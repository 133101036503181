/** @module Components */
import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { SubscriberFormType } from "../../types";
import { usePostSubscriberMutation } from "../../services/subscriberService";
import { ErrorToast, SuccessToast } from "../Shared";

/** @category Prop Types */
export type SubscriberFormProps = {
  /* Whether to include the center application field and change the button text */
  centerApplicationRequest: boolean;
};

export const SubscriberForm: React.FC<SubscriberFormProps> = ({
  centerApplicationRequest,
}: SubscriberFormProps) => {
  const navigate = useNavigate();
  // State and AppContext

  // Redux calls and setup

  // RTKQ Queries and Mutations
  const [postSubscriberForm] = usePostSubscriberMutation();

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="" />, {
      duration: 6000,
    });
  const toastSuccess = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="" />, {
      duration: 3000,
    });

  const formValidationSchema = Yup.object({
    firstName: Yup.string()
      .min(1, "Too Short!")
      .max(75, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(1, "Too Short!")
      .max(75, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  return (
    <div className="mt-8 w-10/12 mx-auto lg:mx-0 lg:w-7/12 lg:mt-0 lg:ml-8">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          centerName: "",
        }}
        validationSchema={formValidationSchema}
        onSubmit={(values: SubscriberFormType) => {
          toast.remove();
          console.log(values)
          postSubscriberForm(values)
            .unwrap()
            .then(() => {
              navigate("/", { replace: false });
              toastSuccess(
                "Your submission has been received and will be processed as soon as possible. Thank you!"
              );
            })
            .catch((error) => {
              if (error.status === 409) {
                toastError(
                  "There was an issue submitting your form. That email has been used to sign up already. Please reach out to support@sicklecellcenters.org for help."
                );
              } else {
                toastError(
                  "There was an issue submitting your form. Please reach out to support@sicklecellcenters.org for help."
                );
              }
            });
        }}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <Form className="space-y-2 sm:flex-col" onSubmit={handleSubmit}>
            <fieldset className="space-x-2 space-y-2 lg:flex">
              <label htmlFor="first-name" className="sr-only">
                First Name
              </label>
              <Field
                id="first-name"
                name="firstName"
                type="text"
                autoComplete="given-name"
                required
                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                placeholder="Enter your first name..."
              />
              <label htmlFor="last-name" className="sr-only">
                Last Name
              </label>
              <Field
                id="last-name"
                name="lastName"
                type="text"
                autoComplete="family-name"
                required
                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                placeholder="Enter your last name..."
              />
            </fieldset>
            {centerApplicationRequest && (
              <div className="space-x-2 lg:flex">
                <label htmlFor="center-name" className="sr-only">
                  Center Name
                </label>
                <Field
                  id="center-name"
                  name="centerName"
                  type="text"
                  autoComplete="off"
                  required
                  className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                  placeholder="Enter the name of the center you represent..."
                />
              </div>
            )}
            <div className="ml-2 w-full lg:flex lg:flex-col lg:pr-2 xl:flex xl:flex-row xl:ml-0 xl:space-x-2">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <Field
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="w-full border-white px-5 py-3 placeholder-gray-500 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white xl:mt-0 xl:w-8/12"
                placeholder="Enter your email..."
              />
              <button
                type="submit"
                className="mx-auto w-full flex items-center justify-center px-4 py-3 border border-transparent text-base
                  font-medium rounded-md text-white bg-indigo-500 xl:mx-0 xl:py-0 xl:h-12 xl:w-4/12 hover:bg-indigo-400 focus:outline-none focus:ring-2
                  focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!(dirty && isValid)}
              >
                {centerApplicationRequest
                  ? "Request an Application"
                  : "Sign Up"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <p className="mt-3 text-sm text-indigo-100 px-2">
        We care about the protection of your data. Read our{" "}
        <Link to="privacy_policy" className="text-white font-medium underline">
          Privacy Policy.
        </Link>
      </p>
    </div>
  );
};
