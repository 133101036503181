import React from "react";
import { Helmet } from "react-helmet-async";

import { Footer } from "../Footer/Footer";
import { LogoGrid } from "../LogoGrid/LogoGrid";

export const SponsorPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Sponsors</title>
        <meta
          name="description"
          content="Information about current sponsors of the National Alliance of Sickle Cell Centers
          and how to become a sponsor."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">
          Sponsoring the National Alliance of Sickle Cell Centers
        </h3>
        <section className="static-page-section">
          <p className="prose-text">
            NASCC sponsorship is a great way to get your company name in front
            of the major decision makers and center directors at our more than
            93 member centers (and growing!). Not only that, but sponsoring
            NASCC means your company is helping to increase the standard of care
            for sickle cell patients around the nation. If you are interested in
            becoming a sponsor, please reach out to{" "}
            <a
              href="mailto:sponsors@sicklecellcenters.org"
              className="hover:text-blue-800"
            >
              sponsors@sicklecellcenters.org
            </a>
            .
          </p>
        </section>
        <h3 className="static-page-header pt-4">
          Thanks to our current sponsors!
        </h3>
        <section className="static-page-section">
          <div className="mx-auto my-auto text-3xl font-bold text-center text-gray-800 2xl:text-left 2xl:col-span-3">
            Thanks to our <b>amazing sponsors</b> for providing their support
            and helping NASCC provide the best treatments, techniques, and
            knowledge to centers around the nation. If you would like to become
            a corporate sponsor and have your logo and profile featured on our
            site, please{" "}
            <a
              href="mailto:sponsors@sicklecellcenters.org"
              className="link-text"
            >
              contact us
            </a>
            .
          </div>
          <div className="2xl:col-span-3 pt-10">
            <div className="flex flex-col">
              <LogoGrid sponsorshipType="platinum" />
            </div>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_6521e312-566d-417d-aad5-4291e39bffa5" />
    </>
  );
};
