import React from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";

import { CenterType, MemberCenter } from "../../types";
import { initialMemberCenterState } from "../../redux/slices/memberCenterSlice";
import RedIcon from "../../assets/images/Red_Map_Icon.png";
import BlueIcon from "../../assets/images/Blue_Map_Icon.png";
import PurpleIcon from "../../assets/images/Purple_Map_Icon.png";

export type GoogleMapProps = {
  memberCenters: MemberCenter[];
};

export const CustomGoogleMap: React.FC<GoogleMapProps> = ({
  memberCenters,
}: GoogleMapProps) => {
  const mapStyles = {
    height: "90vh",
    width: "100%",
    margin: "auto",
  };
  const [center, setCenter] = React.useState({
    lat: 39.05,
    lng: -97.75,
  });
  const [selected, setSelected] = React.useState(initialMemberCenterState);
  const [zoom, setZoom] = React.useState(5);

  const onSelect = (center: MemberCenter) => {
    setZoom(8);
    setCenter({ lat: center.latitude, lng: center.longitude });
    setSelected(center);
  };

  return (
    <LoadScript
      googleMapsApiKey={
        process.env.REACT_APP_GOOGLE_MAP_KEY
          ? process.env.REACT_APP_GOOGLE_MAP_KEY
          : ""
      }
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        center={center}
        mapContainerClassName="rounded-lg shadow-xl"
      >
        {memberCenters.map((center) => {
          return (
            <Marker
              key={center.centerName}
              position={{ lat: center.latitude, lng: center.longitude }}
              onClick={() => onSelect(center)}
              icon={
                center.centerType === CenterType.Adult.toLowerCase()
                  ? RedIcon
                  : center.centerType === CenterType.Pediatric.toLowerCase()
                  ? BlueIcon
                  : PurpleIcon
              }
            />
          );
        })}
        {selected.latitude && (
          <InfoWindow
            position={{ lat: selected.latitude, lng: selected.longitude }}
            onCloseClick={() => setSelected(initialMemberCenterState)}
          >
            <a
              href={selected.websiteAddress ? selected.websiteAddress : ""}
              className="link-text"
            >
              {selected.centerName}
            </a>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};
