/** @module Slices */
import { createSlice } from "@reduxjs/toolkit";

import { CombinedCenterDetailsType } from "../../types";

export const emptyCenterApplication: CombinedCenterDetailsType = {
  id: 0,
  applicationToken: "",
  centerId: 0,
  centerName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: 0,
  latitude: 0,
  longitude: 0,
  websiteAddress: "",
  centerType: undefined,
  profile: "",
  director: "",
  status: "",
  coDirector: undefined,
  agreeToGrndad: false,
  agreeToBylaws: false,
  documentSigned: false,
  signedBy: "",
  dedicatedClinicSpace: false,
  dcsSharedWithCancerCenter: false,
  dcsSharedWithOther: false,
  dcsSharedWithOtherName: "",
  infusionArea: false,
  infAreaDedicatedDayHospital: false,
  infAreaSharedWithCancerCenter: false,
  infAreaSharedWithOther: false,
  infAreaSharedWithOtherName: "",
  observationUnit: false,
  writtenProcForSickleCellDisease: false,
  emergencyDeptProtocolsForScd: false,
  recNewlyTransToAdultProtocol: false,
  trackingQualityMetricsProtocol: false,
  accessCrizanlizumabInfusions: false,
  accessPrescribeVoxeletor: false,
  accessPharmaClinicalTrials: false,
  accessStemCellTransplant: false,
  accessToCompoundHydroxyurea: false,
  opioidPolicy: false,
  infusionPolicy: false,
  trackingNewbornScreenProtocol: false,
  transToAdultProtocol: false,
  pediatricHematology: false,
  indepClinicSpace: false,
  numberOfAdultSca: 0,
  numberOfAdultSc: 0,
  numberOfAdultOther: 0,
  numberOfPediatricSca: 0,
  numberOfPediatricSc: 0,
  numberOfPediatricOther: 0,
  numberOfDedicatedOutpatientStaff: 0,
  firstApprovalBy: undefined,
  firstApprovalAt: "",
  secondApprovalBy: undefined,
  secondApprovalAt: "",
  employees: [],
};

export const centerApplicationSlice = createSlice({
  name: "centerApplication",
  initialState: emptyCenterApplication,
  reducers: {},
});
