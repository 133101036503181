/** @modules Containers */
import React from "react";
import { Helmet } from "react-helmet-async";
import {
  GrndadAbout,
  GrndadBenefits,
  GrndadExecutiveCommittee,
  GrndadHero,
  GrndadLocations,
  GrndadSpeakerSeries,
} from "../../components";

export const Grndad: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - GRNDaD</title>
        <meta
          name="description"
          content="The website for the Globin Research Network for Data and Discovery - a multisite registry
            developed by internationally recognized physicians who take care of both children and adults with sickle
            cell disease"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main bg-white from-white">
        <GrndadHero />
        <GrndadAbout />
        <GrndadLocations />
        <GrndadExecutiveCommittee />
        <GrndadBenefits />
        <GrndadSpeakerSeries />
      </main>
    </>
  );
};
