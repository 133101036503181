import { emptyApi } from "./emptyApi";
import { ApiSettingsType, ProviderResourceType } from "../types";
import { camelCaseResponse } from "../shared/Functions";

export const providerResourceService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getProviderResourceList: builder.query<
      ProviderResourceType[],
      ApiSettingsType
    >({
      query: ({ offset = 0, limit = 100 }: ApiSettingsType) =>
        `/provider-resources?offset=${offset}&limit=${limit}`,
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
    }),
  }),
});

export const { useGetProviderResourceListQuery } = providerResourceService;
