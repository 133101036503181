/** @module Slices */
import { createSlice } from "@reduxjs/toolkit";

import { MemberCenter } from "../../types";

export const initialMemberCenterState: MemberCenter = {
  id: 0,
  centerName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: 0,
  latitude: 0,
  longitude: 0,
  centerType: undefined,
  websiteAddress: "",
  profile: "",
  director: "",
  coDirector: undefined,
  applicationToken: "",
  associateCenter: false,
};

export const memberCenterSlice = createSlice({
  name: "memberCenter",
  initialState: initialMemberCenterState,
  reducers: {},
});
