/** @module Components */

import React from "react";
import { Footer } from "../Footer/Footer";
import { Link } from "react-router-dom";

export const AboutUs: React.FC = () => {
  return (
    <>
      <main className="static-page-main">
        <h2 className="static-page-header">Who are we?</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <div>
              We are an organization that was formed to provide a foundation to
              support sickle cell centers in our mission to improve the care of
              individuals with sickle cell disease (SCD). Our goal is to unify
              sickle cell centers with our purpose and provide support and
              infrastructure assistance to increase the number of sickle cell
              centers available. <strong>Our mission statement:</strong>
              <blockquote>
                to support sickle cell disease centers in providing high-quality
                comprehensive care by setting standards of care and promoting
                their adoption, identifying opportunities and resources to
                strengthen sickle cell disease centers, and advocating for
                access to comprehensive care to improve health outcomes, quality
                of life, and survival.
              </blockquote>
            </div>
            <p>
              Through NASCC we are creating the infrastructure to define a
              sickle cell center for adults and children and to enhance and
              promote the adoption of standards of care that improve patient
              outcomes and quality of life. This includes developing and sharing
              resources amongst our member centers so they can have access to as
              much high quality information as possible. We also plan to
              continuously assess outcomes and improve protocols through the
              Globin Research Network for Data and Discovery (GRNDaD) registry.
            </p>
            <p>
              We want to support providers who care for individuals with sickle
              cell disease, giving them the resources and support they need.
              Together, we can advocate for the ethical and equal treatment of
              individuals living with sickle cell disease.
            </p>
            <p className="font-extrabold text-black-900">
              Our end goal is to make sure that everyone with sickle cell
              disease has access to a sickle cell specialist to improve their
              quality of care and quality of life.
            </p>
          </div>
        </section>
        <h2 className="mt-8 static-page-header">
          What does being a NASCC-recognized comprehensive Sickle Cell Center
          mean?
        </h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <p>
              NASCC recognized Comprehensive Sickle Cell Centers are dedicated
              to improving the outcomes and well-being of people living with
              sickle cell disease. Your NASCC-recognized comprehensive Sickle
              Cell Center will offer BOTH up to date, quality medical care{" "}
              <strong>and</strong> have an experienced, caring staff that takes
              time to develop comprehensive treatment care plans for patients
              and families. A NASCC center also has the ability to prescribe all
              of the available disease-modifying therapies for sickle cell
              disease.
            </p>
            <p>
              Some centers may also have access to research trials - make sure
              you talk to your doctor if you are interested in those trials.
            </p>
          </div>
        </section>
        <h3 className="mt-8 static-page-header">
          Who is part of a NASCC comprehensive Sickle Cell Center?
        </h3>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <p>
              Your NASCC comprehensive SCD Center team may include a sickle cell
              specialist, dedicated and trained nursing staff, a social worker,
              and someone to help coordinate your care (this could be a nurse,
              social worker, patient navigator or community health worker),
              among others. Your SCD Center can also help you access a primary
              care physician, an OBGYN, an eye doctor, a psychiatrist or
              psychologist, or other specialty physicians.
            </p>
            <div>
              Members of a team may include:
              <ul>
                <li>
                  Sickle cell specialist: specialists in sickle cell disease
                </li>
                <li>Nurses: medical specialists in sickle cell disease.</li>
                <li>
                  Social workers: specialists who assist you with the issues of
                  daily living, such as adjusting to living with sickle cell
                  disease and locating resources (e.g. insurance,
                  transportation, housing).
                </li>
                <li>
                  Care coordinator: we know that it can be hard to get all of
                  the care you need. This person will help you coordinate your
                  appointments and medical tests.
                </li>
                <li>
                  Patients and families: these folks are an important member of
                  the treatment team. The staff needs your input to develop a
                  plan of care that will ensure you remain healthy, active, and
                  able to live longer and stronger with sickle cell disease.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <h2 className="mt-8 static-page-header">
          Other frequently asked questions
        </h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <dt className="faq-dt">What is sickle cell disease?</dt>
                <dd className="faq-dd">
                  Sickle cell disease is the most common inherited clinical
                  anemia in the United States and it brings along potentially
                  debilitating and life-threatening pathological conditions. It
                  affects 100,000 Americans and many more millions world wide.
                </dd>
              </div>
              <div>
                <dt className="faq-dt">How can I help?</dt>
                <dd className="faq-dd">
                  We always welcome donations and contributions, which you can
                  make here. NASCC is a 501(c)(3) charity which makes your
                  donations tax deductible.
                </dd>
              </div>
              <div>
                <dt className="faq-dt">
                  Where can I find more information about Sickle Cell Disease?
                </dt>
                <dd className="faq-dd">
                  You can find more information about Sickle Cell Disease at the{" "}
                  <a
                    href="https://www.hematology.org/education/patients/anemia/sickle-cell-disease/"
                    className="hover:text-blue-800"
                  >
                    American Society of Hematology
                  </a>{" "}
                  website and the{" "}
                  <a
                    href="https://www.sicklecelldisease.org"
                    className="hover:text-blue-800"
                  >
                    Sickle Cell Disease Association of America
                  </a>{" "}
                  website.
                </dd>
              </div>
              <div>
                <dt className="faq-dt">
                  How can I stay informed about the National Alliance of Sickle
                  Cell Centers and the progress it's making to help fight sickle
                  cell disease?
                </dt>
                <dd className="faq-dd">
                  You can sign up for our newsletter{" "}
                  <Link to="/join" className="hover:text-blue-800">
                    here
                  </Link>
                  . We also provide general information about Sickle Cell
                  Disease and related conditions, along with educational
                  materials and other resources.
                </dd>
              </div>
              <div>
                <dt className="faq-dt">
                  Does NASCC have a conflict of interst policy?
                </dt>
                <dd className="faq-dd">
                  You can find NASCC's conflict of interest policy{" "}
                  <a href="/conflict_of_interest" className="link-text">
                    here
                  </a>
                  .
                </dd>
              </div>
              <div>
                <dt className="faq-dt">
                  Does NASCC host an annual meeting or conference?
                </dt>
                <dd className="faq-dd">
                  Yes, we host an annual meeting and consensus conference. There
                  we discuss the latest research and treatment options for
                  sickle cell disease, build consensus on recommended
                  treatments, and provide a forum for networking and
                  collaboration. You can check out our{" "}
                  <Link to="/consensus_meeting" className="link-text">
                    Consensus Meeting
                  </Link>{" "}
                  page for more information.
                </dd>
              </div>
            </dl>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_bc8fe07d-7785-4718-a0e9-0e47e86dc3ee" />
    </>
  );
};
