import React from "react";

export const GrndadBenefits: React.FC = () => {
  return (
    <section className="static-page-section mt-10" id="benefits_of_joining">
      <h3 className="static-page-header">GRNDaD Benefits</h3>
      <div className="text-xl md:text-2xl">
        <ol className="list-disc list-inside [&_ul]:list-[revert]">
          <li>Access to your own sites' data at any time</li>
          <li>
            Access to a patient-specific health maintenance sheet, extracted
            from GRNDaD, which prompts needed testing and/or vaccination
            (informed by NHLBI & ASH guidelines).
          </li>
          <li>
            Access to all de-identified GRNDaD data from all sites, via an
            established protocol:
            <ul className="list-disc list-inside pl-8">
              <li>For research purposes</li>
              <li>For quality improvement purposes</li>
            </ul>
          </li>
        </ol>
      </div>
    </section>
  );
};
