/** @module Components */
import React from "react";

import { CombinedCenterDetailsType } from "../../types";
import { CardInfoData } from "./CardInfoData";
import { toTitleCase } from "../../shared/Functions";

export type BasicCenterInfoProps = {
  basicCenterInformation: CombinedCenterDetailsType;
};

export const BasicCenterInfo: React.FC<BasicCenterInfoProps> = ({
  basicCenterInformation,
}: BasicCenterInfoProps) => {
  return (
    <div className="bg-white border-t border-gray-300 border-2 mx-4 my-2 shadow sm:rounded-lg">
      {/* Card Header */}
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-2xl leading-6 font-semibold text-gray-900">
          Basic Center Information
        </h3>
      </div>
      {/* Card Body */}
      <div className="px-4 pb-4 sm:pb-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-4">
          {basicCenterInformation.centerType && (
            <CardInfoData
              infoTitle="Center Type"
              infoContent={toTitleCase(basicCenterInformation.centerType)}
            />
          )}
          {basicCenterInformation.director && (
            <CardInfoData
              infoTitle="Center Director"
              infoContent={basicCenterInformation.director}
            />
          )}
          {basicCenterInformation.coDirector &&
            basicCenterInformation.coDirector !== "" && (
              <CardInfoData
                infoTitle="Center Co-Director"
                infoContent={basicCenterInformation.coDirector}
              />
            )}
          {basicCenterInformation.streetAddress &&
            basicCenterInformation.city &&
            basicCenterInformation.state &&
            basicCenterInformation.zipCode && (
              <CardInfoData
                infoTitle="Center Address"
                addressBoolean={true}
                addressInfo={{
                  streetAddress: basicCenterInformation.streetAddress,
                  city: basicCenterInformation.city,
                  state: basicCenterInformation.state,
                  zipCode: basicCenterInformation.zipCode,
                }}
              />
            )}
          {basicCenterInformation.agreeToGrndad && (
            <CardInfoData
              infoTitle="Agreed to GRNDAD?"
              infoBoolean={basicCenterInformation.agreeToGrndad}
            />
          )}
          {basicCenterInformation.agreeToBylaws && (
            <CardInfoData
              infoTitle="Agreed to Bylaws?"
              infoBoolean={basicCenterInformation.agreeToBylaws}
            />
          )}
          {basicCenterInformation.documentSigned && (
            <CardInfoData
              infoTitle="Application Signed?"
              infoBoolean={basicCenterInformation.documentSigned}
            />
          )}
          {basicCenterInformation.signedBy && (
            <CardInfoData
              infoTitle="Application Signed By"
              infoContent={basicCenterInformation.signedBy}
            />
          )}
          {basicCenterInformation.applicationToken && (
            <CardInfoData
              infoTitle="Application Token"
              infoContent={basicCenterInformation.applicationToken}
            />
          )}
        </dl>
      </div>
    </div>
  );
};
