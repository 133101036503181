import { ColumnWithStrictAccessor } from "react-table";
import { Link } from "react-router-dom";

import { toTitleCase } from "../../shared/Functions";

export const SharedApplicationColumns = (): any[] => {
  return [
    {
      Header: "Center Name",
      accessor: "centerName",
      Cell: function centerNameCell(element: any) {
        return (
          <Link
            to={`/members/center_application/${element.row.original.id}`}
            className="font-bold link-text"
          >
            {element.value}
          </Link>
        );
      },
    },
    {
      Header: "Director Name",
      accessor: "director",
      Cell: function directorNameCell(element: any) {
        return element.value;
      },
    },
    {
      Header: "Co-Director Name",
      accessor: "coDirector",
      Cell: function coDirectorNameCell(element: any) {
        if (element.value === "") {
          return "None";
        } else {
          return element.value;
        }
      },
    },
    {
      Header: "Center Type",
      accessor: "centerType",
      Cell: function centerTypeCell(element: any) {
        return toTitleCase(element.value);
      },
    },
  ];
};

export const SharedEmployeeColumns = (): any[] => {
  return [
    {
      Header: "Name and Credentials",
      accessor: "nameAndInfo",
      Cell: function nameAndCredentialsCell(element: any) {
        return element.value;
      },
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: function roleCell(element: any) {
        return toTitleCase(element.value);
      },
    },
    {
      Header: "Employment Type",
      accessor: "employeeType",
      Cell: function employeeTypeCell(element: any) {
        if (element.value) {
          return toTitleCase(element.value);
        } else {
          return "Unknown";
        }
      },
    },
  ];
};

export const SharedEmailListColumns = (): ColumnWithStrictAccessor<
  Record<string, unknown>
>[] => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: function nameCell(element: any) {
        return (
          <Link
            to={`/members/contact_list/${element.row.original.id}`}
            className="font-bold link-text"
          >
            {toTitleCase(element.value)}
          </Link>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: function descriptionCell(element: any) {
        return element.value;
      },
    },
    {
      Header: "Total # of Contacts",
      accessor: "contactCount",
      Cell: function contactCountCell(element: any) {
        return element.row.original.members.length;
      },
    },
  ];
};

export const SharedSubscriberListColumns = (): ColumnWithStrictAccessor<
  Record<string, unknown>
>[] => {
  return [
    {
      Header: "Contact Name",
      accessor: "firstName",
      Cell: function contactNameCell(element: any) {
        return element.value + " " + element.row.original.lastName;
      },
    },
    {
      Header: "Contact Email",
      accessor: "email",
      Cell: function contactEmailCell(element: any) {
        return element.value;
      },
    },
    {
      Header: "Associated Center",
      accessor: "",
      Cell: function associatedCenterCell(element: any) {
        if (element.row.original.centerId) {
          return (
            <p className="text-green-700">{element.row.original.centerName}</p>
          );
        }
        return <p className="text-red-500">Not Associated with a Center</p>;
      },
    },
  ];
};

export const SharedContactListColumns = (): ColumnWithStrictAccessor<
  Record<string, unknown>
>[] => {
  return [
    {
      Header: "Contact Name",
      accessor: "firstName",
      Cell: function contactNameCell(element: any) {
        return element.value + " " + element.row.original.lastName;
      },
    },
    {
      Header: "Contact Email",
      accessor: "email",
      Cell: function contactEmailCell(element: any) {
        return element.value;
      },
    },
    {
      Header: "Actions",
      accessor: "",
      disableSortBy: true,
      Cell: function actionCell(element: any) {
        return <></>;
      },
    },
  ];
};

export const ConsensusDetailsColumns = (): ColumnWithStrictAccessor<
  Record<string, unknown>
>[] => {
  return [
    {
      Header: () => (
        <div id="header-1" className="font-bold text-xl text-black">
          Category
        </div>
      ),
      accessor: "title",
      disableSortBy: true,
      Cell: function contactNameCell(element: any) {
        return element.value;
      },
    },
    {
      Header: () => (
        <div id="header-2" className="font-bold text-xl text-black">
          Consensus Statement
        </div>
      ),
      accessor: "text",
      disableSortBy: true,
      Cell: function contactEmailCell(element: any) {
        return element.value;
      },
    },
    {
      Header: () => (
        <div id="header-3" className="font-bold text-xl text-black">
          Recommendation/Standard
        </div>
      ),
      accessor: "typeOfRecommendation",
      disableSortBy: true,
      Cell: function contactEmailCell(element: any) {
        return element.value;
      },
    },
  ];
};
