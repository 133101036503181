/** @modules Redux */
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import * as Sentry from "@sentry/react";
import {
  configureStore,
  combineReducers,
  Middleware,
  isRejectedWithValue,
  AnyAction,
  CombinedState,
} from "@reduxjs/toolkit";
import { emptyApi } from "../services/emptyApi";
import storage from "redux-persist/lib/storage";

import { centerApplicationSlice, memberCenterSlice, userSlice } from "./slices";
import {
  CombinedCenterDetailsType,
  JobPostType,
  MemberCenter,
  User,
} from "../types";
import { jobPostSlice } from "./slices/jobPostSlice";
import { publicationService } from "../services/publicationService";
import { userService } from "../services/userService";
import { memberCenterService } from "../services/memberCenterService";
import { contactService } from "../services/contactService";
import { subscriberService } from "../services/subscriberService";
import { jobPostService } from "../services/jobPostService";
import { applicationService } from "../services/applicationService";

const api = emptyApi.reducer;
const user = userSlice.reducer;
const centerApplication = centerApplicationSlice.reducer;
const memberCenter = memberCenterSlice.reducer;
const jobPost = jobPostSlice.reducer;

const appReducer = combineReducers({
  api,
  centerApplication,
  memberCenter,
  user,
  jobPost,
});

const rootReducer = (
  state:
    | CombinedState<{
        centerApplication: CombinedCenterDetailsType;
        memberCenter: MemberCenter;
        user: User;
        jobPost: JobPostType;
        api: any;
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === "user/logoutUser") {
    storage.removeItem("persist:root").then(() => sessionStorage.clear());
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    emptyApi.reducerPath,
    applicationService.reducerPath,
    publicationService.reducerPath,
    userService.reducerPath,
    memberCenterService.reducerPath,
    contactService.reducerPath,
    subscriberService.reducerPath,
    jobPostService.reducerPath,
  ],
};
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const persistedReducer = persistReducer(persistConfig, rootReducer);

/** Handles all async errors by default, taken from https://redux-toolkit.js.org/rtk-query/usage/error-handling */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn(
      "Rejected action, problem with RTK Query results: ",
      action.error
    );
  }
  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [],
      },
    }).concat(emptyApi.middleware, rtkQueryErrorLogger),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
