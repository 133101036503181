/** @module Components */

import React from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import tw from "tailwind-styled-components";

/** @category Prop Types */
export type CustomRowProps = {
  /** Made up of Row objects from the react-table package */
  rows: Row<Record<string, unknown>>[];
  /** Function from the react-table package to get the props for the TableBody */
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<Record<string, unknown>> | undefined,
  ) => TableBodyProps;
  /** Function from the react-table package to prepare the row for display */
  prepareRow: (row: Row<Record<string, unknown>>) => void;
  /** Optional parameter, allows additional TailwindCSS utility keywords to be passed in and applied to the entire Row */
  customRowClassName?: string;
  /** Optional parameter, allows additional TailwindCSS utility keywords to be passed in and applied to each cell in the Row */
  customRowCellClassName?: string;
};

const StyledRow = tw.tr`
  bg-white
  min-w-max
  border-b
  border-gray-200
  h-20
`;

const StyledRowCell = tw.td`
  text-left
  whitespace-nowrap
  text-base
  text-gray-500
`;

const CustomRow = ({
  rows,
  getTableBodyProps,
  prepareRow,
  customRowClassName,
  customRowCellClassName,
}: CustomRowProps): JSX.Element => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <StyledRow
            {...row.getRowProps()}
            className={`${customRowClassName} hover:bg-gray-50 bg-white min-w-max border-b border-gray-200 h-20`}
            key={index}
          >
            {row.cells.map((cell) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <StyledRowCell
                  {...cell.getCellProps()}
                  className={customRowCellClassName}
                  style={{ minWidth: "120px" }}
                >
                  <div className="flex justify-begin items-center">
                    {cell.render("Cell") as React.ReactNode}
                  </div>
                </StyledRowCell>
              );
            })}
          </StyledRow>
        );
      })}
    </tbody>
  );
};

export default CustomRow;
