import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ResetPasswordForm } from "../../components";
import { useCheckResetTokenQuery } from "../../services/userService";
import MainLogo from "../../assets/images/mainLogo.jpg";

type ResetPasswordRouteParams = {
  token: string;
};

export const ResetPassword: React.FC = () => {
  const registrationRouteParams = useParams<ResetPasswordRouteParams>();
  const token = registrationRouteParams.token;
  const navigate = useNavigate();

  const [username, setUsername] = useState("");

  const {
    data: resetPasswordData,
    isSuccess,
    isError,
  } = useCheckResetTokenQuery(token || "");

  useEffect(() => {
    if (resetPasswordData) {
      setUsername(resetPasswordData.email);
    }
  }, [resetPasswordData]);

  return (
    <>
      {isSuccess && (
        <div
          className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-start pt-6 sm:px-6 lg:px-8"
          data-testid="reset-root-element"
        >
          <div
            className="sm:mx-auto sm:w-full sm:max-w-md bg-primary-800 sm:rounded-lg sm:shadow-lg pb-4"
            data-testid="reset-header"
          >
            <img
              className="mx-auto h-full w-auto"
              src={MainLogo}
              alt="Reboot Motion logo with the words Reboot and Motion stacked on top of each other with the top one bold and bottom one regular weight"
              data-testid="reboot-logo"
            />
            <h2
              className="mt-6 text-center text-3xl font-extrabold"
              data-testid="reset-heading"
            >
              Reset Your Password
            </h2>
          </div>
          <ResetPasswordForm email={username} token={token || ""} />
        </div>
      )}
      {isError && navigate("/member_login")}
    </>
  );
};
