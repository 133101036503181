/** @module Containers */
import React from "react";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useGetMembershipReportQuery } from "../../services/reportService";

export const Report: React.FC = () => {
  let mappedResponseWithHeaders: any = React.useRef(null);
  const {
    data: membershipData,
    // isLoading,
    // isFetching,
    // isError,
  } = useGetMembershipReportQuery();

  console.log({ membershipData });

  React.useEffect(() => {
    const headers = [
      [
        "id",
        "centerName",
        "streetAddress",
        "city",
        "state",
        "zip",
        "latitude",
        "longitude",
        "centerType",
        "websiteAddress",
        "profile",
        "director",
        "coDirector",
        "centerContacts",
      ],
    ];
    const mappedResponse = membershipData?.map((center) => {
      return Object.entries(center).map(([key, value]) => {
        if (key === "centerContacts" && value && value instanceof Array) {
          return value.join(";");
        }
        return value;
      });
    });
    if (mappedResponse) {
      mappedResponseWithHeaders.current = [...headers, ...mappedResponse];
    }
  }, [membershipData]);

  const downloadCSV = (data: any) => {
    if (mappedResponseWithHeaders.current) {
      const today = new Date();
      const csvData = mappedResponseWithHeaders.current
        .map((row: any) => row.join(","))
        .join("\n");
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", today.toString() + "_membershipReport.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <main>
      <h2 className="static-page-header mx-8">Generate Reports</h2>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"></div>
      <div className="bg-white overflow-hidden shadow rounded-lg max-w-xl pl-4">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <ClipboardIcon
                className="h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-base font-medium text-gray-500 truncate">
                  Membership Report
                </dt>
                <dd>
                  <div className="text-md font-medium text-gray-900">
                    Generate a CSV report that lists all member centers and
                    their contacts.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-5 py-3">
          <div className="text-sm">
            <button
              className="font-medium text-cyan-700 hover:text-cyan-900"
              onClick={downloadCSV}
            >
              Generate Report
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
