import { emptyApi } from "./emptyApi";
import { camelCaseResponse, providesList } from "../shared/Functions";
import { MemberCenter } from "../types";

export const memberCenterService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getMemberCenters: builder.query<MemberCenter[], void>({
      query: () => "/member_centers",
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) =>
        providesList(results, "MemberCenter"),
    }),
  }),
});

export const { useGetMemberCentersQuery } = memberCenterService;
