/** @module Containers */
import React from "react";

import { Table } from "../../components";
import { useGetSendgridEmailListsQuery } from "../../services/mailManagementService";
import { ColumnWithStrictAccessor } from "react-table";
import { SharedEmailListColumns } from "../../components/Shared/Columns";

export const ContactLists: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );
  const {
    data: sendgridEmailLists,
    isLoading: emailListLoading,
    isFetching: emailListFetching,
    isError: emailListError,
  } = useGetSendgridEmailListsQuery();

  const memoizedSharedEmailListColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedEmailListColumns, []);

  return (
    <section>
      <h3 className="static-page-header mx-8">Contact Lists</h3>
      <div className="flex flex-col">
        {!emailListLoading &&
          !emailListFetching &&
          !emailListError &&
          sendgridEmailLists && (
            <Table
              columns={memoizedSharedEmailListColumns}
              data={sendgridEmailLists}
              selectedPageSize={selectedPageSize}
              setSelectedPageSize={setSelectedPageSize}
              fetching={emailListFetching}
              headerCellClassName="pl-4"
              customRowCellClassName="pl-4"
            />
          )}
      </div>
    </section>
  );
};
