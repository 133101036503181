import camelcaseKeys from "camelcase-keys";
import { BasicCenterInfoType, CombinedCenterDetailsType } from "../types";

// Join together the strings in the class name
export function classNames<Type>(classes: Type[]): string {
  return classes.filter(Boolean).join(" ");
}

// Return the time of day - morning, afternoon, or evening.
export function timeOfDay(): string {
  const currentHour: number = new Date().getHours();
  if (currentHour > 3 && currentHour < 12) {
    return "morning";
  } else if (currentHour > 12 && currentHour < 6) {
    return "afternoon";
  } else {
    return "evening";
  }
}

// Used https://redux-toolkit.js.org/rtk-query/usage/automated-refetching for a guide for this method
// Helper method to return a list of results with the abstract LIST tag type and individual tags for each element
export function providesList<
  R extends { id: string | number }[],
  T extends string,
>(resultsWithIds: R | undefined, tagType: T): any[] {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

// As we need this to return many different types, we're using any as the return type
// This function will handle arrays of results as well, including nested objects using a recursive approach
export function camelCaseResponse(response: Record<string, unknown>): any {
  return camelcaseKeys(response, { deep: true });
}

// Taken from https://stackoverflow.com/a/46774740/9406847
export const toTitleCase = (str: string): string => {
  const articles = ["a", "an", "the"];
  const conjunctions = ["for", "and", "nor", "but", "or", "yet", "so"];
  const prepositions = [
    "with",
    "at",
    "from",
    "into",
    "upon",
    "of",
    "to",
    "in",
    "for",
    "on",
    "by",
    "like",
    "over",
    "plus",
    "but",
    "up",
    "down",
    "off",
    "near",
  ];

  // The list of spacial characters can be tweaked here
  const replaceCharsWithSpace = (str: string) =>
    // eslint-disable-next-line no-useless-escape
    str.replace(/[^0-9a-z&'\/\\]/gi, " ").replace(/(\s\s+)/gi, " ");
  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.substr(1);
  const normalizeStr = (str: string) => str.toLowerCase().trim();
  const shouldCapitalize = (
    word: string,
    fullWordList: string[],
    posWithinStr: number,
  ) => {
    if (posWithinStr === 0 || posWithinStr === fullWordList.length - 1) {
      return true;
    }

    return !(
      articles.includes(word) ||
      conjunctions.includes(word) ||
      prepositions.includes(word)
    );
  };

  str = replaceCharsWithSpace(str);
  str = normalizeStr(str);

  let words = str.split(" ");
  if (words.length <= 2) {
    // Strings less than 3 words long should always have first words capitalized
    words = words.map((w) => capitalizeFirstLetter(w));
  } else {
    for (let i = 0; i < words.length; i++) {
      words[i] = shouldCapitalize(words[i], words, i)
        ? capitalizeFirstLetter(words[i])
        : words[i];
    }
  }

  return words.join(" ");
};

export function extractBasicCenterInfo(
  combinedCenterDetails: CombinedCenterDetailsType,
): BasicCenterInfoType {
  return {
    centerName: combinedCenterDetails.centerName,
    streetAddress: combinedCenterDetails.streetAddress,
    city: combinedCenterDetails.city,
    state: combinedCenterDetails.state,
    zipCode: combinedCenterDetails.zipCode,
    director: combinedCenterDetails.director,
    coDirector: combinedCenterDetails.coDirector,
    applicationStatus: combinedCenterDetails.status,
    agreedToGrndad: combinedCenterDetails.agreeToGrndad,
    agreedToBylaws: combinedCenterDetails.agreeToBylaws,
    documentSigned: combinedCenterDetails.documentSigned,
    signedBy: combinedCenterDetails.signedBy,
    applicationToken: combinedCenterDetails.applicationToken,
  };
}
