/** @module Components */
import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Select, { Options } from "react-select";
import toast from "react-hot-toast";

import { CustomSelectStyles, ErrorToast, SuccessToast } from "../Shared";
import { useGetSendgridEmailListsQuery } from "../../services/mailManagementService";
import { ContactListType } from "../../types/mailTypes";
import { toTitleCase } from "../../shared/Functions";
import { usePatchSubscriberMutation } from "../../services/subscriberService";
import { useAppContext } from "../../context";

/** @category Prop Types */
export type EmailListFormPropType = {
  subscriberId: number;
};

export const EmailListForm: React.FC<EmailListFormPropType> = ({
  subscriberId,
}: EmailListFormPropType) => {
  const [, setIsMenuOpen] = React.useState(false);
  const { setDisplayModal } = useAppContext();

  const [emailListsToAdd, setEmailListsToAdd] = React.useState<any[]>([]);
  const [patchSubscriber] = usePatchSubscriberMutation();

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="" />, {
      duration: 6000,
    });
  const toastSuccess = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="" />, {
      duration: 3000,
    });

  const {
    data: sendgridEmailLists,
    isLoading: emailListLoading,
    isFetching: emailListFetching,
    isError: emailListError,
  } = useGetSendgridEmailListsQuery();

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const emailListOptions = () => {
    if (
      !emailListLoading &&
      !emailListFetching &&
      !emailListError &&
      sendgridEmailLists
    ) {
      return sendgridEmailLists.map((emailList: ContactListType) => {
        return { value: emailList.id, label: toTitleCase(emailList.name) };
      });
    }
  };

  const handleMultiSelect = (
    selections: Options<{ value: number; label: string }>
  ) => {
    setEmailListsToAdd([...selections]);
  };

  const formValidationSchema = Yup.object({
    emailLists: Yup.array().of(Yup.string()).required("Required"),
  });

  return (
    <Formik
      initialValues={emailListsToAdd}
      validationSchema={formValidationSchema}
      onSubmit={(_, { resetForm }) => {
        patchSubscriber({
          emailListIds: emailListsToAdd,
          subscriberId: subscriberId,
        })
          .unwrap()
          .then(() => {
            setDisplayModal(false);
            toastSuccess("Successfully added the subscriber to the list!");
          })
          .catch(() => {
            toastError(
              "There was an issue adding the subscribers to the emails. Please reach out to support@sicklecellcenters.org for help."
            );
          });
        resetForm();
      }}
    >
      {({ values, handleSubmit, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <fieldset>
            <div className="flex justify-between">
              <label
                id="email-lists-label"
                htmlFor="email-lists-select"
                className="block text-sm font-medium text-gray-500 pb-1"
              >
                Email Lists
              </label>
              <span className="text-sm text-gray-300">Required</span>
            </div>
            <Select
              aria-labelledby="email-list-selection-label"
              inputId="email-lists-select"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              isMulti
              onChange={(emailList) => {
                handleMultiSelect(emailList);
              }}
              options={emailListOptions()}
              styles={CustomSelectStyles}
            />
          </fieldset>
          <div className="mt-5 flex">
            <button
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white
                         text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setDisplayModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600
                      text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
