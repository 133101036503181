/** @modules Components */
import React from "react";

import GrndadLogo from "../../assets/images/GRNDaD_Logo.png";
import { classNames } from "../../shared/Functions";

export const GrndadHero: React.FC = () => {
  const navOptions = [
    { name: "About", href: "#about" },
    { name: "Locations", href: "#locations" },
    { name: "Executive Committee", href: "#executive_committee" },
    { name: "Benefits of Joining", href: "#benefits_of_joining" },
    { name: "Speaker Series", href: "#speaker_series" },
  ];

  return (
    <section className="flex flex-col max-w-10xl mt-5 xl:mx-auto bg-white">
      <div className="h-full w-1/3 mx-auto">
        <img
          src={GrndadLogo}
          className="rounded h-full w-full"
          alt="GRNDaD Logo, a stylized G, R, N, D, a, and D in a line with the words Globin Research Network for
            Data and Discovery below and a red globe above the D and a"
        />
      </div>
      <div className="max-h-20 h-full w-full mt-10">
        {/* Iterate over options and display */}
        <nav className="flex justify-center items-center">
          {navOptions.map((option, index) => (
            <a
              key={index}
              href={option.href}
              className={classNames([
                "px-3 py-2 text-3xl font-medium text-gray-500 hover:text-gray-900",
                index === 0 ? "ml-0" : "ml-4",
              ])}
            >
              {option.name}
            </a>
          ))}
        </nav>
      </div>
    </section>
  );
};
