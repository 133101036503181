import React from "react";
import { useGetPublicationListQuery } from "../../services/publicationService";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import * as Sentry from "@sentry/react";

export const Publications: React.FC = () => {
  const [displayPublicationType, setDisplayPublicationType] =
    React.useState<string>("all");

  const {
    data: publications,
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetPublicationListQuery({ offset: 0, limit: 100 });

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <main className="static-page-main">
      <h3 className="static-page-header">
        Recent Publications and White Papers
      </h3>
      <section className="static-page-section">
        <div>
          <p className="text-xl text-gray-500 lg:text-2xl">
            Here you'll find links and information about recent publications and
            white papers in the Sickle Cell Disease field. Please{" "}
            <Link to="/contact_info" className="link-text">
              contact us
            </Link>{" "}
            if you'd like to get your paper added, or if you have a paper you'd
            like to recommend be added.
          </p>
        </div>
        {(isFetching || isLoading) && (
          <h2 className="static-page-header mt-10">Fetching publications...</h2>
        )}
        {!(isFetching && isLoading) && publications && (
          <div className="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
            {publications
              .filter((pub) =>
                displayPublicationType === "all"
                  ? pub
                  : pub.tags
                      .map((tag) => tag.color)
                      .includes(displayPublicationType),
              )
              .map((publication) => {
                return (
                  <div key={publication.title}>
                    <div className="flex flex-wrap grow-0 space-x-0.5">
                      {publication.tags.map((tag) => (
                        <div key={tag.name} className="ml-0">
                          <button
                            onClick={() => setDisplayPublicationType(tag.color)}
                            className="inline-block"
                          >
                            <span
                              className={classNames(
                                `bg-${tag.color}-600`,
                                "inline-flex flex-wrap grow-0 whitespace-nowrap items-center px-3 py-0.5 rounded-full text-sm text-white font-medium ml-0",
                              )}
                            >
                              {tag.name}
                            </span>
                          </button>
                        </div>
                      ))}
                    </div>
                    <a href={publication.webLink} className="block mt-4">
                      <p className="text-xl font-semibold link-text">
                        {publication.title}
                      </p>
                    </a>
                    <p className="mt-3 text-base text-gray-500">
                      {publication.blurb}
                    </p>
                    <div className="mt-3 flex items-center">
                      <div className="flex flex-col">
                        <div className="flex">
                          <p className="text-sm font-medium text-gray-900">
                            {publication.authors[0]}, {publication.authors[1]},
                            et al.
                          </p>
                        </div>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <span>Published:</span>
                          <time dateTime={publication.datePublished.toString()}>
                            {format(
                              new Date(publication.datePublished),
                              "MMMM d, yyyy",
                            )}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {isError && Sentry.captureException(error) && (
          <h2 className="static-page-header mt-12">
            There was an issue fetching publications. Our apologies - we're
            working on fixing the issue as quickly as possible.
          </h2>
        )}
      </section>
    </main>
  );
};
