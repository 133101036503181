/** @modules Containers */
import React from "react";
import { Fragment, useState } from "react";
import { Dialog, DialogPanel, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  Bars3Icon,
  UsersIcon,
  UserIcon,
  XMarkIcon,
  EnvelopeOpenIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { classNames } from "../../shared/Functions";
import NavBarLogo from "../../assets/images/navBarLogo.jpg";
import AppContext from "../../context";
import { Toaster } from "react-hot-toast";
import { useAppSelector } from "../../redux/hooks";

export const MemberDashboard: React.FC = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const user = useAppSelector((state) => state.user);

  const navigation = [
    {
      name: "Dashboard",
      href: "/members/dashboard",
      icon: HomeIcon,
      current: location.pathname === "/members/dashboard",
    },
    {
      name: "Pending Applications",
      href: "/members/pending_applications",
      icon: FolderIcon,
      current: location.pathname === "/members/pending_applications",
    },
    {
      name: "Approved Applications",
      href: "/members/approved_applications",
      icon: FolderIcon,
      current: location.pathname === "/members/approved_applications",
    },
    {
      name: "All Subscribers",
      href: "/members/subscribers_list",
      icon: UsersIcon,
      current: location.pathname === "/members/subscribers_list",
    },
    {
      name: "Contact Lists",
      href: "/members/contact_lists",
      icon: EnvelopeOpenIcon,
      current: false,
    },
    { name: "Documents", href: "#", icon: InboxIcon, current: false },
    {
      name: "Reports",
      href: "/members/reports",
      icon: ChartBarIcon,
      current: false,
    },
    {
      name: "Events",
      href: "/members/calendar",
      icon: CalendarIcon,
      current: false,
    },
  ];
  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "/logout" },
  ];

  console.log("User: ", user);

  return (
    <>
      {user.token ? (
        <AppContext.Provider
          value={{
            displayModal: displayModal,
            setDisplayModal: setDisplayModal,
            // modalTitle: modalTitle,
            // setModalTitle: setModalTitle,
            // modalBody: modalBody,
            // setModalBody: setModalBody,
            // modalRightButtonText: modalRightButtonText,
            // setModalRightButtonText: setModalRightButtonText,
            // modalLeftButtonText: modalLeftButtonText,
            // setModalLeftButtonText: setModalLeftButtonText,
            // displayLeftButton: displayLeftButton,
            // setDisplayLeftButton: setDisplayLeftButton,
            // displayRightButton: displayRightButton,
            // setDisplayRightButton: setDisplayRightButton,
          }}
        >
          <div>
            <Toaster
              containerStyle={{
                zIndex: 99999999,
                top: "20%",
              }}
            />
            {/* Mobile sidebar with menu */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 flex z-40 md:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <DialogPanel className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-16 w-auto rounded-md"
                        src={NavBarLogo}
                        alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
                      />
                      <span className="text-white text-4xl pl-4">NASCC</span>
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames([
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                            ])}
                          >
                            <item.icon
                              className={classNames([
                                item.current
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-4 flex-shrink-0 h-6 w-6",
                              ])}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
              <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
                {/* Desktop Sidebar Logo */}
                <a href="/">
                  <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                    <img
                      className="h-14 p-1 bg-white w-auto rounded-md"
                      src={NavBarLogo}
                      alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
                    />
                    <span className="pl-4 text-white text-4xl">NASCC</span>
                  </div>
                </a>

                {/* Desktop Sidebar Navigation */}
                <div className="flex-1 flex flex-col overflow-y-auto">
                  <nav className="flex-1 px-2 py-4 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames([
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                        ])}
                      >
                        <item.icon
                          className={classNames([
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-3 flex-shrink-0 h-6 w-6",
                          ])}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div className="md:pl-64 flex flex-col">
              <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                {/* Button For Small Screens */}
                <button
                  type="button"
                  className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* Search Bar, Notifications, and Profile */}
                <div className="flex-1 px-4 flex justify-between">
                  {/* Search Bar */}
                  <div className="flex-1 flex">
                    <form
                      className="w-full flex md:ml-0"
                      action="#"
                      method="GET"
                    >
                      <label htmlFor="search-field" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                          <MagnifyingGlassIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search-field"
                          className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Notifications */}
                    <button
                      type="button"
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <UserIcon className="h-8 w-8 rounded-full text-gray-500" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames([
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  ])}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              {/* Rest of the Page */}
              <main className="flex-1">
                <div className="py-6">
                  <Outlet />
                </div>
              </main>
            </div>
          </div>
        </AppContext.Provider>
      ) : (
        <Navigate to="/member_login" />
      )}
    </>
  );
};
