/** @module Components */
import React, { Dispatch, SetStateAction } from "react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";

export type SearchProps = {
  label: string;
  filterValue: string;
  setFilterValue: Dispatch<SetStateAction<string>>;
};

export const Search: React.FC<SearchProps> = ({
  label,
  filterValue,
  setFilterValue,
}: SearchProps) => {
  return (
    <div>
      <label
        htmlFor="search-bar"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <ClipboardDocumentListIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="search-bar"
            id="search-bar"
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
          />
        </div>
      </div>
    </div>
  );
};
