/** @modules Components */
import React from "react";
import { Footer } from "../Footer/Footer";

export const OurPartners: React.FC = () => {
  return (
    <main className="static-page-main">
      <h2 className="static-page-header">Our Partners</h2>
      <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
        <div className="prose-text">
          <p>
            These organizations are ones that we have partnered with in order to
            make the impact of your donations and support as large as possible.
            The work being done by the organizations listed here is saving lives
            and improving patient outcomes all over the world.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-6 prose-text">
          <div>
            <h3 className="text-2xl leading-6 font-medium">
              <a href="https://www.scapn.org/" className="hover:text-blue-800">
                Sickle Cell Adult Provider Network
              </a>
            </h3>
            <p>
              The Sickle Cell Adult Provider Network (SCAPN) was established in
              2002 with a mission to establish a network for healthcare
              providers who serve adults with sickle cell disease. This was the
              first and remains the only national organization that offers a
              home for healthcare professionals from multiple disciplines who
              focus on adult-oriented research, clinical care, provides
              education/training, mentorship, and advocacy.
            </p>
          </div>
          <div>
            <h3 className="text-2xl leading-6 font-medium">
              <a
                href="https://www.sicklecelldisease.org/"
                className="hover:text-blue-800"
              >
                Sickle Cell Disease Association of America
              </a>
            </h3>
            <p>
              The Sickle Cell Disease Association of America's mission statement
              is: to advocate for people affected by sickle cell conditions and
              empower community-based organizations to maximize quality of life
              and raise public consciousness while advancing the search for a
              universal cure.
            </p>
          </div>
          <div>
            <h3 className="text-2xl leading-6 font-medium">
              <a
                href="https://www.hematology.org/"
                className="hover:text-blue-800"
              >
                American Society of Hematology
              </a>
            </h3>
            <p>
              The Sickle Cell Disease Association of America's mission statement
              is: The Society's mission is to further the understanding,
              diagnosis, treatment, and prevention of disorders affecting the
              blood, bone marrow, and the immunologic, hemostatic and vascular
              systems, by promoting research, clinical care, education,
              training, and advocacy in hematology.
            </p>
          </div>
          <div>
            <h3 className="text-2xl leading-6 font-medium">
              <a
                href="https://www.cdc.gov/ncbddd/hemoglobinopathies/scdc.html"
                className="hover:text-blue-800"
              >
                Sickle Cell Data Collection Program
              </a>
            </h3>
            <p>
              The Center For Disease Control and Prevention's Sickle Cell Data
              Collection (SCDC) program collects health information about people
              with sickle cell disease (SCD) to study long-term trends in
              diagnosis, treatment, and healthcare access for people with SCD in
              the United States. The program helps to inform policy and
              healthcare standards that improve and extend the lives of people
              with SCD.
            </p>
          </div>
        </div>
      </section>
      <Footer dataTestId="footer_da419e57-3eea-47aa-b91c-2de9bd05332b" />
    </main>
  );
};
