import { emptyApi } from "./emptyApi";
import { camelCaseResponse, providesList } from "../shared/Functions";
import { ContactListType } from "../types/mailTypes";

export const mailManagementService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSendgridEmailLists: builder.query<ContactListType[], void>({
      query: () => "/admin/sendgrid/get_all_lists",
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error) => providesList(results, "EmailLists"),
    }),
    getContactList: builder.query<ContactListType, string>({
      query: (contactListId: string) =>
        `/admin/sendgrid/contact_list/${contactListId}`,
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) => [{ type: "EmailLists", id: id }],
    }),
  }),
});

export const { useGetSendgridEmailListsQuery, useGetContactListQuery } =
  mailManagementService;
