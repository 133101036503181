/** @module Slices */
import { createSlice } from "@reduxjs/toolkit";

import { JobPostType } from "../../types";

const initialState: JobPostType = {
  id: 0,
  title: "",
  companyName: "",
  locationCity: "",
  locationState: "",
  shortDescription: "",
  logoUrl: "",
  tags: [],
  locationCountry: "",
  degreeRequirements: [],
  // jobType: "",
  // jobSetting: "",
  description: "",
  postedAt: "",
  applicationPeriodEndsAt: "",
  contactName: "",
  contactEmail: "",
};

export const jobPostSlice = createSlice({
  name: "jobPost",
  initialState,
  reducers: {
    clearJobPostState: (state) => {
      state = initialState;
      return state;
    },
  },
});
