/** @module Components */

import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ErrorToast, SuccessToast } from "../Shared";
import { useRequestPasswordResetMutation } from "../../services/userService";

export const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();

  const [requestPasswordReset, isSuccess] = useRequestPasswordResetMutation();

  const success = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="mt-modal" />, {
      duration: Infinity,
    });

  const error = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      duration: 4000,
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: "",
      }}
      validateOnChange={false}
      validationSchema={Yup.object({
        username: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
      })}
      onSubmit={(values, isValid) => {
        if (isValid) {
          const data = {
            username: values.username,
          };
          requestPasswordReset(data)
            .unwrap()
            .then(() => {
              if (isSuccess) {
                success(
                  "We sent a link to the email address associated with your account."
                );
              }
            })
            .catch(() => {
              error(
                "There was an issue submitting your reset form. Please contact support@sicklecellcenters.org if this problem persists."
              );
            });
        }
      }}
    >
      {({ values, handleSubmit, touched, errors }) => (
        <form
          className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
          data-testid="forgot-form-component"
          onSubmit={handleSubmit}
        >
          <fieldset className="bg-white pt-8 pb-4 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
            <h3
              className="pb-2 text-gray-700"
              data-testid="forgot-form-heading"
            >
              No problem. We&apos;ll send you an email to reset your password.
            </h3>
            <div data-testid="email-container">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
                data-testid="email-label"
              >
                Email address
              </label>
              <div className="mt-1">
                <Field
                  id="username"
                  name="username"
                  type="email"
                  autoComplete="email"
                  value={values.username}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    touched.username && errors.username
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="email-input"
                />
                {touched.username && errors.username ? (
                  <div
                    className="font-medium text-xs text-red-600 pt-1"
                    data-testid="email-error"
                  >
                    {errors.username}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row justify-end gap-x-4 py-4">
              <button
                className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md w-1/3
                shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap disabled-styling"
                data-testid="reset-button"
                type="submit"
              >
                Reset
              </button>
              <button
                className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md
                shadow-md text-base font-medium text-white bg-red-500 hover:bg-red-700 whitespace-nowrap disabled-styling"
                data-testid="login-button"
                onClick={() => navigate("/member_login")}
              >
                Back to login
              </button>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
