import React from "react";
import toast from "react-hot-toast";

import { ErrorToast, SuccessToast } from "./StyledToasts";

export const toastError = (message: string) =>
  toast.custom(<ErrorToast message={message} classNames="" />, {
    duration: 6000,
  });

export const toastSuccess = (message: string) =>
  toast.custom(<SuccessToast message={message} classNames="" />, {
    duration: 3000,
  });
