/** @module Slices */
import { createSlice } from "@reduxjs/toolkit";

import { User } from "../../types";

const initialState: User = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  bio: "",
  position: "",
  roles: [],
  token: "",
  refreshToken: "",
  tokenExpiry: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearLoginState: (state) => {
      state = initialState;
      return state;
    },
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      state.tokenExpiry = action.payload.tokenExpiry;
      state.id = action.payload.id;
      state.email = action.payload.email;
      return state;
    },
    logoutUser: (state) => {
      state = initialState;
      return state;
    },
  },
});
