import React from "react";
import "./UploadSpinner.css";

export const UploadSpinner: React.FC = (): JSX.Element => {
  return (
    <div className="upload-spinner" data-testid="upload-spinner">
      <div className="circle-spinner">
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
