import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { RootState } from "../redux/store";

const determineBaseUrl = () => {
  if (process.env.REACT_APP_RUN_ENV === "local") {
    console.warn("Local endpoint");
    return "http://localhost:8050";
  } else if (process.env.REACT_APP_RUN_ENV === "staging") {
    return "https://staging-nascc-backend.onrender.com";
  } else if (process.env.REACT_APP_RUN_ENV === "production") {
    return "https://production-nascc-backend.onrender.com";
  }
};

export const emptyApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: determineBaseUrl(),
    prepareHeaders: (headers, { getState }) => {
      const authToken = (getState() as RootState).user.token;
      if (authToken) {
        headers.set("Authorization", `Bearer ${authToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "User",
    "MemberCenter",
    "JobPost",
    "CenterApplication",
    "CenterApplicationEvent",
    "EmailLists",
  ],
});
