/** @modules Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { ConsensusDetailsColumns } from "../../components/Shared/Columns";
import { Table } from "../../components";
import { ConsensusRecommendationsType } from "../ConsensusResources/ConsensusRecommendations";

export const ConsensusRecommendationInfant: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState("1000");
  const tableData: ConsensusRecommendationsType[] = [
    {
      title: "Newborn Screening",
      text:
        "Notification of primary care provider, child’s caregiver(s), and identified sickle cell center of " +
        "positive results.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Newborn Screening",
      text:
        "Designated funding for coordinator support at sickle cell centers and the associated Department of " +
        "Health, to ensure timely follow up and engagement in care at a sickle cell center.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Visit Frequency",
      text:
        "Children with NBS-identified SCD should be seen as soon as possible after birth to confirm diagnosis, " +
        "initiate PCN, and provide education",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Visit Frequency",
      text:
        "Follow-up should occur by phone, telemedicine, or in-person to communicate the confirmatory " +
        "testing results when those results are available.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Visit Frequency",
      text:
        "All infants with SCD should be seen at least every 3 to 4 months in the first year of life, and " +
        "more frequently if clinically indicated.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Visit Frequency",
      text:
        "In the 2nd year of life, those with severe genotypes should be seen at least every 3 to 4 months.  " +
        "Those with less severe genotypes may be seen less frequently, but a minimum of twice per year.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Visit Frequency",
      text:
        "All children with SCD should be referred to a local and/or national community based SCD " +
        "organization for support.",
      typeOfRecommendation: "Standard",
    },

    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text: "Antibiotic prophylaxis should begin at or before 2 months of age.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text: "All patients with sickle cell disease should receive antibiotic prophylaxis.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text:
        "For premature infants, initiate antibiotic prophylaxis when an infant’s corrected gestational age " +
        "is term and taking full enteral feeds, or at discharge from the hospital.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text:
        "The standard medication for antibiotic prophylaxis is penicillin VK, at a dose of 125mg twice " +
        "daily by mouth at this age.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text: "Amoxicillin can also be utilized as an alternative.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Prevention of Invasive Pneumococcal Infection",
      text:
        "In instances of intolerance or allergy to penicillin or amoxicillin, cephalexin, erythromycin, " +
        "or azithromycin can be utilized.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Immunizations",
      text:
        "Pneumococcal Conjugate Vaccine (PCV, Prevnar): Ensure that children with any sickle cell " +
        "genotype receive the full four dose pneumococcal conjugate vaccine series with recommended doses at " +
        "2, 4, 6, and 12-15 months. These vaccinations should ideally be administered by the primary care " +
        "physician with the pediatric sickle cell center serving as a backup if patients are unable to " +
        "establish or maintain care with their PCP.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Immunizations",
      text:
        "Pneumococcal Polysaccharide Vaccine (PPSV23, Pneumovax): Children with all genotypes receive " +
        "PPSV-23 at two years of age with a second dose at age 5 years.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Immunizations",
      text:
        "Meningococcal ACWY Vaccine (Menactra/Menveo) and Bexsero: Children with all genotypes should " +
        "receive meningococcal vaccines as per the latest ACIP guidelines.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Immunizations",
      text: "Beginning at 6 months of age, we recommend annual influenza vaccines.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Immunizations",
      text: "We recommend routine SARS-CoV2 vaccination and associated booster doses per CDC guidelines.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Immunizations",
      text:
        "For children who are being considered for surgical splenectomy, ensure pneumococcal and " +
        "meningococcal vaccination series are up to date prior to surgery.",
      typeOfRecommendation: "Standard",
    },

    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Hydroxyurea should be initiated at 9 months of age for children with sickle cell anemia " +
        "(HbSS, HbSβ0-thalassemia).",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Comprehensive laboratory evaluation should be performed prior to initiation of hydroxyurea " +
        "therapy, including complete blood count with WBC differential, absolute reticulocyte count, " +
        "comprehensive metabolic panel with LDH, and %HbF.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Initial dosing should be no less than 20 mg/kg with dose escalation to achieve the maximum " +
        "tolerated dose. Dose escalation should occur in increments of 2.5-5 mg/kg/day every 8 weeks with " +
        "the goal to achieve mild myelosuppression " +
        "(typically defined by absolute neutrophil count 1.5-3.0x109/L), up to a maximum dose of 35mg/kg/day.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Careful evaluation of the CBC should occur at each visit, including review of the ANC, " +
        "hemoglobin and ARC, and platelet count. During the dose escalation period, CBC with WBC " +
        "differential and ARC are recommended every 1-2 months with reduced frequency to every 3 months " +
        "after a stable hydroxyurea dose is established.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Discussions regarding hydroxyurea for children with sickle cell anemia should be initiated with " +
        "caregiver(s) as early as the first visit. This should include discussion of the rationale, benefits, " +
        "potential or putative risks, and possible concerns or barriers to initiation.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "Percent HbF should be evaluated at least once per year or more to monitor for medication effect " +
        "and medication adherence.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Counseling and Initiation",
      text:
        "At each visit, if there is no evidence of hematologic toxicity, the hydroxyurea dose " +
        "should be adjusted to maintain the optimal mg/kg dose.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Education at Visits",
      text:
        "The first visit should include the following assessments and disease-based education:\n" +
        "1. Pathophysiology and inheritance of SCD\n" +
        "2. Fever precautions \n" +
        "3. Splenic sequestration and spleen palpation\n" +
        "4. Dactylitis\n" +
        "5. Introduce hydroxyurea as disease modifying therapy\n" +
        "6. Ensure family has an emergency plan and contact phone number\n" +
        "7. Ensure child has a primary care provider\n",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Laboratory Evaluations",
      text:
        "The following labs should be performed at 1 year of age (or prior to starting HU): " +
        "CBC with WBC differential, retic, BMP, bilirubin, transaminases, LDH, Hb analysis, 25-OH vitamin D, " +
        "ferritin, iron studies",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Laboratory Evaluations",
      text:
        "A CBC w/differential and ARC should be performed 2 to 4 times a year in the first 2 years of life " +
        "to establish baseline values.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Laboratory Evaluations",
      text:
        "Extended red cell phenotype and/or genotype should be performed at 1 year of life or prior " +
        "to first transfusion.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Screening for Risk of Stroke",
      text:
        "Transcranial doppler (TCD) ultrasound should be performed annually for children with SCA " +
        "genotypes beginning at 2 years of age and through 16 years of age.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Screening for Risk of Stroke",
      text:
        "If unsuccessful at the first attempt, we recommend repeating TCD 3-6 months later until " +
        "successful with annual exams thereafter depending on results and additional risk factors.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Acute Complications - Splenic Sequestration",
      text:
        "Documentation of splenic size by physical examination should be performed during a " +
        "well-visit to establish a baseline.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Acute Complications - Splenic Sequestration",
      text:
        "For patients experiencing severe splenic sequestration indicated by drop in hemoglobin ≥ 2g/dL " +
        "and signs of hemodynamic instability, or hemoglobin < 6 g/dL, we recommend transfusing PRBC. " +
        "PRBCs should be given in small aliquots to avoid hyperviscosity from auto-transfusion from the " +
        "enlarged spleen. If multiple aliquots are required, they should be from the same split unit of " +
        "PRBC whenever feasible.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Acute Complications - Splenic Sequestration",
      text:
        "Children with SCD older than 2 years of age, experiencing two or more episodes of splenic " +
        "sequestration requiring PRBC transfusion per the criteria above, can be referred for splenectomy. ",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Acute Complications - Fever",
      text: "Sickle cell centers should have a standard algorithm for managing fever in children with SCD.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Acute Complications - Fever",
      text:
        "Fever in SCD is defined as a temperature of 101.3F or 38.5C in children >2 months of age. " +
        "In children under 2 months of age, fever is defined as a temperature of 100.4F. Children with SCD " +
        "under 2 months of age should follow the general AAP fever guidelines for this age group.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Acute Complications - Fever",
      text:
        "All children <2 years of age with SCD and fever should be evaluated urgently. " +
        "The location of this evaluation is dependent on comfort of the provider and resources available.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Acute Complications - Fever",
      text:
        "Hospitalization of children with SCD and fever should be based on the presence of high-risk " +
        "criteria (e.g., temp >39.5C, complications such as acute chest or splenic sequestration).",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Reproductive Counseling",
      text:
        "Expectant parents or individuals planning a pregnancy should be tested for a " +
        "hemoglobinopathy trait by their Obstetrician.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Reproductive Counseling",
      text:
        "Trait status be evaluated using a hemoglobinopathy evaluation test (e.g. HPLC, IE, CZE), and " +
        "not sickledex or sickle solubility testing.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Counseling",
      text:
        "If a parent has RBC microcytosis or an elevated hemoglobin A2, they should have genetic " +
        "testing for beta thalassemia trait and be appropriately assessed with consultation from a " +
        "hematologist or genetic counselor. This is particularly important if the other parent has S trait.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Counseling",
      text: "Parents should receive genetic counseling about inheritance and risk of future offspring with SCD.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Counseling",
      text:
        "Parents should be referred to a genetic counselor knowledgeable about hemoglobinopathies the " +
        "child’s first SCD visit or deferred to a subsequent visit per family preference for discussion of " +
        "future risk of offspring with SCD and possibility of preimplantation genetic testing.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Screening for Social Determinants of Health",
      text:
        "All families should have annual screening for social determinants of health to identify " +
        "barriers to care and to offer resource assistance for those with identified needs.",
      typeOfRecommendation: "Standard",
    },

    {
      title: "Collaboration with Primary Care",
      text:
        "All children with SCD should have a primary care physician for routine health maintenance, " +
        "immunizations, and non-SCD related care.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Collaboration with Primary Care",
      text:
        "Hematologists should communicate with primary care physicians regularly to facilitate " +
        "continuity of care.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Screening for Age Appropriate Development",
      text:
        "Age-appropriate developmental screening should be performed by the child’s primary " +
        "care physician, with confirmation by the sickle cell team. If screening has not been done by 1 " +
        "year of age, or significant concern for delays, we recommend screening should be performed " +
        "by the sickle cell team.",
      typeOfRecommendation: "Recommendation",
    },
  ];

  const memoizedConsensusColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(ConsensusDetailsColumns, []);

  React.useEffect(() => {
    const firstHeader = document.getElementById("header-1")?.closest("th");
    const secondHeader = document.getElementById("header-2")?.closest("th");
    const thirdHeader = document.getElementById("header-3")?.closest("th");
    if (firstHeader) {
      firstHeader.style.width = "20% important!";
    }
    if (secondHeader) {
      secondHeader.style.width = "70%";
    }
    if (thirdHeader) {
      thirdHeader.style.width = "10%";
    }
  });

  return (
    <>
      <Helmet>
        <title>NASCC - Infant Recommendations</title>
        <meta
          name="description"
          content="NASCC consensus recommendations for infant patients with Sickle Cell Disease gathered from
          the NASCC annual meeting."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <section className="static-page-section mt-10">
          <h3 className="static-page-header pl-8">
            Care Delivery and Health Maintenance Consensus Recommendations for 0
            - 2 years old
          </h3>
          <p className="pl-8 pt-2 pb-8">
            There is a link to download the recommendation document at the
            bottom of the page.
          </p>
          <Table
            columns={memoizedConsensusColumns}
            data={tableData}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={false}
            headerCellClassName="pl-4"
            customRowCellClassName="pl-4 whitespace-normal"
            searchable={true}
          />
        </section>
        <section className="static-page-section mt-10">
          <h3 className="static-page-header">
            Download the Consensus Recommendations
          </h3>
          <ul className="list-disc pl-6">
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/30m7i7uy3jt224ddzbtjy/Table-for-Conensus-Recommendations-0-2-YO.docx?rlkey=55pm4ago1ar3v2q1lq4j6spdb&dl=0"
                className="link-text"
              >
                {" "}
                Care Delivery and Health Maintenance Consensus Recommendations
                for 0 - 2 years old
              </Link>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};
