/** @module Components */
import React from "react";
import { Field, useFormikContext } from "formik";

import { CenterApplicationFormType } from "../../types/applicationTypes";

export const CenterDocumentationForm: React.FC = () => {
  const { initialValues } = useFormikContext<CenterApplicationFormType>();

  return (
    <div className="bg-white shadow px-4 py-5 border border-gray-200 sm:rounded-lg sm:p-6 md:shadow-xl">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Documentation, Protocols, and Treatment Access
          </h3>
          <p
            className="mt-1 text-sm text-gray-500"
            id="center-documentation-description"
          >
            Please check off the different types of documentation, protocols,
            and other items you have at your clinic.
          </p>
        </div>
        <fieldset
          className="mt-5 md:grid md:grid-cols-2 md:mt-0 md:col-span-2 md:gap-y-4"
          id="center-documentation-fieldset"
          aria-describedby="center-documentation-description"
        >
          {/* Written Procedures for SCD */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="written-procedure-for-scd"
                aria-describedby="written-procedure-for-scd-description"
                name="writtenProcForSickleCellDisease"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="written-procedure-for-scd"
                className="font-medium text-gray-700"
              >
                Written Procedures for Acute and Chronic Sickle Cell Disease
              </label>
              <p
                id="written-procedure-for-scd-description"
                className="text-gray-500"
              >
                Check this if you have written procedures or guidelines for
                treating acute and/or chronic sickle cell disease.
              </p>
            </div>
          </div>

          {/* Emergency Department Protocols for SCD Management */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="emergency-dept-protocols"
                aria-describedby="emergency-dept-protocols-description"
                name="emergencyDeptProtocolsForScd"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="emergency-dept-protocols"
                className="font-medium text-gray-700"
              >
                Emergency Department Protocols for SCD Management
              </label>
              <p
                id="emergency-dept-protocols-description"
                className="text-gray-500"
              >
                Check this if you have established emergency department
                protocols for sickle cell disease management.
              </p>
            </div>
          </div>

          {/* Opioid Policy */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="opioid-policy"
                aria-describedby="opioid-policy-description"
                name="opioidPolicy"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="opioid-policy"
                className="font-medium text-gray-700"
              >
                Opioid Policy
              </label>
              <p id="opioid-policy-description" className="text-gray-500">
                Check this if you have an established opioid policy.
              </p>
            </div>
          </div>

          {/* Infusion Policy */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="infusion-policy"
                aria-describedby="infusion-policy-description"
                name="infusionPolicy"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="infusion-policy"
                className="font-medium text-gray-700"
              >
                Infusion Policy
              </label>
              <p id="infusion-policy-description" className="text-gray-500">
                Check this if you have an established infusion policy.
              </p>
            </div>
          </div>

          {initialValues.centerType &&
            initialValues.centerType !== "pediatric" && (
              <>
                {/* Protocol for Tracking Quality Metrics */}
                <div className="flex pt-3">
                  <div className="flex items-center h-5">
                    <Field
                      id="protocol-tracking-quality-metrics"
                      aria-describedby="protocol-tracking-quality-metrics-description"
                      name="trackingQualityMetricsProtocol"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="protocol-tracking-quality-metrics"
                      className="font-medium text-gray-700"
                    >
                      Protocol for Tracking Quality Metrics
                    </label>
                    <p
                      id="protocol-tracking-quality-metrics-description"
                      className="text-gray-500"
                    >
                      Check this if you have an established protocol or
                      procedure for tracking quality metrics.
                    </p>
                  </div>
                </div>

                {/* Protocol for Newly Transitioned Adult */}
                <div className="flex pt-3">
                  <div className="flex items-center h-5">
                    <Field
                      id="protocol-newly-transitioned-adult"
                      aria-describedby="protocol-newly-transitioned-adult-description"
                      name="recNewlyTransToAdultProtocol"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="protocol-newly-transitioned-adult"
                      className="font-medium text-gray-700"
                    >
                      Protocol for Newly Transitioned to Adult
                    </label>
                    <p
                      id="protocol-newly-transitioned-adult-description"
                      className="text-gray-500"
                    >
                      Check this if you have a protocol for patients that have
                      recently transition to adult care.
                    </p>
                  </div>
                </div>
              </>
            )}

          {initialValues.centerType && initialValues.centerType !== "adult" && (
            <>
              {/* Protocol for Transition to Adult Care */}
              <div className="flex pt-3">
                <div className="flex items-center h-5">
                  <Field
                    id="protocol-transition-to-adult-care"
                    aria-describedby="protocol-transition-to-adult-care-description"
                    name="transToAdultProtocol"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="protocol-transition-to-adult-care"
                    className="font-medium text-gray-700"
                  >
                    Protocol for Transition to Adult Care
                  </label>
                  <p
                    id="protocol-transition-to-adult-care-description"
                    className="text-gray-500"
                  >
                    Check this if you have a protocol for patients that will be
                    transitioning to adult care, starting in early/mid
                    adolescence.
                  </p>
                </div>
              </div>

              {/* Access to Compound Hydroxyurea */}
              <div className="flex pt-3">
                <div className="flex items-center h-5">
                  <Field
                    id="access-compound-hydroxyurea"
                    aria-describedby="access-compound-hydroxyurea-description"
                    name="accessToCompoundHydroxyurea"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="access-compound-hydroxyurea"
                    className="font-medium text-gray-700"
                  >
                    Access to Compound Hydroxyurea
                  </label>
                  <p
                    id="access-compound-hydroxyurea-description"
                    className="text-gray-500"
                  >
                    Check this if your clinic has access to compound
                    hydroxyurea.
                  </p>
                </div>
              </div>
            </>
          )}

          {/* Access to Crizanlizumab */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="access-to-crizanlizumab"
                aria-describedby="access-to-crizanlizumab-description"
                name="accessCrizanlizumabInfusions"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="access-to-crizanlizumab"
                className="font-medium text-gray-700"
              >
                Access to Crizanlizumab Infusions
              </label>
              <p
                id="access-to-crizanlizumab-description"
                className="text-gray-500"
              >
                Check this if your clinic has access to crizanlizumab infusions.
              </p>
            </div>
          </div>

          {/* Access to Prescribe Voxeletor */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="access-to-prescribe-voxeletor"
                aria-describedby="access-to-prescribe-voxeletor-description"
                name="accessPrescribeVoxeletor"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="access-to-prescribe-voxeletor"
                className="font-medium text-gray-700"
              >
                Access to Prescribe Voxeletor
              </label>
              <p
                id="access-to-prescribe-voxeletor-description"
                className="text-gray-500"
              >
                Check this if your clinic has the ability to prescribe
                voxeletor.
              </p>
            </div>
          </div>

          {/* Access to Pharmaceutical Trials */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="access-to-pharmaceutical-trials"
                aria-describedby="access-to-pharmaceutical-trials-description"
                name="accessPharmaClinicalTrials"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="access-to-pharmaceutical-trials"
                className="font-medium text-gray-700"
              >
                Access to Pharmaceutical Trials
              </label>
              <p
                id="access-to-pharmaceutical-trials-description"
                className="text-gray-500"
              >
                Check this if your clinic has access to pharmaceutical clinic
                trials.
              </p>
            </div>
          </div>

          {/* Access to Stem Cell Transplants */}
          <div className="flex pt-3">
            <div className="flex items-center h-5">
              <Field
                id="access-to-stem-cell-transplants"
                aria-describedby="access-to-stem-cell-transplants-description"
                name="accessStemCellTranspant"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="access-to-stem-cell-transplants"
                className="font-medium text-gray-700"
              >
                Access to Stem Cell Transplants
              </label>
              <p
                id="access-to-stem-cell-transplants-description"
                className="text-gray-500"
              >
                Check this if your clinic has access to stem cell transplants.
              </p>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
