import { emptyApi } from "./emptyApi";
import {
  camelCaseResponse,
  providesList,
  toTitleCase,
} from "../shared/Functions";
import {GeneralApiResponse, User, UserLoginData} from "../types";
import {ApiResetPasswordData, ApiResetTokenData} from "../types/apiTypes";

export const userService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getOfficers: builder.query<User[], void>({
      query: () => "/users?officers=true",
      transformResponse: (response: any) => {
        return processUsers(camelCaseResponse(response));
      },
      providesTags: (results, error, id) => providesList(results, "User"),
    }),
    loginUser: builder.mutation<User, UserLoginData>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: processCredentials(credentials),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    requestPasswordReset: builder.mutation<
      ApiResetPasswordData,
      ApiResetPasswordData
      >({
      query: (data: ApiResetPasswordData) => ({
        url: "/user/reset_password",
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    checkResetToken: builder.query<ApiResetTokenData, string>({
      query: (token: string) => `/user/reset_token/${token}`,
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    setNewPassword: builder.mutation<GeneralApiResponse, ApiResetPasswordData>({
      query: (data: ApiResetPasswordData) => ({
        url: `/user/set_new_password?reset_password_token=${data.token}`,
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const { useGetOfficersQuery, useLoginUserMutation, useRequestPasswordResetMutation, useCheckResetTokenQuery, useSetNewPasswordMutation } = userService;

function processUsers(apiUsers: Record<string, unknown>[]): User[] {
  return apiUsers.map((user: any) => {
    const position = user.roles.filter((role: Record<string, unknown>) => {
      return (
        role.name === "president" ||
        role.name === "vice_president" ||
        role.name === "secretary" ||
        role.name === "treasurer"
      );
    });
    return {
      id: user.id,
      firstName: user.details.firstName,
      lastName: user.details.lastName,
      email: user.email,
      roles: user.roles,
      bio: user.details.bio,
      position: (position && position.length !== 0) ? toTitleCase(position[0].name) : "",
      token: "",
      refreshToken: "",
      tokenExpiry: null,
    };
  });
}

function processCredentials(credentials: UserLoginData): UserLoginData {
  return credentials;
}
