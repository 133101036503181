/**
 * This page contains resources for Sickle Cell treatment providers.
 *
 * @module Containers
 */
import React from "react";
import { Helmet } from "react-helmet-async";

import { ProviderResourcesDisplay } from "../../components";

export const ProviderResources: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Provider Resources</title>
        <meta
          name="description"
          content="Resources for providers who work with patients who have sickle cell disease."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">Provider Resources</h3>
        <ProviderResourcesDisplay />
      </main>
    </>
  );
};
