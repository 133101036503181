import { emptyApi } from "./emptyApi";
import { ContactForm } from "../types";
import { camelCaseResponse } from "../shared/Functions";

export const contactService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    postContactForm: builder.mutation<void, ContactForm>({
      query: (contactForm: ContactForm) => ({
        url: "/contact_form",
        method: "POST",
        body: processContactForm(contactForm),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const { usePostContactFormMutation } = contactService;

function processContactForm(
  contactFormInfo: ContactForm
): Record<string, unknown> {
  return {
    full_name: contactFormInfo.fullName,
    email: contactFormInfo.email,
    message: contactFormInfo.message,
  };
}
