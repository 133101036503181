/** @modules Containers */
import React from "react";
import { Helmet } from "react-helmet-async";

export const EducationalResources: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Educational Resources</title>
        <meta
          name="description"
          content="Educational resources and links for patient providers and patients who have sickle cell disease."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">Educational Resources</h3>
        <section className="static-page-section">Coming soon!</section>
      </main>
    </>
  );
};
