/** @module Containers */
import React from "react";
import { useParams } from "react-router-dom";
import { ColumnWithStrictAccessor } from "react-table";

import { useGetContactListQuery } from "../../services/mailManagementService";
import { toTitleCase } from "../../shared/Functions";
import { Table } from "../../components";
import { SharedContactListColumns } from "../../components/Shared/Columns";

export const ContactListDetails: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );
  const { contactListId } = useParams();
  const {
    data: contactList,
    isLoading: contactListLoading,
    isFetching: contactListFetching,
    isError: contactListError,
  } = useGetContactListQuery(contactListId || "");
  console.log(contactList);

  const memoizedSharedContactListColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedContactListColumns, []);

  return (
    <>
      {contactList && (
        <>
          <h3 className="static-page-header mx-8">
            Contact List - {toTitleCase(contactList.name)}
          </h3>
          <h5 className="mx-8 text-lg text-gray-700">
            {contactList.description}
          </h5>
          <div className="flex flex-col pt-4">
            {!contactListLoading &&
              !contactListFetching &&
              !contactListError &&
              contactList &&
              contactList.members &&
              contactList.members.length > 0 && (
                <Table
                  columns={memoizedSharedContactListColumns}
                  data={contactList.members}
                  selectedPageSize={selectedPageSize}
                  setSelectedPageSize={setSelectedPageSize}
                  fetching={contactListFetching}
                  headerCellClassName="pl-4"
                  customRowCellClassName="pl-4"
                />
              )}
            {!contactListLoading &&
              !contactListFetching &&
              !contactListError &&
              contactList &&
              contactList.members &&
              contactList.members.length === 0 && (
                <p className="mx-8 font-bold text-2xl">
                  No contacts found for this list.
                </p>
              )}
          </div>
        </>
      )}
    </>
  );
};
