import React from "react";
import { ColumnWithStrictAccessor } from "react-table";

import { CombinedCenterDetailsType } from "../../types";
import { SharedEmployeeColumns } from "../Shared";
import { Table } from "../Table/Table";

/** @category Prop Types */
export type CenterDetailsProps = {
  combinedCenterDetails: CombinedCenterDetailsType;
};
export const CenterEmployees: React.FC<CenterDetailsProps> = ({
  combinedCenterDetails,
}: CenterDetailsProps) => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );

  const memoizedSharedEmployeeColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedEmployeeColumns, []);

  return (
    <section>
      <div className="bg-white border-t border-gray-300 border-2 mx-4 my-2 shadow sm:rounded-lg">
        {/* Card Header */}
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-2xl leading-6 font-semibold text-gray-900">
            Center Employees
          </h3>
        </div>
        <div className="-mx-2">
          <Table
            columns={memoizedSharedEmployeeColumns}
            data={combinedCenterDetails.employees}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={false}
            customRowCellClassName="pl-4"
            headerCellClassName="pl-4"
          />
        </div>
      </div>
    </section>
  );
};
