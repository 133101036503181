/** @module Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";

import { useGetCenterApplicationsInfoQuery } from "../../services/applicationService";
import { Table } from "../../components";
import { SharedApplicationColumns } from "../../components/Shared/Columns";

export const ApprovedApplications: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );

  const {
    data: approvedCenterApplications,
    isFetching,
    isLoading,
    isError,
  } = useGetCenterApplicationsInfoQuery("accepted");

  const memoizedSharedApplicationColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedApplicationColumns, []);

  return (
    <section>
      <h4 className="static-page-header mx-8">Approved Applications</h4>
      <div className="flex flex-col">
        {!isError &&
          approvedCenterApplications && (
            <Table
              columns={memoizedSharedApplicationColumns}
              data={approvedCenterApplications}
              selectedPageSize={selectedPageSize}
              setSelectedPageSize={setSelectedPageSize}
              fetching={isFetching || isLoading}
              headerCellClassName="pl-4"
              customRowCellClassName="pl-4"
            />
          )}
      </div>
    </section>
  );
};
