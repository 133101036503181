/** @module Components */
import React from "react";
import {
  CheckIcon,
  EnvelopeOpenIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

import { format, parseJSON } from "date-fns";

// const eventIcons = {
//   received: {
//     icon: UserIcon,
//     iconBackground: "bg-gray-400",
//   },
// };

// const timeline = [
//   {
//     id: 1,
//     content: "Applied to",
//     target: "Front End Developer",
//     href: "#",
//     date: "Sep 20",
//     datetime: "2020-09-20",
//     icon: UserIcon,
//     iconBackground: "bg-gray-400",
//   },
//   {
//     id: 2,
//     content: "Advanced to phone screening by",
//     target: "Bethany Blake",
//     href: "#",
//     date: "Sep 22",
//     datetime: "2020-09-22",
//     icon: ThumbUpIcon,
//     iconBackground: "bg-blue-500",
//   },
//   {
//     id: 3,
//     content: "Completed phone screening with",
//     target: "Martha Gardner",
//     href: "#",
//     date: "Sep 28",
//     datetime: "2020-09-28",
//     icon: CheckIcon,
//     iconBackground: "bg-green-500",
//   },
//   {
//     id: 4,
//     content: "Advanced to interview by",
//     target: "Bethany Blake",
//     href: "#",
//     date: "Sep 30",
//     datetime: "2020-09-30",
//     icon: ThumbUpIcon,
//     iconBackground: "bg-blue-500",
//   },
//   {
//     id: 5,
//     content: "Completed interview with",
//     target: "Katherine Snyder",
//     href: "#",
//     date: "Oct 4",
//     datetime: "2020-10-04",
//     icon: CheckIcon,
//     iconBackground: "bg-green-500",
//   },
// ];

export type ActivityFeedEventType = {
  id: number;
  eventText: string;
  eventTimestamp: string;
};

/** @category Prop Type */
export type ActivityFeedProps = {
  events: ActivityFeedEventType[];
};

export const ActivityFeed: React.FC<ActivityFeedProps> = ({
  events,
}: ActivityFeedProps) => {
  return (
    <div className="flow-root pr-16 pl-16">
      <h3 className="text-xl font-bold text-gray-800 pb-2">
        Application Timeline
      </h3>
      <ul className="-mb-8">
        {events.length === 0 && (
          <p className="font-bold text-gray-500">
            No events have been recorded for this application yet.
          </p>
        )}
        {events.length > 0 &&
          events.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== events.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    {event.eventText === "Received application" && (
                      <span className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400">
                        <UserIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                    {(event.eventText.startsWith("First approval by") ||
                      event.eventText.startsWith("Second approval by")) && (
                      <span className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-green-500">
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                    {event.eventText.startsWith("Approval email sent to") && (
                      <span className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-green-500">
                        <EnvelopeOpenIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </div>
                  <div className="min-w-0 flex-1 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">
                        {event.eventText}{" "}
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={event.eventTimestamp}>
                        {format(
                          parseJSON(event.eventTimestamp),
                          "LLL dd, yyyy",
                        )}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};
