/** @module Components */
import React, { ReactNode } from "react";
import { CenterType, MemberCenter } from "../../types";
import { toTitleCase } from "../../shared/Functions";

export type CenterListType = {
  memberCenters: MemberCenter[];
};

/** @category Prop Types */
export const CenterList: React.FC<CenterListType> = ({
  memberCenters,
}: CenterListType) => {
  return (
    <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
      {memberCenters.map((center, index): ReactNode => {
        return (
          <li
            className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8"
            key={`${index}`}
          >
            <div className="sm:col-span-2 text-lg leading-6 font-medium space-y-1">
              <h3>
                <a className="link-text" href={center.websiteAddress}>
                  {toTitleCase(center.centerName)}
                </a>
              </h3>
              <p className="text-gray-500 font-normal">
                Center Type:{" "}
                {center.centerType ? (
                  <span
                    className={`${
                      center.centerType === CenterType.WholeLife.toLowerCase()
                        ? "bg-indigo-700"
                        : center.centerType === CenterType.Adult.toLowerCase()
                        ? "bg-rose-600"
                        : "bg-blue-600"
                    } rounded-lg text-white px-2 py-1`}
                  >
                    {toTitleCase(center.centerType.toString())}
                  </span>
                ) : (
                  "Unknown Type"
                )}
              </p>
              <p className="text-gray-500 font-normal">
                {toTitleCase(center.streetAddress)}
              </p>
              <p className="text-gray-500 font-normal">
                {center.city}, {center.state}
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
