/** @module Components */
import React from "react";
import {
  ColumnWithStrictAccessor,
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";

import { SearchBar, UploadSpinner, CustomHeader } from "..";
import CustomRow from "../CustomRow/CustomRow";

/** @category Prop Types */
export type TableProps = {
  columns: ColumnWithStrictAccessor<Record<string, unknown>>[];
  data: Record<string, unknown>[];
  selectedPageSize: string;
  setSelectedPageSize: (pageSize: string) => void;
  fetching: boolean;
  headerRowClassName?: string;
  headerCellClassName?: string;
  customRowClassName?: string;
  customRowCellClassName?: string;
  searchable?: boolean;
};

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  selectedPageSize,
  setSelectedPageSize,
  fetching,
  headerRowClassName,
  headerCellClassName,
  customRowClassName,
  customRowCellClassName,
  searchable = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    headerGroups,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: parseInt(selectedPageSize) },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <div className="max-w-8xl mx-auto w-full pb-10">
      {searchable && (
        <SearchBar
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <div className="flex flex-col mx-8">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden border border-gray-200 rounded-lg">
          <table {...getTableProps()} className="w-full">
            <CustomHeader
              headerGroups={headerGroups}
              headerRowClassName={headerRowClassName}
              headerCellClassName={headerCellClassName}
            />
            <CustomRow
              rows={page}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              customRowClassName={customRowClassName}
              customRowCellClassName={customRowCellClassName}
            />
          </table>
          {(fetching && (
            <div className="py-4" data-testid="loading-container">
              <div
                className="flex justify-center"
                data-testid="loading-spinner"
              >
                <UploadSpinner />
              </div>
            </div>
          )) ||
            (!fetching && !page.length && (
              <div className="py-4" data-testid="default-message-container">
                <p className="text-center" data-testid="empty-table-message">
                  No information found
                </p>
              </div>
            ))}
          {pageCount > 1 && (
            <footer
              className="bg-gray-50 flex justify-between px-6 border-t border-gray-200"
              data-testid="table-footer"
            >
              <div
                className="flex w-1/2 py-3 items-center"
                data-testid="results-container"
              >
                <p
                  className="font-medium text-base text-gray-500 tracking-wide"
                  data-testid="showing-results-text"
                >
                  Showing {parseInt(page[0].id) + 1} to{" "}
                  {parseInt(page[page.length - 1].id) + 1} of {data.length}{" "}
                  results
                </p>
              </div>
              <div
                className="py-3 pr-2 w-1/2 flex justify-end items-center"
                data-testid="pagination-container"
              >
                <select
                  value={pageSize}
                  onChange={(event) => {
                    setPageSize(parseInt(event.target.value));
                    setSelectedPageSize(event.target.value);
                  }}
                  className="font-medium text-base text-gray-500 block h-9 pl-3 py-0.5 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mr-4"
                  data-testid="page-size-select"
                >
                  {[10, 20, 30, 40, 50].map((number) => (
                    <option
                      key={number}
                      value={number}
                      data-testid="page-size-option"
                    >
                      {number} per page
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="font-medium text-base text-gray-500 tracking-wide pr-2"
                  data-testid="first-page-button"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="font-medium text-base text-gray-500 tracking-wide pr-1"
                  data-testid="previous-page-button"
                >
                  {"<"}
                </button>
                <p
                  className="py-3 pl-2 pr-2 font-medium text-base text-gray-500 tracking-wide"
                  data-testid="result-counter-text"
                >
                  Page {pageIndex + 1} of {pageOptions.length}
                </p>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="font-medium text-base text-gray-500 tracking-wide pl-1"
                  data-testid="next-page-button"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="font-medium text-base text-gray-500 tracking-wide pl-2"
                  data-testid="last-page-button"
                >
                  {">>"}
                </button>
              </div>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};
