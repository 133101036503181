/** @modules Containers */
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export type ConsensusRecommendationsType = {
  title: string;
  text: string;
  typeOfRecommendation: string;
};

export const ConsensusRecommendations: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Consensus Recommendations</title>
        <meta
          name="description"
          content="NASCC consensus recommendations gathered from the NASCC annual meeting."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">
          National Alliance of Sickle Cell Centers Consensus Recommendations
        </h3>
        <section className="static-page-section">
          <h4 className="font-bold text-2xl pb-3">Background</h4>
          <p>
            Guidelines for routine care and monitoring of patients with sickle
            cell disease (SCD) exist across several different organizations. In
            the United States, these include the{" "}
            <strong>American Academy of Pediatrics</strong> (AAP) Health
            Supervision for Children with SCD; the{" "}
            <strong>American College of Emergency Physicians</strong> (ACEP) SCD
            point of care tool; the <strong>American Journal of Nursing</strong>{" "}
            (AJN) evidence review of nursing assessments; the{" "}
            <strong>American Society of Hematology</strong> (ASH) Clinical
            Practice Guidelines; the{" "}
            <strong>
              National Academies of Sciences Engineering and Medicine
            </strong>{" "}
            (NASEM) Strategic Plan and Blueprint for Action; the{" "}
            <strong>National Heart, Lung, and Blood Institute</strong> (NHLBI)
            Expert Panel Report; and the{" "}
            <strong>National Institutes of Health</strong> (NIH). In Europe, the
            UK (British Journal of Haematology), Italy (Italian Association of
            Pediatric Hematology-Oncology [AIEOP]), and France also have their
            own guidelines.{" "}
          </p>
          <br />
          <p>
            The <strong>National Alliance of Sickle Cell Centers</strong>{" "}
            (NASCC) conducted the first consensus meeting July, 2022, to develop
            consensus recommendations for SCD management in areas where current
            guidelines do not exist, are outlined or conflicting from different
            groups or lack specificity and rigor. As a part of that meeting,
            participants were sent literature to review ahead of time and asked
            to complete a survey to assess their center’s current SCD
            management. First topics are presented here as tables for
            recommendations for routine screening and assessments for people
            living with sickle cell disease.{" "}
            <strong>
              There is also a link at the bottom of the page to download each
              table.
            </strong>{" "}
            The topics through these links are for the age groups 0-2 years,
            2-18 years, and greater than 18 years old.
          </p>
          <br />
          <div>
            <strong>Current (searchable) NASCC recommendation tables:</strong>
            <ul className="list-disc pl-6">
              <li>
                <Link
                  to="/consensus_recommendations/infant"
                  className="link-text"
                >
                  Consensus Recommendations for Children 0-2 Years Old
                </Link>
              </li>
              <li>
                <Link
                  to="/consensus_recommendations/pediatric"
                  className="link-text"
                >
                  Consensus Recommendations for Children 2-18 Years Old
                </Link>
              </li>
              <li>
                <Link
                  to="/consensus_recommendations/adult"
                  className="link-text"
                >
                  Consensus Recommendations for Adults Over 18 Years Old
                </Link>
              </li>
              <li>
                <Link
                  to="/consensus_recommendations/gene_therapy_transformative_centers"
                  className="link-text"
                >
                  Consensus Recommendations for Transformative Sickle Cell
                  Disease Centers
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <p>
            Additional consensus recommendations are pending as well including
            the following topics:
          </p>
          <ul className="list-decimal pl-6 pt-2">
            <li>
              Identifying Multi-Organ Failure Syndrome in Sickle Cell Disease
              and the Need to Transfer Patients for Management
            </li>
            <li>
              Recognizing SCD Center Requirements for Genetic and Transplant
              Centers
            </li>
            <li>Defining and Tracking Transition Outcomes</li>
            <li>Iron Overload: Diagnosis and Management</li>
            <li>Defining a Sickle Cell Disease Specialist</li>
          </ul>
          <h3 className="text-2xl font-bold pt-6">Links for Downloading</h3>
          <ul className="list-disc pl-6">
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/30m7i7uy3jt224ddzbtjy/Table-for-Conensus-Recommendations-0-2-YO.docx?rlkey=55pm4ago1ar3v2q1lq4j6spdb&dl=0"
                className="link-text"
              >
                Care Delivery and Health Maintenance Consensus Recommendations
                for 0 - 2 years (docx)
              </Link>
            </li>
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/y0gtnbgswdabnazbyu93b/Table-for-Consensus-Recommendations-2-18-YO.pdf?rlkey=zoy67b3466esm5ujcsmcf6bax&st=vvhbler3&dl=0"
                className="link-text"
              >
                Care Delivery and Health Maintenance Consensus Recommendations
                for 2 - 18 years old (pdf)
              </Link>
            </li>
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/1jo9i3acjo1erbg5mwwcu/Table-for-Consensus-Recommendations-18.docx?rlkey=c0fkkeqw9pm1w5cu2iutkunq0&st=hfl31f4f&dl=0"
                className="link-text"
              >
                Care Delivery and Health Maintenance Consensus Recommendations
                for 18+ years old (pdf)
              </Link>
            </li>
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/co1oc07u4jo02gi3drslc/2024-NASCC-Consensus-Gene-Therapy-Recommendations.pdf?rlkey=0zlugtf3bqtdxesz5i9st2szg&st=mw345coi&dl=0"
                className="link-text"
              >
                Transformative SCD Center Recommendations (pdf)
              </Link>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};
