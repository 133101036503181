/** @module Components */
import React from "react";
import { Field } from "formik";

export const CenterMembershipAgreementForm: React.FC = () => {
  return (
    <div className="bg-white shadow px-4 py-5 border border-gray-200 sm:rounded-lg sm:p-6 md:shadow-xl">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Center Membership Agreement and Signature
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please read the NASCC membership agreement carefully and type in
            your name to serve as a signature. Please ensure you are authorized
            to sign the application on behalf of your center.
          </p>
        </div>
        <fieldset
          className="mt-5 md:mt-0 md:col-span-2"
          id="center-components-fieldset"
        >
          <legend className="sr-only">
            Center Membership Agreement Section
          </legend>
          <div className="flex flex-col">
            <h4 className="font-bold text-gray-800">Membership Agreement</h4>
            <div className="text-gray-500">
              NASCC Center Members must agree to utilize the{" "}
              <a href="https://grndad.org/" className="link-text">
                GRNDaD (Globin Research Network for Data and Discovery)
              </a>{" "}
              as their center registry for purposes of quality improvement and
              quality assurance. Other registry efforts are also permissible and
              do not require NASCC permission. However, only those Center
              Members that fulfill the minimum dataset criteria (number of
              patients cared for; age; gender; genotype) will be eligible to
              receive data associated resources/funding from NASCC. Once funding
              is available, Center Members must meet the GRNDaD charter (or
              updated charter requirements) as follows:
              <ol className="list-decimal list-inside pl-6">
                <li>
                  Rate of entry of at least 75 patients in year one (1), or 25%
                  of your SCD population (whichever is lesser), with less than
                  5% missing data, with a goal of 50% population by year five
                  (5), again with less than 5% missing data, subject to annual
                  review
                </li>
                <li>
                  Obtain Reliant IRB approval through Johns Hopkins University
                </li>
                <li>
                  Obtain an annual 10/10 quality review (10 data elements on 10
                  randomly selected subjects), subject to annual review
                </li>
              </ol>
            </div>
            <div className="flex pt-6 items-end" id="grndad-initial">
              <Field
                type="checkbox"
                id="grndad-initial-field"
                aria-labelledby="grndad-initial-field-label"
                name="agreeToGrndad"
                className="focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300 rounded"
                required
              />
              <label
                id="grndad-initial-field-label"
                className="pl-4 text-gray-700 font-bold text-xl flex justify-end"
                htmlFor="grndad-initial-field"
              >
                By checking this box, you are agreeing to participate in GRNDaD
                data collection as described above.
              </label>
            </div>
            <div className="flex pt-6 items-end" id="nascc-bylaws">
              <Field
                type="checkbox"
                id="nascc-bylaws-field"
                aria-labelledby="nascc-bylaws-field-label"
                name="agreeToBylaws"
                className="focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300 rounded"
                required
              />
              <label
                id="nascc-bylaws-field-label"
                className="pl-4 text-gray-700 font-bold text-xl flex justify-end"
                htmlFor="nascc-bylaws-field"
              >
                <p>
                  By checking this box, you are agreeing to uphold the{" "}
                  <a
                    href="https://storage.googleapis.com/nascc-public-assets/org-files/NASCC_Bylaws.pdf"
                    className="link-text"
                  >
                    NASCC Mission Statement and Bylaws
                  </a>
                  .
                </p>
              </label>
            </div>
            <div className="flex flex-col pt-16" id="app-signature">
              <label
                className="text-xl font-bold text-gray-700"
                htmlFor="app-signature-field"
              >
                Please type your full name here to sign the application:
              </label>
              <Field
                type="text"
                id="app-signature-field"
                aria-labelledby="app-signature-field-label"
                name="signedBy"
                aria-describedby="app-signature-field-description"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
              <div
                id="app-signature-field-description"
                className="pt-2 text-gray-500 text-lg flex justify-end"
                aria-details="app-signature-field"
              >
                <p>
                  By typing your full name here, you are acknowledging that you
                  are signing this application to join NASCC on behalf of the
                  sickle cell disease treatment center listed above and you have
                  the legal authority to do so. Also, please note that we will
                  ask for copies of the policies you identified above at a later
                  date and this signature is an acknowledgement that those will
                  be provided.
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
