/**
 * This page contains information on how to start a Sickle Cell center in the US, along with
 * links to resources with more information.
 *
 * @module Components
 */
import React from "react";
import { Helmet } from "react-helmet-async";

import { Footer } from "../Footer/Footer";

export const StartCenterInfo = () => {
  return (
    <>
      <Helmet data-testid="helmet_start-center-info">
        <title>NASCC - Start a Center</title>
        <meta
          name="description"
          content="Information about how to start a sickle cell center in the US"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main" data-testid="main_start-center-info">
        <h3
          className="static-page-header"
          data-testid="page-header_start-center-info"
        >
          Start a Clinical Sickle Cell Disease Center
        </h3>
        <section
          className="static-page-section"
          data-testid="section_start-center-info"
        >
          <div
            className="prose-text"
            data-testid="div_0a7e7915-020e-41fc-94a5-102f47640e4b"
          >
            <p>
              The{" "}
              <a
                href="https://www.hematology.org/"
                className="hover:text-blue-800"
              >
                American Society of Hematology (ASH)
              </a>{" "}
              hosted a{" "}
              <a
                href="https://www.hematology.org/meetings/sickle-cell-disease-workshop"
                className="hover:text-blue-800"
              >
                Sickle Cell Disease Centers Workshop
              </a>{" "}
              where they introduced the components needed to start a clinical
              center focused on the needs of those living with sickle cell
              disease. The workshop included an introduction to the components
              of such centers, the process for developing a business plan and
              advocating to stakeholders, the ins and outs of operations, and
              approaches to measuring impact and improving the quality of care
              in these centers.
            </p>
            <p>
              In collaboration with{" "}
              <a
                href="https://www.francefoundation.com/"
                className="hover:text-blue-800"
              >
                The France Foundation
              </a>
              , ASH has developed a series of <strong>free</strong>{" "}
              <a
                href="https://academy.hematology.org/local/catalog/view/product.php?productid=1167"
                className="hover:text-blue-800"
              >
                15-minute modules
              </a>{" "}
              to address clinical practice topics related to Sickle Cell
              Disease. These modules are intended as tools to aid health care
              providers in their development toward becoming adult Sickle Cell
              Disease centers. Continuing Medical Education (CME) credit is
              available for these modules.
            </p>
            <div data-testid="div_3b080240-42ab-4427-8290-1022563da7d0">
              Topics include:
              <ul
                className="ml-6 font-bold"
                data-testid="div_ab6a8d67-3a5f-4c42-b188-98a20042e575"
              >
                <li>SCD Center Essentials: Models and Teams</li>
                <li>Managing Together - The Clinician as Change Agents</li>
                <li>Working with Community-based Organizations</li>
                <li>Bias and Sickle Cell Disease</li>
                <li>The Changing Therapeutic Landscape of SCD</li>
                <li>The Financial Case for SCD Centers</li>
                <li>Creating an Institutional Approach to Pain</li>
              </ul>
              <p>
                Creating a login to the ASH website is required to access the
                courses and is <strong>free</strong>.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_02beb907-dbb6-4c5d-9734-4d884a2dd8b0" />
    </>
  );
};
