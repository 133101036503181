/** @module Containers */
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { toTitleCase } from "../../shared/Functions";
import {
  CenterComponentsForm,
  CenterDocumentationForm,
  CenterInformationForm,
  CenterMembershipAgreementForm,
} from "../../components";
import { CenterApplicationFormType } from "../../types/applicationTypes";
import {
  useGetCenterApplicationTokenQuery,
  usePostCenterApplicationMutation,
} from "../../services/applicationService";
import { GeneralApiResponse } from "../../types";
import { ErrorToast, SuccessToast } from "../../components/Shared";

export const ApplicationForm: React.FC = () => {
  const { applicationType, applicationToken } = useParams();
  const navigate = useNavigate();

  const [postCenterApplication, isSuccess] = usePostCenterApplicationMutation();
  const { error, isError, isFetching } = useGetCenterApplicationTokenQuery(
    applicationToken || ""
  );

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="" />, {
      duration: 7000,
    });
  const toastSuccess = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="" />, {
      duration: 3000,
    });

  React.useEffect(() => {
    if (!isFetching && isError) {
      console.warn(error);
      if (error && "status" in error && error.status === 404) {
        toastError(
          "Invalid application token. Please contact support@sicklecellcenters.org"
        );
      } else if (error && "status" in error && error.status === 409) {
        toastError(
          "An application already exists that used this token. " +
            "Please contact support@sicklecellcenters.org for more information"
        );
      } else {
        toastError(
          "Something went wrong. Please contact support@sicklecellcenters.org"
        );
      }
      navigate("/");
    }
  });

  const formValidationSchema = Yup.object().shape({
    // Center Information
    centerName: Yup.string().required("Center name is required"),
    streetAddress: Yup.string().required("Street address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip is required"),
    directorFirstName: Yup.string().required("First name is required"),
    directorLastName: Yup.string().required("Last name is required"),
    directorEmail: Yup.string().required("Email is required"),
    coDirectorFirstName: Yup.string(),
    coDirectorLastName: Yup.string(),
    coDirectorEmail: Yup.string(),
    primaryCenterContactEmail: Yup.string().required(
      "Primary center contact email is required"
    ),
    centerType: Yup.string(),
    websiteAddress: Yup.string().required(
      "You must provide a website address for your center"
    ),
    // Center Components
    dedicatedClinicSpace: Yup.boolean(),
    indepClinicSpace: Yup.boolean(),
    dcsSharedWithCancerCenter: Yup.boolean(),
    dcsSharedWithOther: Yup.boolean(),
    dcsSharedWithOtherName: Yup.string(),
    infusionArea: Yup.boolean(),
    infAreaDedicatedDayHospital: Yup.boolean(),
    ifAreaSharedWithCancerCenter: Yup.boolean(),
    ifAreaSharedWithOther: Yup.boolean(),
    ifAreaSharedWithOtherName: Yup.string(),
    observationUnit: Yup.boolean(),
    // Center Documentation, Protocol, and Treatments
    writtenProcForSickleCellDisease: Yup.boolean(),
    emergencyDeptProtocolsForScd: Yup.boolean(),
    opioidPolicy: Yup.boolean(),
    infusionPolicy: Yup.boolean(),
    trackingQualityMetricsProtocol: Yup.boolean(),
    accessCrizanlizumabInfusions: Yup.boolean(),
    accessPrescribeVoxeletor: Yup.boolean(),
    accessPharmaClinicalTrials: Yup.boolean(),
    accessStemCellTransplant: Yup.boolean(),
    accessToCompoundHydroxyurea: Yup.boolean(),
    // Center Employee Makeup
    advancedPractice: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    caseManager: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    mentalHealth: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    numberOfSickleCellNurses: Yup.number(),
    otherStaff: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    pharmacist: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    physicalTherapist: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    primaryCarePhysician: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    sickleCellSpecialist: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    socialWorker: Yup.array().of(
      Yup.object({
        nameAndInfo: Yup.string(),
        employeeType: Yup.string(),
      })
    ),
    // Center Agreement and Signatures
    agreeToGrndad: Yup.boolean().required("You must agree to the GRNDaD rules"),
    agreeToBylaws: Yup.boolean().required("You must agree to the bylaws"),
    signedBy: Yup.string().required("Signature is required"),
    // Type Dependent Fields
    // Adult Center
    numberOfAdultSca: Yup.number(),
    numberOfAdultSc: Yup.number(),
    numberOfAdultOther: Yup.number(),
    recNewlyTransToAdultProtocol: Yup.boolean(),
    // Pediatric Center
    numberOfPediatricSca: Yup.number(),
    numberOfPediatricSc: Yup.number(),
    numberOfPediatricOther: Yup.number(),
    transToAdultProtocol: Yup.boolean(),
    trackingNewbornScreenProtocol: Yup.boolean(),
    pediatricHematology: Yup.boolean(),
  });

  const formInitialValues: CenterApplicationFormType = {
    // Center Information
    centerName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    directorFirstName: "",
    directorLastName: "",
    directorEmail: "",
    coDirectorFirstName: "",
    coDirectorLastName: "",
    coDirectorEmail: "",
    primaryCenterContactEmail: "",
    centerType: applicationType || "",
    applicationToken: applicationToken || "",
    websiteAddress: "",
    // Center Components
    dedicatedClinicSpace: false,
    indepClinicSpace: false,
    dcsSharedWithCancerCenter: false,
    dcsSharedWithOther: false,
    dcsSharedWithOtherName: "",
    infusionArea: false,
    infAreaDedicatedDayHospital: false,
    infAreaSharedWithCancerCenter: false,
    infAreaSharedWithOther: false,
    infAreaSharedWithOtherName: "",
    observationUnit: false,
    // Center Employee Makeup
    advancedPractice: [{ nameAndInfo: "", employeeType: "" }],
    caseManager: [{ nameAndInfo: "", employeeType: "" }],
    mentalHealth: [{ nameAndInfo: "", employeeType: "" }],
    numberOfSickleCellNurses: 0,
    otherStaff: [{ nameAndInfo: "", employeeType: "" }],
    pharmacist: [{ nameAndInfo: "", employeeType: "" }],
    physicalTherapist: [{ nameAndInfo: "", employeeType: "" }],
    primaryCarePhysician: [{ nameAndInfo: "", employeeType: "" }],
    sickleCellSpecialist: [{ nameAndInfo: "", employeeType: "" }],
    socialWorker: [{ nameAndInfo: "", employeeType: "" }],
    // Center Documentation, Protocol, and Treatments
    writtenProcForSickleCellDisease: false,
    emergencyDeptProtocolsForScd: false,
    opioidPolicy: false,
    infusionPolicy: false,
    trackingQualityMetricsProtocol: false,
    accessCrizanlizumabInfusions: false,
    accessPrescribeVoxeletor: false,
    accessPharmaClinicalTrials: false,
    accessStemCellTransplant: false,
    accessToCompoundHydroxyurea: false,
    // Center Agreement and Signatures
    agreeToGrndad: false,
    agreeToBylaws: false,
    signedBy: "",
    // Type Dependent Fields
    // Adult Center
    numberOfAdultSca: 0,
    numberOfAdultSc: 0,
    numberOfAdultOther: 0,
    recNewlyTransToAdultProtocol: false,
    // Pediatric Center
    numberOfPediatricSca: 0,
    numberOfPediatricSc: 0,
    numberOfPediatricOther: 0,
    transToAdultProtocol: false,
    trackingNewbornScreenProtocol: false,
    pediatricHematology: false,
  };

  return (
    <main className="static-page-main">
      <h2 className="static-page-header">
        {applicationType ? toTitleCase(applicationType.toString()) : ""} Sickle
        Cell Center Application
      </h2>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values: CenterApplicationFormType) => {
          postCenterApplication(values)
            .unwrap()
            .then((response: GeneralApiResponse) => {
              if (isSuccess) {
                toastSuccess(
                  "Your application has been successfully submitted to NASCC and will be reviewed as soon as possible. Thank you!"
                );
              } else {
                toastError(
                  "There was an issue submitting your form. Please reach out to support@sicklecellcenters.org for help."
                );
              }
            })
            .catch((error) => {
              if (error.status === 409) {
                toastError(
                  "You have already submitted an application using this application token. Please contact support@sicklecellcenters.org."
                );
              } else {
                toastError(
                  "There was an issue submitting your form. Please reach out to support@sicklecellcenters.org for help."
                );
              }
            });
        }}
      >
        {({ dirty, isValid }) => (
          <Form className="gap-y-10 flex flex-col" id="center-application-form">
            <section id="center-information-form">
              <CenterInformationForm />
            </section>
            <section id="center-component-form">
              <CenterComponentsForm />
            </section>
            <section id="center-documentation-form">
              <CenterDocumentationForm />
            </section>
            <section id="center-membership-form">
              <CenterMembershipAgreementForm />
            </section>
            <button
              type="submit"
              className="w-full ml-auto flex items-center justify-center px-4 py-3 border border-transparent text-base self-end justify-self-end
                    font-medium rounded-md text-white bg-indigo-500 xl:h-12 xl:w-3/12 hover:bg-indigo-400 focus:outline-none focus:ring-2
                    focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!(dirty && isValid)}
            >
              Submit Application For Review
            </button>
          </Form>
        )}
      </Formik>
    </main>
  );
};
