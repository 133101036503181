import { emptyApi } from "./emptyApi";
import { camelCaseResponse, providesList } from "../shared/Functions";
import {
  CenterApplicationEvent,
  CenterApplicationUpdateOutType,
  CombinedCenterDetailsType,
  GeneralApiResponse,
} from "../types";
import { CenterApplicationFormType } from "../types/applicationTypes";

export const applicationService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getCenterApplicationInfo: builder.query<CombinedCenterDetailsType, string>({
      query: (centerApplicationId) =>
        `/admin/center_application/${centerApplicationId}`,
      transformResponse: (response: any): CombinedCenterDetailsType => {
        console.log(response);
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) => [{ type: "CenterApplication", id }],
    }),
    getCenterApplicationsInfo: builder.query<
      CombinedCenterDetailsType[],
      string
    >({
      query: (status) => `/admin/centers?status=${status}`,
      transformResponse: (response: any): CombinedCenterDetailsType[] => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) =>
        providesList(results, "CenterApplication"),
    }),
    getCenterApplicationEvents: builder.query<CenterApplicationEvent[], string>(
      {
        query: (centerApplicationId) =>
          `/admin/center_application_events/${centerApplicationId}`,
        transformResponse: (response: any): CenterApplicationEvent[] => {
          return camelCaseResponse(response);
        },
        providesTags: (results, error, id) =>
          providesList(results, "CenterApplication"),
      }
    ),
    getCenterApplicationToken: builder.query<GeneralApiResponse, string>({
      query: (centerApplicationToken: string) => `/admin/check_token/${centerApplicationToken}`,
      transformResponse: (response: any) => { return camelCaseResponse(response); }
    }),
    patchCenterApplication: builder.mutation<
      CombinedCenterDetailsType,
      CenterApplicationUpdateOutType
    >({
      query: (centerApplicationUpdateOut) => ({
        url: `/admin/center_application/${centerApplicationUpdateOut.center_application_id}`,
        method: "PATCH",
        body: centerApplicationUpdateOut,
      }),
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
    }),
    postCenterApplication: builder.mutation<
      GeneralApiResponse,
      CenterApplicationFormType
    >({
      query: (formInfo) => ({
        url: "/admin/center_application",
        method: "POST",
        body: processCenterApplication(formInfo),
      }),
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
    }),
  }),
});

export const {
  useGetCenterApplicationInfoQuery,
  useGetCenterApplicationsInfoQuery,
  useGetCenterApplicationEventsQuery,
  useGetCenterApplicationTokenQuery,
  usePatchCenterApplicationMutation,
  usePostCenterApplicationMutation,
} = applicationService;

function processCenterApplication(values: CenterApplicationFormType) {
  return {
    // Center Information
    center_name: values.centerName,
    street_address: values.streetAddress,
    city: values.city,
    state: values.state,
    zip_code: values.zipCode,
    director_first_name: values.directorFirstName,
    director_last_name: values.directorLastName,
    director_email: values.directorEmail,
    co_director_first_name: values.coDirectorFirstName,
    co_director_last_name: values.coDirectorLastName,
    co_director_email: values.coDirectorEmail,
    primary_center_contact_email: values.primaryCenterContactEmail,
    website_address: values.websiteAddress,
    center_type: values.centerType,
    application_token: values.applicationToken,
    // Center Components
    //// Adult Specific
    number_of_adult_sca: values.numberOfAdultSca,
    number_of_adult_sc: values.numberOfAdultSc,
    number_of_adult_other: values.numberOfAdultOther,
    //// Pediatric Specific
    number_of_pediatric_sca: values.numberOfPediatricSca,
    number_of_pediatric_sc: values.numberOfPediatricSc,
    number_of_pediatric_other: values.numberOfPediatricOther,
    //// Dedicated Clinic Space
    dedicated_clinic_space: values.dedicatedClinicSpace,
    indep_clinic_space: values.indepClinicSpace,
    dcs_shared_with_cancer_center: values.dcsSharedWithCancerCenter,
    dcs_shared_with_other: values.dcsSharedWithOther,
    dcs_shared_with_other_name: values.dcsSharedWithOtherName,
    //// Infusion Area
    infusion_area: values.infusionArea,
    inf_area_dedicated_day_hospital: values.infAreaDedicatedDayHospital,
    inf_area_shared_with_cancer_center: values.infAreaSharedWithCancerCenter,
    inf_area_shared_with_other: values.infAreaSharedWithOther,
    inf_area_shared_with_other_name: values.infAreaSharedWithOtherName,
    //// Observation Unit
    observation_unit: values.observationUnit,
    // Center Employee Info
    employees: {
      advanced_practice: values.advancedPractice?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      case_manager: values.caseManager?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      mental_health: values.mentalHealth?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      number_of_sickle_cell_nurses: values.numberOfSickleCellNurses,
      pharmacist: values.pharmacist?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      physical_therapist: values.physicalTherapist?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      primary_care_physician: values.primaryCarePhysician?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      sickle_cell_specialist: values.sickleCellSpecialist?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      social_worker: values.socialWorker?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
      other_staff: values.otherStaff?.map((value) =>
        value.nameAndInfo !== ""
          ? {
              name_and_info: value.nameAndInfo,
              employee_type: value.employeeType,
            }
          : undefined
      ),
    },
    // Center Documentation Info
    written_proc_for_sickle_cell_disease:
      values.writtenProcForSickleCellDisease,
    emergency_dept_protocols_for_scd: values.emergencyDeptProtocolsForScd,
    opioid_policy: values.opioidPolicy,
    infusion_policy: values.infusionPolicy,
    tracking_quality_metrics_protocol: values.trackingQualityMetricsProtocol,
    access_crizanlizumab_infusions: values.accessCrizanlizumabInfusions,
    access_prescribe_voxeletor: values.accessPrescribeVoxeletor,
    access_pharma_clinical_trials: values.accessPharmaClinicalTrials,
    access_stem_cell_transplant: values.accessStemCellTransplant,
    access_to_compound_hydroxyurea: values.accessToCompoundHydroxyurea,
    //// Adult Specific
    rec_newly_trans_to_adult_protocol: values.recNewlyTransToAdultProtocol,
    //// Pediatric Specific
    trans_to_adult_protocol: values.transToAdultProtocol,
    tracking_newborn_screen_protocol: values.trackingNewbornScreenProtocol,
    pediatric_hematology: values.pediatricHematology,
    // Center Agreement and Signatures
    agree_to_grndad: values.agreeToGrndad,
    agree_to_bylaws: values.agreeToBylaws,
    signed_by: values.signedBy,
  };
}
