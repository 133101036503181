import { emptyApi } from "./emptyApi";
import { camelCaseResponse } from "../shared/Functions";
import { ApiSettingsType, PublicationType } from "../types";

export const publicationService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicationList: builder.query<PublicationType[], ApiSettingsType>({
      query: ({ offset = 0, limit = 100 }: ApiSettingsType) =>
        `/publications?offset=${offset}&limit=${limit}`,
      transformResponse: (response: any) => {
        return parseResponse(camelCaseResponse(response));
      },
    }),
  }),
});

export const { useGetPublicationListQuery } = publicationService;

function parseResponse(camelCasedResponse: any) {
  return camelCasedResponse.map((element: any) => {
    return {
      ...element,
      datePublished: element.datePublished.split("T")[0],
    };
  });
}
