/** @category Enums */
export enum JobTypeEnum {
  PatientCare = "PATIENT_CARE",
  Faculty = "FACULTY",
  Research = "RESEARCH",
  Academic = "ACADEMIC",
  Fellowship = "FELLOWSHIP",
  Other = "OTHER",
}

/** @category Types */
export type JobTypeWithDescriptionType = {
  title: JobTypeEnum;
  description: string;
  current: boolean;
};

/** @category Constants */
export const JobTypesWithDescription = [
  {
    title: JobTypeEnum.PatientCare,
    description: "Temp Description",
    current: false,
  },
  {
    title: JobTypeEnum.Faculty,
    description: "Temp Description",
    current: false,
  },
  {
    title: JobTypeEnum.Research,
    description: "Temp description",
    current: false,
  },
  {
    title: JobTypeEnum.Academic,
    description: "Temp description",
    current: false,
  },
  {
    title: JobTypeEnum.Fellowship,
    description: "Temp description",
    current: false,
  },
  { title: JobTypeEnum.Other, description: "Temp description", current: false },
];

/** @category Enums */
export enum JobSettingEnum {
  Clinical = "CLINICAL",
  Hospital = "HOSPITAL",
  MedicalSchool = "MEDICAL_SCHOOL",
  ResearchLab = "RESEARCH_LAB",
}

/** @category Types */
export type JobSettingWithDescriptionType = {
  title: JobSettingEnum;
  description: string;
  current: boolean;
};

/** @category Constants */
export const JobSettingsWithDescription = [
  {
    title: JobSettingEnum.Clinical,
    description: "Job is performed mostly in a clinic",
    current: false,
  },
  {
    title: JobSettingEnum.Hospital,
    description: "Job is performed mostly in a hospital",
    current: false,
  },
  {
    title: JobSettingEnum.MedicalSchool,
    description: "Job is performed in a medical school",
    current: false,
  },
  {
    title: JobSettingEnum.ResearchLab,
    description: "Job is research based and performed in a lab",
    current: false,
  },
];

export const CustomSelectStyles = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  control: (provided: any) => ({
    ...provided,
    fontSize: "0.875rem",
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#E3E3E3"
        : isFocused
        ? "#E3E3E3"
        : undefined,
      color: isSelected ? "#FB6A07" : undefined,
      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#F9FAFB" : undefined,
      },
    };
  },
};
