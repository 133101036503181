/** @module Containers */
import React from "react";
import { Helmet } from "react-helmet-async";

import { useGetMemberCentersQuery } from "../../services/memberCenterService";
import {
  CenterList,
  CustomGoogleMap,
  Footer,
  InputWithOverlappingLabel,
  // Search,
} from "../../components";
import { CenterType, MemberCenter } from "../../types";
import RedIcon from "../../assets/images/Red_Map_Icon.png";
import BlueIcon from "../../assets/images/Blue_Map_Icon.png";
import PurpleIcon from "../../assets/images/Purple_Map_Icon.png";

/** @category Prop Types */
export type MemberCentersPropType = {
  onlyAssociate: boolean;
};

export const MemberCenters: React.FC<MemberCentersPropType> = ({
  onlyAssociate = false,
}: MemberCentersPropType) => {
  const [filteredMemberCenters, setFilteredMemberCenters] = React.useState<
    MemberCenter[] | []
  >([]);
  const [displayCenterTypes, setDisplayCenterTypes] = React.useState<string[]>([
    "pediatric",
    "adult",
    "wholeLife",
  ]);
  // const [filterValue, setFilterValue] = React.useState("");
  const {
    data: memberCenters,
    isFetching,
    isError,
  } = useGetMemberCentersQuery();

  const parsedMemberCenters = () => {
    if (memberCenters) {
      if (onlyAssociate) {
        return memberCenters.filter((center) => center.associateCenter);
      }
      return memberCenters.filter((center) => center.associateCenter === false);
    }
    return [];
  };

  React.useEffect(() => {
    setFilteredMemberCenters(parsedMemberCenters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCenters, onlyAssociate]);

  React.useEffect(() => {
    const tempArray: MemberCenter[] = [];
    const copiedFilteredMemberCenters = parsedMemberCenters();
    if (displayCenterTypes.includes("adult") && copiedFilteredMemberCenters) {
      tempArray.push.apply(
        tempArray,
        copiedFilteredMemberCenters.filter((center) => {
          return center.centerType === CenterType.Adult.toLowerCase();
        }),
      );
    }
    if (
      displayCenterTypes.includes("pediatric") &&
      copiedFilteredMemberCenters
    ) {
      tempArray.push.apply(
        tempArray,
        copiedFilteredMemberCenters.filter((center) => {
          return center.centerType === CenterType.Pediatric.toLowerCase();
        }),
      );
    }
    if (
      displayCenterTypes.includes("wholeLife") &&
      copiedFilteredMemberCenters
    ) {
      tempArray.push.apply(
        tempArray,
        copiedFilteredMemberCenters.filter((center) => {
          return center.centerType === CenterType.WholeLife.toLowerCase();
        }),
      );
    }
    setFilteredMemberCenters(tempArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCenterTypes]);

  function searchMemberCenters(searchText: string) {
    const searchedMemberCenters = parsedMemberCenters().filter((center) => {
      return (
        center.centerName.toLowerCase().includes(searchText.toLowerCase()) ||
        center.city.toLowerCase().includes(searchText.toLowerCase()) ||
        center.state.toLowerCase().includes(searchText.toLowerCase()) ||
        center.streetAddress.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredMemberCenters(searchedMemberCenters);
  }

  return (
    <>
      <Helmet>
        <title>NASCC - Member Sites</title>
        <meta
          name="description"
          content="A map showing the different member sites of the National Alliance of Sickle Cell Centers."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">
          Get to Know Our {onlyAssociate ? "Associate " : ""}Member Centers
        </h3>
        {onlyAssociate && (
          <p className="pb-4">
            Associate Centers are NASCC members who are currently working to
            become full Member Centers of NASCC by growing their available
            resources and treatments. Associate Centers are led by sickle cell
            disease experts with excellent treatment capabilities - please check
            their website for a better idea of the capabilities of the center.
          </p>
        )}
        <section className="static-page-section">
          {!isFetching && !isError && memberCenters && filteredMemberCenters ? (
            <>
              <CustomGoogleMap memberCenters={filteredMemberCenters} />
              <div className="flex flex-col justify-between items-center space-y-2 text-xl pb-6">
                <InputWithOverlappingLabel
                  inputLabel="Search"
                  placeholderText="Search for a center..."
                  className="max-w-4xl w-2/3 h-12 my-4"
                  inputClassName="h-12"
                  inputOnChange={(e) => {
                    searchMemberCenters(e.target.value);
                  }}
                />
                <div className="flex flex-col lg:flex-row max-w-xs lg:max-w-3xl lg:min-w-max justify-end w-full">
                  <h4 className="text-2xl pb-2 pr-6 ml-4 flex flex-col justify-center">
                    Filter by center type:
                  </h4>
                  <button
                    className={`flex justify-start items-center bg-rose-300 rounded-lg shadow-lg p-2 hover:bg-rose-500 ${
                      displayCenterTypes.length === 1 &&
                      displayCenterTypes[0] === "adult"
                        ? "bg-rose-500"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        displayCenterTypes.length === 1 &&
                        displayCenterTypes[0] === "adult"
                      ) {
                        setDisplayCenterTypes([
                          "adult",
                          "pediatric",
                          "wholeLife",
                        ]);
                      } else {
                        setDisplayCenterTypes(["adult"]);
                      }
                    }}
                  >
                    <img
                      src={RedIcon}
                      alt="Two hands in white holding a classically shaped heart in white with a red background"
                      className="aspect-auto h-10 mr-1"
                    />{" "}
                    Adult Treatment Center
                  </button>
                  <button
                    className={`flex justify-start items-center bg-blue-300 rounded-lg shadow-lg p-2 mt-2 lg:mt-0 lg:ml-4 hover:bg-blue-500 ${
                      displayCenterTypes.length === 1 &&
                      displayCenterTypes[0] === "pediatric"
                        ? "bg-blue-500"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        displayCenterTypes.length === 1 &&
                        displayCenterTypes[0] === "pediatric"
                      ) {
                        setDisplayCenterTypes([
                          "adult",
                          "pediatric",
                          "wholeLife",
                        ]);
                      } else {
                        setDisplayCenterTypes(["pediatric"]);
                      }
                    }}
                  >
                    <img
                      src={BlueIcon}
                      alt="Two hands in white holding a classically shaped heart in white with a blue background"
                      className="aspect-auto h-10 mr-1"
                    />{" "}
                    Pediatric Treatment Center
                  </button>
                  <button
                    className={`flex justify-start items-center bg-purple-300 rounded-lg shadow-lg p-2 mt-2 lg:mt-0 lg:ml-4 hover:bg-purple-500 ${
                      displayCenterTypes.length === 1 &&
                      displayCenterTypes[0] === "wholeLife"
                        ? "bg-purple-500"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        displayCenterTypes.length === 1 &&
                        displayCenterTypes[0] === "wholeLife"
                      ) {
                        setDisplayCenterTypes([
                          "adult",
                          "pediatric",
                          "wholeLife",
                        ]);
                      } else {
                        setDisplayCenterTypes(["wholeLife"]);
                      }
                    }}
                  >
                    <img
                      src={PurpleIcon}
                      alt="Two hands in white holding a classically shaped heart in white with a purple background"
                      className="aspect-auto h-10 mr-1"
                    />
                    Whole Life Treatment Center
                  </button>
                </div>
              </div>

              <div className="px-8 pl-20 pt-10">
                {displayCenterTypes.length === 3 && (
                  <>
                    <h2 className="text-3xl font-extrabold tracking-tight mb-6 sm:text-4xl">
                      Treatment Centers
                    </h2>
                    {filteredMemberCenters &&
                      filteredMemberCenters.length === 0 &&
                      "No treatment centers found for this filter or type."}
                    <CenterList memberCenters={filteredMemberCenters} />
                  </>
                )}
                {displayCenterTypes.length !== 3 &&
                  displayCenterTypes.includes("adult") && (
                    <>
                      <h2 className="text-3xl font-extrabold tracking-tight mb-6 sm:text-4xl">
                        Adult Treatment Centers
                      </h2>
                      <CenterList
                        memberCenters={memberCenters.filter((center) => {
                          return (
                            center.centerType === CenterType.Adult.toLowerCase()
                          );
                        })}
                      />
                      <br />
                    </>
                  )}
                {displayCenterTypes.length !== 3 &&
                  displayCenterTypes.includes("pediatric") && (
                    <>
                      <h2 className="text-3xl font-extrabold tracking-tight mb-6 sm:text-4xl">
                        Pediatric Treatment Centers
                      </h2>
                      <CenterList
                        memberCenters={memberCenters.filter((center) => {
                          return (
                            center.centerType ===
                            CenterType.Pediatric.toLowerCase()
                          );
                        })}
                      />
                      <br />
                    </>
                  )}
                {displayCenterTypes.length !== 3 &&
                  displayCenterTypes.includes("wholeLife") && (
                    <>
                      <h2 className="text-3xl font-extrabold tracking-tight mb-6 sm:text-4xl">
                        Whole Life Treatment Centers
                      </h2>
                      <CenterList
                        memberCenters={memberCenters.filter((center) => {
                          return (
                            center.centerType ===
                            CenterType.WholeLife.toLowerCase()
                          );
                        })}
                      />
                    </>
                  )}
              </div>
            </>
          ) : isFetching ? (
            "Retrieving centers..."
          ) : (
            "Error retrieving centers. Please try refreshing the page."
          )}
        </section>
      </main>
      <Footer dataTestId="footer_c989db8f-ad1a-42e3-af01-4d5cab87c8db" />
    </>
  );
};
