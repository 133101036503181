/** @module Containers */
import React from "react";
import { Link } from "react-router-dom";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

import { useGetJobPostListQuery } from "../../services/jobPostService";
import { Search } from "../../components";
import { JobPostType } from "../../types";

export const JobList: React.FC = () => {
  const {
    data: jobPostings,
    isLoading,
    isFetching,
    isError,
  } = useGetJobPostListQuery({ offset: 0, limit: 100 });

  const [filterValue, setFilterValue] = React.useState("");
  const [filteredPosts, setFilteredPosts] = React.useState<JobPostType[]>(
    jobPostings || [],
  );

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  React.useEffect(() => {
    if (jobPostings) {
      setFilteredPosts(jobPostings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  React.useEffect(() => {
    if (jobPostings) {
      const newFilteredPosts = jobPostings?.filter((post) => {
        return Object.values(post).some((postValue) => {
          if (postValue instanceof Array) {
            postValue.forEach((postValueObject) => {
              Object.values(postValueObject).some((innerPostValue: any) => {
                if (
                  innerPostValue
                    .toString()
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())
                ) {
                  return post;
                }
                return null;
              });
            });
          } else if (
            postValue !== null &&
            postValue
              .toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          ) {
            return post;
          }
          return null;
        });
      });
      setFilteredPosts(newFilteredPosts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  console.log(jobPostings);
  return (
    <>
      <Helmet>
        <title>NASCC - Job List</title>
        <meta
          name="description"
          content="Job postings from members and non-members of the National Alliance of Sickle Cell Centers."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">Current Job Listings</h3>
        <section className="static-page-section flex flex-col">
          <div className="self-begin w-full mb-2 lg:w-1/2 xl:w-1/3">
            <Search
              label="Search job postings"
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </div>
          <div className="border-b-2 w-full mb-2 lg:mb-4" />
          <ul className="grid grid-cols-1 gap-6 min-h-full sm:grid-cols-2 lg:grid-cols-3">
            {!(isFetching && isLoading && isError) &&
              jobPostings &&
              filteredPosts.map((jobPost) => (
                <li
                  key={jobPost.title}
                  className="col-span-1 grid grid-rows-6 text-center bg-white rounded-lg shadow divide-y divide-gray-200 h-[25rem] max-h-full"
                >
                  <div className="px-6 row-span-5 grid grid-rows-10 pb-2 max-h-full">
                    {jobPost.logoUrl ? (
                      <img
                        className="w-28 h-28 mx-auto rounded-full row-span-2 hidden md:flex md:flex-shrink-0 md:row-span-4"
                        src={jobPost.logoUrl}
                        alt=""
                      />
                    ) : null}

                    <h4 className="mt-2 text-gray-900 text-base font-medium row-span-1 md:text-xl">
                      {jobPost.title}
                    </h4>
                    <dl className="justify-between row-span-5 grid grid-rows-5 max-h-full">
                      <dt className="sr-only">Company name and location</dt>
                      <dd className="text-gray-500 text-base row-span-1">
                        {jobPost.locationCity}, {jobPost.locationState}
                      </dd>
                      <dt className="sr-only">Short job description</dt>
                      <dd className="whitespace-normal row-span-3 overflow-hidden">
                        {jobPost.shortDescription}...
                      </dd>
                      <dt className="sr-only">Tags and Posted Date</dt>
                      <dd className="mt-3 space-x-0.5 row-span-1">
                        {jobPost.tags.map((tag) => {
                          return (
                            <span
                              className={classNames(
                                `bg-${tag.color}-600`,
                                "px-2 py-1 text-white text-xs font-medium bg- rounded-full",
                              )}
                              key={tag.name}
                            >
                              {tag.name}
                            </span>
                          );
                        })}
                      </dd>
                    </dl>
                  </div>
                  <div className="row-span-1">
                    <div className="flex">
                      <div className="w-0 flex-1 flex">
                        <Link
                          to={`/job/${jobPost.id}`}
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                          <ClipboardDocumentListIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-3">View Listing</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            {!(isFetching && isLoading && isError) &&
              jobPostings &&
              jobPostings.length === 0 && (
                <h3 className="static-page-header">No job posting founds...</h3>
              )}
          </ul>
        </section>
      </main>
    </>
  );
};
