/** @module Components */
import React from "react";
import { Link } from "react-router-dom";

import { Footer } from "../Footer/Footer";
import { SubscriberForm } from "../SubscriberForm/SubscriberForm";

export const Join: React.FC = () => {
  return (
    <>
      <main className="static-page-main">
        <h2 className="static-page-header">Stay Up to Date by Joining NASCC</h2>
        <section className="static-page-section">
          <h4 className="text-2xl font-bold">
            If you represent a <strong>Sickle Cell Disease CENTER</strong>,
            please click through to{" "}
            <Link to="/center_join" className="link-text">
              our center specific form to request an application
            </Link>
            .
          </h4>
        </section>
        <section>
          <div className="max-w-7xl mx-auto px-4 py-4 min-h-two-third-screen sm:px-6 lg:py-8 lg:px-8">
            <div className="py-4 px-2 bg-indigo-700 rounded-3xl h-11/12 justify-center sm:py-8 sm:px-6 lg:px-8 lg:py-20 lg:flex lg:items-center">
              <div className="w-full flex-col lg:w-5/12">
                <h2 className="text-3xl font-extrabold tracking-tight text-white">
                  Stay in the know - sign up for our newsletter!
                </h2>
                <p className="mt-4 max-w-3xl text-lg text-indigo-100">
                  Sign up for our newsletter and get up to date information
                  about patient treatments, treatment techniques, cutting edge
                  research, and more!
                </p>
              </div>
              <SubscriberForm centerApplicationRequest={false} />
            </div>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_7f3e8040-65ef-4eb6-b8a3-0939fcca6325" />
    </>
  );
};
