import React from "react";
import { Link } from "react-router-dom";

export const GrndadAbout: React.FC = () => {
  return (
    <section className="static-page-section" id="about">
      <h3 className="static-page-header">About GRNDaD</h3>
      <section className="relative bg-white p-2 sm:p-4 md:p-6">
        <p>
          Sickle Cell Disease (SCD) is a genetic anemia with debilitating and
          life-threatening sequelae. It affects 100,000 Americans, and millions
          worldwide. Research-driven improvements in pediatric care have
          successfully lengthened life expectancy in SCD over the last 5
          decades, especially in resource rich settings. Nonetheless, the
          clinical impact of the underlying pathophysiology has not been fully
          mitigated. The burden of disease in the emerging contemporary
          population, especially young adults with SCD who now survive
          childhood, is changing in significant ways that are largely
          unexplored.
        </p>
        <br />
        <p>
          GRNDaD is a multisite registry developed by internationally recognized
          physicians who take care of both children and adults with sickle cell
          disease. The goal is to collect longitudinal data on a cohort of
          people living with sickle cell disease to better understand how
          clinical characteristics predict outcomes. GRNDaD also collects
          patient reported outcomes including surveys on health related quality
          of life and information on pain and fatigue. Another major goal of
          GRNDaD is to use the data collected for quality improvement and assess
          how sites are doing adhering to guideline recommendations. We then
          will use this information to develop appropriate interventions to
          improve adherence to recommendations which will lead to improved
          outcomes for this population.
        </p>
        <br />
        <p>
          If you are a clinic that takes care of people living with sickle cell
          disease and would like to inquire about joining as a GRNDaD site
          please email us at{" "}
          <Link to="mailto:GRNDaDSCD@gmail.com" className="link-text">
            GRNDaDSCD@gmail.com
          </Link>
          .
        </p>
      </section>
    </section>
  );
};
