/**
 * This page contains information on what is needed to be a NASCC Sickle Cell center. Essentially,
 * what's required to be a member of the NASCC.
 *
 * @module Components
 */
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Footer } from "../Footer/Footer";

export const CenterCriteria: React.FC = () => {
  return (
    <>
      <Helmet data-testid="helmet_center-criteria">
        <title>NASCC - Center Membership Criteria</title>
        <meta
          name="description"
          content="Information on what is required of a Sickle Cell center to become a member of NASCC."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main" data-testid="main_center-criteria">
        <h3
          className="static-page-header"
          data-testid="page-header_center-criteria"
        >
          NASCC Membership Criteria - What Your Center Needs to Join
        </h3>
        <section
          className="static-page-section"
          data-testid="section_center-criteria"
        >
          <div
            className="prose-text"
            data-testid="div_ef4d0ccd-5a45-438c-96d4-6e44d147755f"
          >
            <p>
              There are a few requirements needed to become a member of the
              NASCC. We currently have three different types of centers that we
              accept as members; those who treat adults, those who treat
              children (pediatrics), and those who treat both. Depending on what
              category you fall into, the requirements to be accepted are
              slightly different. Below, we lay out the requirements for each
              type of center. As long you meet most of these criteria, we
              encourage you to{" "}
              <Link
                to={`/center_join`}
                className="link-text"
                data-testid="link_center-join"
              >
                apply
              </Link>
              ! You can also reach out to{" "}
              <a
                href="mailto:application.support@sicklecellcenters.org"
                className="link-text"
                data-testid="link_application-support-email"
              >
                application.support@sicklecellcenters.org.
              </a>
            </p>
          </div>
        </section>
        <h3
          className="static-page-header"
          data-testid="page-header_adult-center-criteria"
        >
          Criteria for an Adult Center
        </h3>
        <section
          className="static-page-section"
          data-testid="section_adult-center-criteria"
        >
          <div
            className="prose-text"
            data-testid="div_1e561dac-ceba-4adf-838f-7958f645e480"
          >
            <p>
              The elements needed in a Sickle Cell center that treats adults to
              become a member of NASCC are as follows:
            </p>
            <div
              data-testid="div_3b080240-42ab-4427-8290-1022563da7d0"
              className="font-bold"
            >
              Essential personnel requirements:
              <ul
                className="ml-6 font-normal"
                data-testid="div_3d7b4d04-f2ac-4c7f-ab83-76b7d3cfd762"
              >
                <li>Sickle Cell Specialist (physician)</li>
                <li>
                  Case Manager/Patient Navigator (or someone who fills this
                  role)
                </li>
                <li>Social Worker(s)</li>
                <li>
                  Educated Nursing Staff (for managing Sickle Cell disease)
                </li>
                <li>
                  Advanced Practice Providers (depending on size - not a
                  requirement for centers)
                </li>
              </ul>
            </div>
            <div
              data-testid="div_6ac4d230-ea96-41ed-bab9-8ad9cc311430"
              className="font-bold"
            >
              Essential protocols and treatments available:
              <ul
                className="ml-6 font-normal"
                data-testid="div_86b0d66f-60c3-4317-b8ff-ed9da71ca910"
              >
                <li>Written procedures for acute/chronic management</li>
                <li>Written procedures for pain management</li>
                <li>Erythrocytopheresis for acute treatment</li>
                <li>Erythrocytopheresis for chronic treatment</li>
                <li>Timely access to transfusion medicine</li>
                <li>
                  Timely access to specialist services including family planning
                </li>
                <li>
                  Plan for mental health treatment if there is not a dedicated
                  provider
                </li>
                <li>Access to all approved SCD-modifying therapies</li>
              </ul>
            </div>
            <div
              data-testid="div_91d6713d-9cf8-4256-a13b-68c092274525"
              className="font-bold"
            >
              Optimal elements:
              <ul
                className="ml-6 font-normal"
                data-testid="div_01faec4b-675a-4c01-a912-a3bd34560b62"
              >
                <li>Lead Nurse/Clinic Manager</li>
                <li>Infusion Center/Day Hospital</li>
                <li>GYN/Contraception Access</li>
                <li>Transition Plan and Guidelines</li>
                <li>Mental Health Staff</li>
              </ul>
            </div>
          </div>
        </section>
        <h3
          className="static-page-header"
          data-testid="page-header_pediatric-center-criteria"
        >
          Criteria for a Pediatric Center
        </h3>
        <section
          className="static-page-section"
          data-testid="section_center-criteria"
        >
          <div
            className="prose-text"
            data-testid="div_cac1a73a-2aaa-47a7-a967-0ffc017e754e"
          >
            <p>
              The elements needed to become a member of NASCC for a Sickle Cell
              center that treats <strong>children</strong> are as follows:
            </p>
            <div
              data-testid="div_9a4775dd-ae93-4160-bbe3-ce6b11ac610c"
              className="font-bold"
            >
              Essential personnel requirements:
              <ul
                className="ml-6 font-normal"
                data-testid="div_c8072c5e-c61b-4f7b-ad29-6001d57d1c46"
              >
                <li>Sickle Cell Specialist (physician)</li>
                <li>
                  Case Manager/Patient Navigator (or someone who fills this
                  role)
                </li>
                <li>Social Worker(s)</li>
                <li>Pediatric Hematology Team Managing Inpatient Care</li>
                <li>Outpatient Nursing Staff with SCD Expertise</li>
              </ul>
            </div>
            <div
              data-testid="div_67ce7d4e-05df-4d20-a9ea-1b3ad79ae93d"
              className="font-bold"
            >
              Essential protocols and treatments available:
              <ul
                className="ml-6 font-normal"
                data-testid="div_6edede93-b4b4-4433-acaa-d470c6791965"
              >
                <li>Written procedures for acute/chronic management</li>
                <li>Protocols for newborn screening follow-up</li>
                <li>Protocols for Emergency Department care</li>
                <li>Defined transition plan from pediatric to adult care</li>
                <li>Standardized order sets in EMR for pain admission</li>
                <li>Formal process for patients/family input</li>
                <li>Erythrocytopheresis for acute and chronic treatments</li>
              </ul>
            </div>
            <div
              data-testid="div_8a0276e5-53e2-4937-9f6d-11c82e479c5d"
              className="font-bold"
            >
              Essential diagnostic and treatment requirements:
              <ul
                className="ml-6 font-normal"
                data-testid="div_22d98865-6ccf-4b43-8a5f-e2286ac69cc7"
              >
                <li>On-site transcranial Doppler ultrasonography</li>
                <li>Iron overload measurement by MRI</li>
                <li>Compound hydroxyurea</li>
                <li>Annual individualized care plan</li>
              </ul>
            </div>
            <div
              data-testid="div_d252c37b-b38d-48f0-b352-3652e0e73a98"
              className="font-bold"
            >
              Required Links and Partnerships:
              <ul
                className="ml-6 font-normal"
                data-testid="div_38bc040b-7cf7-462a-b67f-4344e91a7369"
              >
                <li>
                  Timely access to specialist services including family planning
                </li>
                <li>Mental health provider</li>
                <li>Partnership with HSCT team</li>
                <li>Partnership with adult SCD program</li>
                <li>Transfusion medicine specialist</li>
                <li>Neuropsychologist</li>
              </ul>
            </div>
          </div>
        </section>
        <h3
          className="static-page-header"
          data-testid="page-header_whole-life-center-criteria"
        >
          Criteria for a Whole Life Center
        </h3>
        <section
          className="static-page-section"
          data-testid="section_center-criteria"
        >
          <div
            className="prose-text"
            data-testid="div_abc119cf-fc3a-49ed-a48c-97c85996e151"
          >
            <p>
              As a whole life treatment center is treating patients from
              childhood to adulthood, NASCC requires that they have a
              combination of the above resources. We encourage you to apply
              reach out to{" "}
              <a
                href="mailto:application.support@sicklecellcenters.org"
                className="link-text"
              >
                application.support@sicklecellcenters.org
              </a>{" "}
              for more information, if needed.
            </p>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_1bb5daae-3dfe-4bd8-8a69-bc69d7a0814b" />
    </>
  );
};
