/** @module Components */

import React from "react";
import {
  PresentationChartLineIcon,
  GlobeAltIcon,
  BoltIcon,
  ScaleIcon,
} from "@heroicons/react/24/outline";

export const Goals: React.FC = () => {
  return (
    <section className="pt-8 pb-24 bg-white">
      <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <h2 className="text-2xl text-indigo-600 font-extrabold tracking-wide uppercase">
            Our Goals
          </h2>
          <p className="mt-4 max-w-4xl text-xl text-gray-500 lg:mx-auto">
            We are committed to providing support, education, and other
            resources to sickle cell centers across America in order to enhance
            the quality of patient care and improve outcomes for patients and
            the centers alike.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <GlobeAltIcon className="h-8 w-8" />
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Infrastructure
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We are committed to creating an infrastructure for adult and
                  pediatric sickle cell disease centers to define, enhance, and
                  promote the adoption of standards of primary and specialized
                  care.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <ScaleIcon className="h-8 w-8" />
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Quality Improvement
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Work to enable access to the{" "}
                  <a
                    href="https://grndad.org/"
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    Globin Research Network for Data and Discovery
                  </a>
                  , a multi-site registry to optimize quality improviement and
                  quality assurance through data analytics.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <BoltIcon className="h-8 w-8" />
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Collaboration
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Work with the{" "}
                  <a
                    href="https://www.hematology.org/"
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    American Society of Hematology
                  </a>
                  , the{" "}
                  <a
                    href="http://www.sicklecelldisease.org/"
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    Sickle Cell Disease Association of America
                  </a>
                  , and the{" "}
                  <a
                    href="https://www.cdc.gov/ncbddd/hemoglobinopathies/scdc.html"
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    CDC's Sickle Cell Data Collection
                  </a>{" "}
                  program along with other organizations to collaborate on
                  research, data collection, advocacy and other efforts.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <PresentationChartLineIcon className="h-8 w-8" />
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Tools and Information
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Develop tools and share information with sickle cell disease
                  centers to use in implementing and operating a comprehensive
                  care model within their organization.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};
