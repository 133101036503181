/** @module Containers */
import React, { useEffect, useState } from "react";
import { ColumnWithStrictAccessor } from "react-table";

import { Table } from "../../components";
import { SharedSubscriberListColumns } from "../../components/Shared/Columns";
import { useGetAllSubscribersQuery } from "../../services/subscriberService";
import { useAppContext } from "../../context";
import { Modal } from "../../components/Shared";
import { EmailListForm } from "../../components/EmailListForm/EmailListForm";

export const SubscriberList: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );
  const { displayModal, setDisplayModal } = useAppContext();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [clickedSubscriberId, setClickedSubscriberId] = useState(0);

  const {
    data: subscriberList,
    isLoading: subscriberListLoading,
    isFetching: subscriberListFetching,
    isError: subscriberListError,
  } = useGetAllSubscribersQuery();

  let memoizedSharedSubscriberListColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedSubscriberListColumns, []);

  const handleAddToEmailList = (subscriberName: string) => {
    setModalTitle(`Add ${subscriberName} to Multiple Email Lists`);
    setModalBody("Add this subscriber to multiple email lists.");
    setDisplayModal(true);
  };

  useEffect(() => {
    memoizedSharedSubscriberListColumns.push({
      Header: "Actions",
      accessor: "",
      disableSortBy: true,
      Cell: function actionCell(element: any) {
        return (
          <>
            <button
              className="inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md
              shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap disabled-styling"
              onClick={() => {
                setClickedSubscriberId(element.row.original.id);
                return handleAddToEmailList(
                  element.row.original.firstName +
                    " " +
                    element.row.original.lastName
                );
              }}
            >
              Add to Email List
            </button>
          </>
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      {displayModal && (
        <Modal
          modalBody={modalBody}
          modalTitle={modalTitle}
          displayRightButton={false}
          displayLeftButton={false}
          modalComponent={<EmailListForm subscriberId={clickedSubscriberId} />}
        />
      )}
      <h3 className="static-page-header mx-8">Subscribers List</h3>
      <div className="flex flex-col">
        {!subscriberListLoading &&
          !subscriberListFetching &&
          !subscriberListError &&
          subscriberList && (
            <Table
              columns={memoizedSharedSubscriberListColumns}
              data={subscriberList}
              selectedPageSize={selectedPageSize}
              setSelectedPageSize={setSelectedPageSize}
              fetching={subscriberListFetching}
              headerCellClassName="pl-4"
              customRowCellClassName="pl-4"
            />
          )}
      </div>
    </section>
  );
};
