import React from "react";
import { useParams } from "react-router-dom";

import { useGetJobPostListQuery } from "../../services/jobPostService";
import { JobPostType } from "../../types";
import { format, parse } from "date-fns";
import MDEditor from "@uiw/react-md-editor";

export const JobPost: React.FC = () => {
  const { jobId } = useParams();
  const { jobPost } = useGetJobPostListQuery(
    { offset: 0, limit: 100 },
    {
      selectFromResult: ({ data }) => ({
        jobPost: data?.find(
          (jobPost: JobPostType) => jobPost.id.toString() === jobId
        ),
      }),
    }
  );

  return (
    <main className="static-page-main">
      <section
        className="static-page-section flex flex-col"
        aria-label="Single job post section"
      >
        <div className="flex flex-col">
          <div className="space-y-6">
            {jobPost && (
              <div className="shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="job-post-title-header"
                    className="text-lg leading-6 font-medium text-gray-900 lg:text-3xl xl:text-4xl"
                    aria-label="Job post title in the header"
                  >
                    {jobPost.title}
                  </h2>
                  <p
                    className="mt-1 max-w-2xl text-base text-gray-500 lg:text-xl xl:text-2xl"
                    id="job-post-location-header"
                    aria-label="Job post location in the header"
                  >
                    {jobPost.locationCity}, {jobPost.locationState}
                  </p>
                  <p
                    className="mt-1 max-w-2xl text-base text-gray-500 lg:text-xl xl:text-2xl"
                    id="job-post-location-header"
                    aria-label="Job post location in the header"
                  >
                    Posted On:{" "}
                    {format(
                      parse(
                        jobPost.postedAt.toString().replace("T", " "),
                        "yyyy-MM-dd HH:mm:ss",
                        new Date()
                      ),
                      "MMMM do, yyyy"
                    )}
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Title
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {jobPost.title}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Location
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {jobPost.locationCity}, {jobPost.locationState}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Application Period Ends On
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {format(
                          parse(
                            jobPost.applicationPeriodEndsAt
                              .toString()
                              .replace("T", " "),
                            "yyyy-MM-dd HH:mm:ss",
                            new Date()
                          ),
                          "MMMM do, yyyy"
                        )}{" "}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
                    <div className="col-span-1">
                      <dt className="text-base font-medium text-gray-500 pb-6 lg:text-xl xl:text-2xl">
                        Job Description:
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        <MDEditor.Markdown source={jobPost.description} />
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Contact Name
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {jobPost.contactName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Contact Email Address
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {jobPost.contactEmail}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">
                        Contact Phone Number
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">
                        {jobPost.contactPhone
                          ? jobPost.contactPhone
                          : "None provided."}
                      </dd>
                    </div>
                    {/*<div className="sm:col-span-1">*/}
                    {/*  <dt className="text-base font-medium text-gray-500 lg:text-xl xl:text-2xl">*/}
                    {/*    Contact Address*/}
                    {/*  </dt>*/}
                    {/*  <dd className="mt-1 text-base text-gray-900 lg:text-xl xl:text-2xl">*/}
                    {/*    {jobPost.contactAddress*/}
                    {/*      ? jobPost.contactAddress*/}
                    {/*      : "None provided."}*/}
                    {/*  </dd>*/}
                    {/*</div>*/}
                  </dl>
                </div>
                <div>
                  <a
                    href={`mailto:${jobPost.contactEmail}`}
                    className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                  >
                    Email Job Contact
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
