/** @modules Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { ConsensusDetailsColumns } from "../../components/Shared/Columns";
import { Table } from "../../components";
import { ConsensusRecommendationsType } from "../ConsensusResources/ConsensusRecommendations";

export const ConsensusRecommendationPediatric: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState("1000");
  const tableData: ConsensusRecommendationsType[] = [
    {
      title: "Routine Health Maintenance",
      text:
        "All children with sickle cell disease (SCD) should be seen annually for a comprehensive sickle cell " +
        "disease wellness visit. Children identified with severe disease phenotype or on disease modifying therapy " +
        "should be seen at least every 3 months for follow-up assessments. Those children with mild disease " +
        "phenotype who are not having frequent acute visits should be seen for follow-up at least every 6 months",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Routine Health Maintenance",
      text:
        "We recommend genetic counseling for parents about inheritance and risk of future offspring " +
        "with SCD.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Composition of Annual Comprehensive SCD Wellness Visit",
      text:
        "All children with SCD should have a full history and physical that includes measurement of all " +
        "vital signs including SaO2, screening for obesity, screening for growth failure and screening for " +
        "physical activity and sports participation. Examination should include all organ systems (eye " +
        "exam, skin exam, cardiopulmonary assessment, Gastrointestinal (GI) including palpation for " +
        "spleen/liver, focused neurologic exam, gait assessment and a musculoskeletal exam including a " +
        "range of motion (ROM) assessment with focus on hips, shoulders, and knees). A full review of " +
        "systems should also be done including symptom screening as noted by organ system below and " +
        "including mood, school performance, and attention.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Composition of Annual Comprehensive SCD Wellness Visit",
      text:
        "Follow-up should occur by phone, telemedicine, or in-person to communicate the confirmatory " +
        "testing results when those results are available.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurological",
      text:
        "All children with sickle cell anemia (HbSS/HbSB0) should undergo an annual transcranial " +
        "Doppler ultrasound (TCD/TCDi) also known as a sickle stroke screen between the ages of 2-16 " +
        "years.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurological",
      text:
        "All children with SCD of all genotypes should undergo routine developmental screening, and " +
        "referral for neurocognitive testing for any developmental screen failures or delays.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurological",
      text:
        "All children with sickle cell anemia (HbSS/HbSB0) should undergo at least one screening " +
        "MRI/MRA brain with perfusion imaging in early childhood (star>ng age 5) to assess for silent " +
        "cerebral infarct when MRI can be performed without seda>on (ASH guidelines).",
      typeOfRecommendation: "Standard",
    },

    {
      title: "Neurological",
      text:
        "All patients with HbSS/ HbSβ0 " +
        ", 12-18 years of age, should undergo neurocognitive testing at " +
        "least once. ",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Neurological",
      text:
        "Repeat neurocognitive testing and MRI/MRA brain with perfusion imaging in patients > 10 years " +
        "of age with change in school performance.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Eye",
      text:
        "All children with SCD should undergo a dilated eye exam to screen for retinopathy starting at " +
        "age 10 years and continue annually",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Eye",
      text:
        "All children should have an evaluation performed by an ophthalmologist if available. If " +
        "ophthalmology not available, minimum optometrist evaluation.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "ENT/Dental",
      text:
        "All children should undergo routine dental screening every 6 months (as supported by the AAP). " +
        "As SCD can affect dentition, dental coverage should be included in medical coverage for children " +
        "with SCD in all payer systems.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT/Dental",
      text:
        "Children with SCD (all genotypes) DO NOT require antibiotic prophylaxis for dental screening or " +
        "treatment. ",
      typeOfRecommendation: "Standard",
    },

    {
      title: "ENT/Dental",
      text:
        "Caution should be used when considering general anesthesia for children with SCD and should " +
        "be discussed with the child’s hematologist prior to proceeding. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT/Dental",
      text:
        "Outpatient procedural sedation with duration less than 30 minutes is preferred in children with " +
        "SCD over prolonged sedation or general anesthesia to reduce the risk of pulmonary " +
        "complications. ",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "ENT/Dental",
      text:
        "All children with SCD receiving iron chelation treatment and/or those children with SCD " +
        "identified with receptive/expressive language delays should undergo an annual audiogram",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Pulmonary",
      text:
        "All children with SCD should be screened for hypoxia at baseline and should undergo a " +
        "symptom screen for obstructive sleep apnea (AAP) and for asthma (AAP).",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Pulmonary",
      text:
        "Screening with pulmonary function testing (PFT) is NOT recommended. However, providers " +
        "should consider performing PFTs for children with oxygen saturation < 95% at rest, history of " +
        "acute chest syndrome (ACS), wheezing, nighcme cough, syncope, dyspnea, history of " +
        "Standard " +
        "pulmonary embolism, or history of recurrent vaso-occlusive episodes (VOE). (in line with ASH " +
        "guidelines)",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Pulmonary",
      text:
        "Consideration should be given to establishing multi-disciplinary clinic with Hematology and " +
        "Pulmonology specialists.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Cardiac",
      text:
        "All children should undergo symptom screening for cardiovascular abnormalities and physical " +
        "exam abnormalities including blood pressure assessments and cardiac exams. Routine " +
        "echocardiogram is NOT recommended but should be considered in anyone with oxygen " +
        "saturations <95% at rest, exercise intolerance, hypertension, history of recurrent acute chest " +
        "syndrome or other symptom-specific assessment. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Cardiac",
      text:
        "If children are being initiated on a medication that is known to prolong the QTc, consider " +
        "baseline electrocardiogram (ECG) with annual follow-up.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Gastro-Intestinal (GI)",
      text:
        "All children with SCD should be screened for symptoms of gallstones, abdominal pain, " +
        "constipation, and PICA. If symptoms are noted concerning for gallstones, patients should be " +
        "referred for abdominal ultrasound. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Gastro-Intestinal (GI)",
      text:
        "All children with SCD should undergo a spleen exam during their routine assessment and " +
        "parents and caregivers should also be taught spleen measurement techniques. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Gastro-Intestinal (GI)",
      text: "All children with SCD should be screened for adequate growth and nutrition.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Renal",
      text:
        "All children with SCD should have symptom screening for enuresis with review of evidence on " +
        "when to refer including the presence of daytime enuresis.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Renal",
      text:
        "All children with SCD should have a symptom screen for hematuria to identify possible papillary " +
        "necrosis ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Renal",
      text:
        "All children with SCD should have an annual urinalysis and should be screened with a random " +
        "urine albumin and creatinine by at age 10. Optimally, urine albumin and creatinine should be a " +
        "first morning or early morning void when feasible. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Renal",
      text:
        "Referral to Nephrology is recommended in this age group to look for concomitant factors " +
        "leading to kidney disease (different from adults).",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Urology and Gynecology",
      text:
        "All children with SCD at risk of having a priapism (anatomic male) should be screened for a " +
        "history of priapism.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Urology and Gynecology",
      text:
        "All children with SCD should be screened for puberty onset, progression, and pubertal delays " +
        "including screening for menarche in anatomic females.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Urology and Gynecology",
      text:
        "All children (all genders) with SCD should receive reproductive and contraception counseling " +
        "starting at 12-14.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Musculoskeletal",
      text:
        "All children with SCD should have a symptoms screen for sites of recurrent pain, paying " +
        "particular aRention to shoulders, hips, knees, and back. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Musculoskeletal",
      text:
        "Consideration should be given for physical therapy referral if there are sites of recurrent or " +
        "chronic pain. Children with SCD who have a diagnosis of avascular necrosis should also be " +
        "referred to orthopedics.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Psychosocial Evaluations",
      text:
        "All children with SCD should undergo mental health screening for anxiety and depression " +
        "annually (and more ogen if needed). A plan should be in place at all SCD centers for referral for " +
        "psychiatric treatment if needed (i.e. workflow for a positive screen). " +
        "Tools to use: GAD, PHQ, SCARED",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Psychosocial Evaluations",
      text: "All children should undergo a yearly QOL assessment.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Psychosocial Evaluations",
      text:
        "All children with SCD should be screened for social determinants of health: " +
        "(a) School Arrangement " +
        "(b) Food insecurity " +
        "(c) Transportation " +
        "(d) Insurance " +
        "(e) Housing stability " +
        "(f) Household income " +
        "(g) Household occupants/support system " +
        "(h) Adherence barriers to prescribed therapy " +
        "(i) Connectivity- technology access " +
        "(j) Camp referral (for summer camps for people with SCD) " +
        "(k) Make-A-Wish " +
        "(l) Community Support",
      typeOfRecommendation: "Recommendation",
    },
    {
      title:
        "Standard Education for Parents and Caregivers as Well as Children with SCD",
      text:
        "1. Fever, infection " +
        "2. Disease type " +
        "3. Medications " +
        "4. Symptoms of accentuated anemia " +
        "(a) Splenic sequestration " +
        "(b) Transient aplastic crisis " +
        "5. Splenomegaly " +
        "(a) Teach parent/patient spleen exam " +
        "(b) Risks of splenomegaly on activity/sports/high velocity injury if spleen palpable " +
        "6. Dehydration, hyposthenuria, enuresis " +
        "7. Sports participation " +
        "8. Stroke symptoms " +
        "9. Pain " +
        "(a) Triggers/risk factors " +
        "(i) weather/temperature " +
        "(ii) swimming " +
        "(b) Symptom recognition " +
        "(c) Alternative treatments, coping mechanisms – mindfulness " +
        "(d) Individualized pain plan " +
        "10. Priapism " +
        "11. Potentially curative therapy options; HLA sibling screening as indicated " +
        "12. Disease modifying therapies " +
        "13. Dental: importance and pertinence to SCD. " +
        "14. Transition starting at age 12 " +
        "15. Research. Screening for eligibility for clinical trials. " +
        "16. Travel Planning " +
        "17. Relocation " +
        "18. Genetic Counseling",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Standard Annual Testing",
      text:
        "1. Complete blood count (CBC) with differential " +
        "2. Reticulocyte count " +
        "3. Renal function assessments (BMP) " +
        "4. Total and direct bilirubin " +
        "5. Fetal hemoglobin monitoring for hydroxyurea patients " +
        "6. LDH " +
        "7. Ferritin/transferrin saturation " +
        "8. RBC genotype (ONCE) " +
        "9. RBC phenotyping (min C/D/E/Kell if not done previously) if RBC genotype not obtained " +
        "10. Transfusion transmiRed infections for patients who have had transfusion in the past 12 " +
        "months (HIV, Hep C, and Hep B) or are sexually active " +
        "11. RBC Antibody screen if transfused in the last 12 months (or within 3 months of most recent " +
        "transfusion) " +
        "12. Urinalysis with microscopy – Recommended age 10 and older " +
        "13. Urine Albumin and Creatinine ratio " +
        "a. Routine urinalysis is not sufficient to detect early kidney disease and should not be " +
        "relied upon. " +
        "14. Vitamin D: annual 25-OH Vitamin D level should be done due to high risk for bone disease. ",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Standard Annual Testing",
      text: "Consider genetic confirmation alpha and beta globin gene; gamma globin analysis if not done.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "For Prevention of Pneumococcal Infection",
      text:
        "Continue prophylactic antibiotics through 5 years, then stop for all genotypes if fully vaccinated " +
        "and no risk factors.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "For Prevention of Pneumococcal Infection",
      text:
        "Continue or re-start prophylactic antibiotics if surgical splenectomy or serious s. pneumococcal " +
        "bacterial infection.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "For Prevention of Pneumococcal Infection",
      text: "Consider continuing past 5 years per patient preference and adherent to twice daily dosing.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Recommend use in all patients with HbSS/HbSBeta0 with start dose 20 mg/kg.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Hydroxyurea Use",
      text:
        "Recommend for all HbSC/SBeta+ with severe disease (VOC x 3 per 2 years/ACS/AVN) starting at " +
        "15 mg/kg due to increased risk for cytopenia in these genotypes.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Increase dose by 5 mg/kg every 8 weeks, monitor CBC/retic one month ager any dose increase.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Increase if ANC > 2000 and clinically indicated.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Increase if platelets > 150,000 or stable.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Hold hydroxyurea if ANC <1000, platelets <75, worsening anemia with ARC<80",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Defer to NHLBI guidelines regarding dose pause",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Do not hold hydroxyurea during hospitalizations unless neutropenic or thrombocytopenic. ",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Address medication adherence prior to dose escalation.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text: "Goal is to reach MTD or maximum dose 35mg/kg/day of IBW.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Hydroxyurea Use",
      text:
        "Recommendation for patients of child-bearing age on hydroxyurea: " +
        "1. All patients should be counseled to use contraception. " +
        "2. Recommend periodic urine HCG monitoring. " +
        "3. Educate all patients on potential risks for baby if pregnancy results while on " +
        "medication/fertility.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Splenectomy",
      text:
        "Consideration for splenectomy ager 1 life-threatening or 2 episodes of acute splenic " +
        "sequestration requiring transfusion.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Health",
      text:
        "Reproductive health discussions should start by 12-14 years of age in both boys and girls. This " +
        "conversation should include education on the genetics of SCD and what this means for their " +
        "potential offspring.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Reproductive Health",
      text:
        "Discuss menstrual pain with biological females (dysmenorrhea and VOE), heavy menstrual " +
        "bleeding, sexual activity. Prescribe or refer for hormonal therapy.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Reproductive Health",
      text: "Avoid using estrogen containing formulations due to thrombosis risk.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Health",
      text:
        "Initiate discussion of priapism with all at-risk patients (anatomic male) by age 12, discuss at " +
        "comprehensive care visits in this age group as highest risk.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Health",
      text:
        "Sexually transmiRed infection (STI)screening should be available for at risk patients as this is " +
        "part of routine reproductive health care.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Reproductive Health",
      text: "Provide access to gender affirming care.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Adolescent Care/Transition",
      text:
        "All programs should have a formal policy on transition and a formal curriculum such as those " +
        "offered by Got transition, ASH and St Jude",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Adolescent Care/Transition",
      text:
        "Each program should use the Transition Registry function within the Globin Research Network " +
        "for Data and Discovery registry (GRNDaD) to follow patients within the process and to share " +
        "data between pediatric and adult centers.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Adolescent Care/Transition",
      text: "All programs with SCD should initiate screening for readiness and preparing at age 12-14. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Adolescent Care/Transition",
      text:
        "Each sickle cell center must have a Transition Coordinator to help individual patients navigate " +
        "this process and to oversee the program. This can be a nurse, social worker, or navigator " +
        "familiar with SCD and the Transition process. This is a very high-risk time for patients and the " +
        "coordinator can help provide stability for the patient, ensure the patient demonstrates " +
        "transition readiness, and makes sure they successfully complete the process (establish care and " +
        "retention. ",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Standards for Timing of Transition",
      text:
        "Pregnancy and incarceration alone are not reasons for transition to Adult Care. Assistance may " +
        "be needed from adult providers while the patient is pregnant, but this is a high-risk time and " +
        "unstable time and not appropriate for acute transition of the patient to new providers. ",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Standards for Timing of Transition",
      text:
        "Transition should be a multi-disciplinary process. " +
        "(a) Teach patients about Disability Rights (register with office of disability for college, " +
        "504/IEP plan in place prior to high school graduation to go with them to college) " +
        "(b) Patient must receive a summary of care, an emergency plan, and a Pain Plan " +
        "(c) Recognize that many sickle cell patients have neurocognitive delays and that the process " +
        "will be slow, and patients ogen require support in their adult years as well. Include " +
        "neurocognitive assessments in transition leRer to adult providers. " +
        "(d) Transition is a process (handshake not a hand off). Relationships should be maintained " +
        "between pediatric and adult caregivers. " +
        "(e) High risk behaviors are a sign of underlying problems in adolescents and need further " +
        "explanation rather than punitive action or early dismissal from a pediatric program.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Standards for Timing of Transition",
      text:
        "Patient should transition all other specialties to adult care prior to transitioning sickle cell " +
        "provider (Pulmonary, renal, cardiology, etc) to ensure that the process is completed, as we are their " +
        "“medical home” for their SCD related care",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Standards for Timing of Transition",
      text: "Comprehensive adolescent care must include following assessments PROs- HR-QOL, PROMIS",
      typeOfRecommendation: "Recommendation",
    },
  ];

  const memoizedConsensusColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(ConsensusDetailsColumns, []);

  React.useEffect(() => {
    const firstHeader = document.getElementById("header-1")?.closest("th");
    const secondHeader = document.getElementById("header-2")?.closest("th");
    const thirdHeader = document.getElementById("header-3")?.closest("th");
    if (firstHeader) {
      firstHeader.style.width = "20%";
    }
    if (secondHeader) {
      secondHeader.style.width = "70%";
    }
    if (thirdHeader) {
      thirdHeader.style.width = "10%";
    }
  });

  return (
    <>
      <Helmet>
        <title>NASCC - Pediatric Recommendations</title>
        <meta
          name="description"
          content="NASCC consensus recommendations for pediatric patients with Sickle Cell Disease gathered from
          the NASCC annual meeting."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main>
        <section className="static-page-section mt-10">
          <h3 className="static-page-header pl-8">
            Care Delivery and Health Maintenance Consensus Recommendations for 2
            - 18 years old
          </h3>
          <p className="pl-8 pt-2 pb-8">
            There is a link to download the recommendation document at the
            bottom of the page.
          </p>
          <Table
            columns={memoizedConsensusColumns}
            data={tableData}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={false}
            headerCellClassName="pl-4"
            customRowCellClassName="pl-4 whitespace-normal"
            searchable={true}
          />
        </section>
        <section className="static-page-section mt-10">
          <h3 className="static-page-header">
            Download the Consensus Recommendations
          </h3>
          <ul className="list-disc pl-6">
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/y0gtnbgswdabnazbyu93b/Table-for-Consensus-Recommendations-2-18-YO.pdf?rlkey=zoy67b3466esm5ujcsmcf6bax&dl=0"
                className="link-text"
              >
                Care Delivery and Health Maintenance Consensus Recommendations
                for 2- 18 years old
              </Link>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};
