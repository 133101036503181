/** @module Components */

import React from "react";
import { Footer } from "../Footer/Footer";
import { Link } from "react-router-dom";

export const LogoUsageGuidelines: React.FC = () => {
  return (
    <>
      <main className="static-page-main">
        <h2 className="static-page-header">Logo Guidelines</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <div>
              <p>
                These logo guidelines will help maintain the integrity of NASCC
                brand mark while providing value to our center members. The
                power of the logo lies in its consistent and appropriate use;
                therefore, any use of the logo that is not consistent with these
                guidelines is strictly prohibited. NASCC reserves the right to
                change the logo or these usage guidelines at any time and solely
                at its discretion.
              </p>
              <p>
                Using the logo gives NASCC the right to request a written sample
                piece that explains the purpose (i.e., direct mail piece, web
                page, flyer etc.) of using the National Alliance of Sickle Cell
                Centers member logo prior to use and or at any time.
              </p>
            </div>
          </div>
        </section>
        <h2 className="static-page-header">Member Logo Guidelines</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <div>
              <p>
                Please use this Google Form to request usage of the NASCC member
                logo:{" "}
                <Link
                  to="https://forms.gle/2CCqMudE8xLWotvJ7"
                  className="link-text"
                >
                  https://forms.gle/2CCqMudE8xLWotvJ7
                </Link>
                .
              </p>
              <p>
                <strong>General Rules:</strong>
              </p>
              <ul>
                <li>
                  Permission to use the member logo is limited to members of the
                  National Alliance of Sickle Cell Centers. Those who lo longer
                  meet this criterion must discontinue use of the logo
                  immediately.
                </li>
                <li>
                  The member logo may be updated at any time, and NASCC members
                  are responsible for updating their own websites and printed
                  materials with the appropriate logo.
                </li>
                <li>
                  NASCC reserves the right to review use of the logo prior to
                  use and or at any time. Disregard of these usage guidelines
                  may result in revocation of the right to use the logo.
                </li>
                <li>
                  Any member found to be improperly using the logo or using any
                  outdated or unapproved logo will be notified and must correct
                  any deficiencies in their use of the logo. Refusal to correct
                  such deficiencies may result in revocation of the right to use
                  the logo. Continued use may result in revocation of
                  membership.
                </li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Usage</strong>
              </p>
              <ul>
                <li>
                  The NASCC member logo may be used in stationery, brochures,
                  web pages, and other print applications. The logo may NOT be
                  used on product packaging, clinical trials advertising, or any
                  event and/or activity not affiliated with NASCC participation.
                </li>
                <li>
                  NASCC will provide the electronic artwork of the logo. The
                  logo should not be altered, changed, or recreated in anyway.
                  The logo should not be tilted, rotated, condensed, expanded,
                  slanted, or leaned. The colors of the logo should not be
                  changed in any way.
                </li>
                <li>
                  The NASCC member logo is provided to its members as a
                  graphical representation to indicate membership. The logo
                  should not be used in a way that implies NASCC’s sponsorship
                  or endorsement of a product, service, event, publication, or
                  content presented on the member’s website or at other venues.
                </li>
                <li>
                  The member logo is owned by NASCC and all uses of the logo
                  insure the benefit of the NASCC. No member is permitted to use
                  the logo to disparage NASCC, its members, or NASCC related
                  activities.
                </li>
                <li>
                  The logo should be displayed in a positive manner. The logo
                  may not be used to depict NASCC in any negative way, as
                  determined in NASCC’s reasonable discretion.
                </li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Website Usage:</strong>
              </p>
              <ul>
                <li>
                  The logo must be an active link to the National Alliance of
                  Sickle Cell Centers website landing-page at this URL address:
                  https://sicklecellcenters.org
                </li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Print Presentation:</strong>
              </p>
              <ul>
                <li>
                  The logo may not be used more prominently than a member’s name
                  or company name.
                </li>
                <li>
                  Recommended placement of the logo is in the lower-right corner
                  of all printed materials.
                </li>
                <li>
                  Use only NASCC authorized electronic artwork of the logo. The
                  NASCC member logo is to be displayed independently, not
                  conjoined with any other graphic or text.
                </li>
                <li>
                  Redraws or distortion of the logo are not permitted beyond
                  what is provided.
                </li>
                <li>
                  Avoid using the logo on a busy background or backgrounds with
                  a similar color of the logo.
                </li>
                <li>
                  The area surrounding the logo is to be clean and uncluttered.
                  The clear space should measure a minimum of ½” on all sides.
                </li>
                <li>
                  When using a color other than NASCC colors, please print the
                  logo reversed in white on a dark background.
                </li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Web Presentation:</strong>
              </p>
              <ul>
                <li>
                  NASCC member logo may not be used more prominently than a
                  member’s name or company name.
                </li>
                <li>
                  Use only NASCC member authorized electronic artwork of the
                  logo. NASCC member logo is to be displayed independently, not
                  conjoined with any other graphic or text.
                </li>
                <li>
                  Do not distort or alter the logo in any way. Do not rotate or
                  animate the logo.
                </li>
                <li>
                  Avoid using the logo on a busy background or backgrounds with
                  a similar color of the logo.
                </li>
                <li>
                  The area surrounding the logo is to be clean and uncluttered.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_cbf3dfe0-0fdd-4e26-96b0-6da2f0a5fc7e" />
    </>
  );
};
