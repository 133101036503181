/** @module Components */
import React from "react";
import { Link } from "react-router-dom";

import { SubscriberForm } from "../SubscriberForm/SubscriberForm";
import { Footer } from "../Footer/Footer";

export const CenterJoin: React.FC = () => {
  return (
    <>
      <main className="static-page-main" data-testid="main_center-join">
        <h2
          className="static-page-header"
          data-testid="page-header_center-join"
        >
          Stay Up to Date by Joining NASCC
        </h2>
        <section
          className="static-page-section"
          data-testid="section_switch-to-regular-subscriber"
        >
          <h4
            className="text-2xl font-bold"
            data-testid="header_switch-to-regular-subscriber"
          >
            If you do <strong>NOT</strong> represent a center, please use{" "}
            <Link to="/join" className="link-text">
              our regular subscriber form to get important updates about our
              mission
            </Link>
            !
          </h4>
        </section>
        <section data-testid="section_center-join-application">
          <div className="max-w-7xl mx-auto px-4 py-4 min-h-two-third-screen sm:px-6 lg:py-8 lg:px-8">
            <div className="py-4 px-2 bg-secondary bg-red-60 rounded-3xl h-11/12 justify-center sm:py-8 sm:px-6 lg:px-8 lg:py-20 lg:flex lg:items-center">
              <div className="w-full flex-col lg:w-5/12">
                <h2 className="text-3xl font-extrabold tracking-tight text-white">
                  Request an application for your center to join NASCC!
                </h2>
                <p className="mt-4 max-w-3xl text-lg text-indigo-100">
                  Joining NASCC comes with many benefits, potential access to
                  funding, cutting edge research, and a large community made up
                  of dozens of other centers. If you are curious as to what the
                  requirements are to join as a center, please{" "}
                  <Link to="/center_criteria" className="link-text">
                    visit our center criteria page
                  </Link>
                  .
                </p>
              </div>
              <SubscriberForm centerApplicationRequest={true} />
            </div>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_48d5d902-2a2f-4c9a-8fc0-7cea5263a88e" />
    </>
  );
};
