import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface AppContextInterface {
  displayModal: boolean;
  setDisplayModal: Dispatch<SetStateAction<boolean>>;
  // modalTitle: string;
  // setModalTitle: Dispatch<SetStateAction<string>>;
  // modalBody: string;
  // setModalBody: Dispatch<SetStateAction<string>>;
  // modalRightButtonText: string;
  // setModalRightButtonText: Dispatch<SetStateAction<string>>;
  // modalLeftButtonText: string;
  // setModalLeftButtonText: Dispatch<SetStateAction<string>>;
  // displayRightButton: boolean;
  // setDisplayRightButton: Dispatch<SetStateAction<boolean>>;
  // displayLeftButton: boolean;
  // setDisplayLeftButton: Dispatch<SetStateAction<boolean>>;
}

const defaultAppContext = {
  displayModal: false,
  setDisplayModal: (): boolean => false,
  // modalTitle: "",
  // setModalTitle: (): string => "",
  // modalBody: "",
  // setModalBody: (): string => "",
  // modalRightButtonText: "",
  // setModalRightButtonText: (): string => "",
  // modalLeftButtonText: "",
  // setModalLeftButtonText: (): string => "",
  // displayRightButton: false,
  // setDisplayRightButton: (): boolean => false,
  // displayLeftButton: false,
  // setDisplayLeftButton: (): boolean => false,
};

const AppContext = createContext<AppContextInterface>(defaultAppContext);

export const useAppContext = (): AppContextInterface => useContext(AppContext);

export default AppContext;
