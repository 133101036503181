/** @module Components */
import React from "react";
import { RadioGroup } from "@headlessui/react";
import { Field, FieldArray, useFormikContext } from "formik";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

import { CenterApplicationFormType } from "../../types/applicationTypes";
import { ApplicationFormRadioButton } from "../Shared/RadioButtons/ApplicationFormRadioButton";
import { classNames } from "../../shared/Functions";

export const CenterComponentsForm: React.FC = () => {
  const { setFieldValue } = useFormikContext();
  const { values, initialValues } =
    useFormikContext<CenterApplicationFormType>();

  // Center Employee Hooks
  const [advancedPractitionerEnabled, setAdvancedPractitionerEnabled] =
    React.useState(false);
  const [caseManagerEnabled, setCaseManagerEnabled] = React.useState(false);
  const [mentalHealthEnabled, setMentalHealthEnabled] = React.useState(false);
  const [pharmacistEnabled, setPharmacistEnabled] = React.useState(false);
  const [physicalTherapistEnabled, setPhysicalTherapistEnabled] =
    React.useState(false);
  const [primaryCarePhysicianEnabled, setPrimaryCarePhysicianEnabled] =
    React.useState(false);
  const [sickleCellSpecialistEnabled, setSickleCellSpecialistEnabled] =
    React.useState(false);
  const [socialWorkerEnabled, setSocialWorkerEnabled] = React.useState(false);
  const [otherStaffEnabled, setOtherStaffEnabled] = React.useState(false);
  const [numberOfSickleCellNursesEnabled, setNumberOfSickleCellNursesEnabled] =
    React.useState(false);

  // Additional Component Hooks
  const [dedicatedClinicSpaceEnabled, setDedicatedClinicSpaceEnabled] =
    React.useState(false);
  const [dcsSharedWithOtherEnabled, setDcsSharedWithOtherEnabled] =
    React.useState(false);
  const [infusionAreaEnabled, setInfusionAreaEnabled] = React.useState(false);
  const [infAreaSharedWithOtherEnabled, setInfAreaSharedWithOtherEnabled] =
    React.useState(false);

  console.log(values);

  function hideInfusionAreaOptions() {
    return setInfusionAreaEnabled(!infusionAreaEnabled);
  }

  function hideInfusionAreaSharedWithOtherName() {
    return setInfAreaSharedWithOtherEnabled(!infAreaSharedWithOtherEnabled);
  }

  function hideDcsSharedOptions() {
    return setDedicatedClinicSpaceEnabled(!dedicatedClinicSpaceEnabled);
  }

  function hideDcsSharedWithOtherName() {
    return setDcsSharedWithOtherEnabled(!dcsSharedWithOtherEnabled);
  }

  // @ts-ignore
  return (
    <div className="bg-white shadow px-4 py-5 border border-gray-200 sm:rounded-lg sm:p-6 md:shadow-xl">
      {/* Center Type Specific Information */}
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Center Specifics and Components
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please fill in information specifically about your center and the
            makeup of it's patients.
          </p>
        </div>
        <fieldset
          className="mt-5 md:mt-0 md:col-span-2"
          id="center-components-fieldset"
        >
          <legend className="sr-only">Center Type Specific Information</legend>
          <div className="grid grid-cols-6 gap-6">
            {/* Adult Information */}
            {initialValues.centerType &&
              initialValues.centerType !== "pediatric" && (
                <>
                  {" "}
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-adult-sca"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of SCA (Hgb SS/SB0thal) Adult Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="number"
                      name="numberOfAdultSca"
                      id="number-adult-sca"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-adult-sc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of SC Adult Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="text"
                      name="numberOfAdultSc"
                      id="number-adult-sc"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-adult-other"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of Other Adult Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="text"
                      name="numberOfAdultOther"
                      id="number-adult-other"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>{" "}
                </>
              )}

            {/* Pediatric Information */}
            {initialValues.centerType &&
              initialValues.centerType !== "adult" && (
                <>
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-pediatric-sc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of SCA (Hgb SS/SB0thal) Pediatric Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="text"
                      name="numberOfPediatricSca"
                      id="number-pediatric-sc"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-pediatric-sc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of SC Pediatric Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="text"
                      name="numberOfPediatricSc"
                      id="number-pediatric-sc"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between sm:col-span-2">
                    <label
                      htmlFor="number-pediatric-other"
                      className="block text-sm font-medium text-gray-700"
                    >
                      # of Other Pediatric Patients*{" "}
                      <span className="text-gray-400 italic">(Estimated)</span>
                    </label>
                    <Field
                      type="text"
                      name="numberOfPediatricOther"
                      id="number-pediatric-other"
                      autoComplete="off"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      required
                    />
                  </div>
                </>
              )}
          </div>
        </fieldset>
      </div>

      {/* Center Employee Information */}
      <div className="pt-16 md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Center Employee Makeup and Information
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please fill in information specifically about your center and the
            makeup of it's staff. <strong>Note:</strong> embedded indicates the
            employee is part of the center and typically applies to doctors or
            providers, while full time is usually for staff.
          </p>
        </div>
        <fieldset
          className="mt-5 md:mt-0 md:col-span-2"
          id="center-employee-makeup-fieldset"
        >
          <legend className="sr-only">Center Employee Information</legend>
          <div className="grid grid-cols-6 gap-6">
            {/* Advanced Practice Providers Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Advanced Practitioners Information"
                checked={advancedPractitionerEnabled}
                setChecked={setAdvancedPractitionerEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="advancedPractice"
                render={(arrayHelpers) => (
                  <>
                    {values.advancedPractice && advancedPractitionerEnabled
                      ? values.advancedPractice.map(
                          (advancedPractice, index) => (
                            <div
                              key={index}
                              className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                            >
                              <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                                <label
                                  htmlFor={`advanced-practitioner-name-and-info-${index}`}
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Name and Credentials of Advanced Practitioner
                                </label>
                                <Field
                                  type="text"
                                  name="nameAndInfo"
                                  id={`advanced-practitioner-name-and-info-${index}`}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `advancedPractice[${index}].nameAndInfo`,
                                      event.target.value,
                                    )
                                  }
                                  autoComplete="off"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  required={advancedPractitionerEnabled}
                                />
                              </div>
                              <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employment Type
                                </label>
                                <RadioGroup
                                  value={advancedPractice.employeeType}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `advancedPractice[${index}].employeeType`,
                                      event,
                                    )
                                  }
                                  className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Choose a working type for this employee
                                  </RadioGroup.Label>
                                  <RadioGroup.Option
                                    value="embedded"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Embedded
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="fullTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Full Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="partTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Part Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                </RadioGroup>
                              </div>
                            </div>
                          ),
                        )
                      : undefined}
                    {values.advancedPractice && advancedPractitionerEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.advancedPractice.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.advancedPractice
                                ? values.advancedPractice.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Case Manager Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Case Manager Information"
                checked={caseManagerEnabled}
                setChecked={setCaseManagerEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="caseManager"
                render={(arrayHelpers) => (
                  <>
                    {values.caseManager && caseManagerEnabled
                      ? values.caseManager.map((caseManager, index) => (
                          <div
                            key={index}
                            className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                          >
                            <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                              <label
                                htmlFor={`case-manager-name-and-info-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name and Credentials of Case Manager
                              </label>
                              <Field
                                type="text"
                                name="nameAndInfo"
                                id={`case-manager-name-and-info-${index}`}
                                onChange={(event: any) =>
                                  setFieldValue(
                                    `caseManager[${index}].nameAndInfo`,
                                    event.target.value,
                                  )
                                }
                                autoComplete="off"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                required={caseManagerEnabled}
                              />
                            </div>
                            <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">
                                Employment Type
                              </label>
                              <RadioGroup
                                value={caseManager.employeeType}
                                onChange={(event) =>
                                  setFieldValue(
                                    `caseManager[${index}].employeeType`,
                                    event,
                                  )
                                }
                                className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  Choose a working type for this employee
                                </RadioGroup.Label>
                                <RadioGroup.Option
                                  value="embedded"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Embedded
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="fullTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Full Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="partTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Part Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              </RadioGroup>
                            </div>
                          </div>
                        ))
                      : undefined}
                    {values.caseManager && caseManagerEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.caseManager.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.caseManager
                                ? values.caseManager.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Mental Health Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Mental Health Specialist Information"
                checked={mentalHealthEnabled}
                setChecked={setMentalHealthEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="mentalHealth"
                render={(arrayHelpers) => (
                  <>
                    {values.mentalHealth && mentalHealthEnabled
                      ? values.mentalHealth.map((mentalHealth, index) => (
                          <div
                            key={index}
                            className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                          >
                            <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                              <label
                                htmlFor={`mental-health-name-and-info-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name and Credentials of Mental Health Specialist
                              </label>
                              <Field
                                type="text"
                                name="nameAndInfo"
                                id={`mental-health-name-and-info-${index}`}
                                onChange={(event: any) =>
                                  setFieldValue(
                                    `mentalHealth[${index}].nameAndInfo`,
                                    event.target.value,
                                  )
                                }
                                autoComplete="off"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                required={mentalHealthEnabled}
                              />
                            </div>
                            <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">
                                Employment Type
                              </label>
                              <RadioGroup
                                value={mentalHealth.employeeType}
                                onChange={(event) =>
                                  setFieldValue(
                                    `mentalHealth[${index}].employeeType`,
                                    event,
                                  )
                                }
                                className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  Choose a working type for this employee
                                </RadioGroup.Label>
                                <RadioGroup.Option
                                  value="embedded"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Embedded
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="fullTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Full Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="partTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Part Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              </RadioGroup>
                            </div>
                          </div>
                        ))
                      : undefined}
                    {values.mentalHealth && mentalHealthEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.mentalHealth.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.mentalHealth
                                ? values.mentalHealth.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Pharmacist Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Pharmacist Information"
                checked={pharmacistEnabled}
                setChecked={setPharmacistEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="pharmacist"
                render={(arrayHelpers) => (
                  <>
                    {values.pharmacist && pharmacistEnabled
                      ? values.pharmacist.map((pharmacist, index) => (
                          <div
                            key={index}
                            className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                          >
                            <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                              <label
                                htmlFor={`pharmacist-name-and-info-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name and Credentials of Pharmacist
                              </label>
                              <Field
                                type="text"
                                name="nameAndInfo"
                                id={`pharmacist-name-and-info-${index}`}
                                onChange={(event: any) =>
                                  setFieldValue(
                                    `pharmacist[${index}].nameAndInfo`,
                                    event.target.value,
                                  )
                                }
                                autoComplete="off"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                required={pharmacistEnabled}
                              />
                            </div>
                            <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">
                                Employment Type
                              </label>
                              <RadioGroup
                                value={pharmacist.employeeType}
                                onChange={(event) =>
                                  setFieldValue(
                                    `pharmacist[${index}].employeeType`,
                                    event,
                                  )
                                }
                                className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  Choose a working type for this employee
                                </RadioGroup.Label>
                                <RadioGroup.Option
                                  value="embedded"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Embedded
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="fullTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Full Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="partTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Part Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              </RadioGroup>
                            </div>
                          </div>
                        ))
                      : undefined}
                    {values.pharmacist && pharmacistEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.pharmacist.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.pharmacist
                                ? values.pharmacist.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Physical Therapist Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Physical Therapist Information"
                checked={physicalTherapistEnabled}
                setChecked={setPhysicalTherapistEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="physicalTherapist"
                render={(arrayHelpers) => (
                  <>
                    {values.physicalTherapist && physicalTherapistEnabled
                      ? values.physicalTherapist.map(
                          (physicalTherapist, index) => (
                            <div
                              key={index}
                              className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                            >
                              <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                                <label
                                  htmlFor={`physical-therapist-name-and-info-${index}`}
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Name and Credentials of Physical Therapist
                                </label>
                                <Field
                                  type="text"
                                  name="nameAndInfo"
                                  id={`physical-therapist-name-and-info-${index}`}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `physicalTherapist[${index}].nameAndInfo`,
                                      event.target.value,
                                    )
                                  }
                                  autoComplete="off"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  required={physicalTherapistEnabled}
                                />
                              </div>
                              <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employment Type
                                </label>
                                <RadioGroup
                                  value={physicalTherapist.employeeType}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `physicalTherapist[${index}].employeeType`,
                                      event,
                                    )
                                  }
                                  className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Choose a working type for this employee
                                  </RadioGroup.Label>
                                  <RadioGroup.Option
                                    value="embedded"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Embedded
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="fullTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Full Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="partTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Part Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                </RadioGroup>
                              </div>
                            </div>
                          ),
                        )
                      : undefined}
                    {values.physicalTherapist && physicalTherapistEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.physicalTherapist.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.physicalTherapist
                                ? values.physicalTherapist.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Primary Care Physician Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Primary Care Physician Information"
                checked={primaryCarePhysicianEnabled}
                setChecked={setPrimaryCarePhysicianEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="primaryCarePhysician"
                render={(arrayHelpers) => (
                  <>
                    {values.primaryCarePhysician && primaryCarePhysicianEnabled
                      ? values.primaryCarePhysician.map(
                          (primaryCarePhysician, index) => (
                            <div
                              key={index}
                              className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                            >
                              <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                                <label
                                  htmlFor={`primary-care-physician-name-and-info-${index}`}
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Name and Credentials of Primary Care Physician
                                </label>
                                <Field
                                  type="text"
                                  name="nameAndInfo"
                                  id={`primary-care-physician-name-and-info-${index}`}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `primaryCarePhysician[${index}].nameAndInfo`,
                                      event.target.value,
                                    )
                                  }
                                  autoComplete="off"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  required={primaryCarePhysicianEnabled}
                                />
                              </div>
                              <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employment Type
                                </label>
                                <RadioGroup
                                  value={primaryCarePhysician.employeeType}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `primaryCarePhysician[${index}].employeeType`,
                                      event,
                                    )
                                  }
                                  className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Choose a working type for this employee
                                  </RadioGroup.Label>
                                  <RadioGroup.Option
                                    value="embedded"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Embedded
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="fullTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Full Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="partTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Part Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                </RadioGroup>
                              </div>
                            </div>
                          ),
                        )
                      : undefined}
                    {values.primaryCarePhysician &&
                    primaryCarePhysicianEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.primaryCarePhysician.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.primaryCarePhysician
                                ? values.primaryCarePhysician.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Sickle Cell Specialist Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Sickle Cell Specialist Information"
                checked={sickleCellSpecialistEnabled}
                setChecked={setSickleCellSpecialistEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="sickleCellSpecialist"
                render={(arrayHelpers) => (
                  <>
                    {values.sickleCellSpecialist && sickleCellSpecialistEnabled
                      ? values.sickleCellSpecialist.map(
                          (sickleCellSpecialist, index) => (
                            <div
                              key={index}
                              className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                            >
                              <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                                <label
                                  htmlFor={`sickle-cell-specialistname-and-info-${index}`}
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Name and Credentials of Sickle Cell Specialist
                                </label>
                                <Field
                                  type="text"
                                  name="nameAndInfo"
                                  id={`sickle-cell-specialistname-and-info-${index}`}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `sickleCellSpecialist[${index}].nameAndInfo`,
                                      event.target.value,
                                    )
                                  }
                                  autoComplete="off"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  required={sickleCellSpecialistEnabled}
                                />
                              </div>
                              <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employment Type
                                </label>
                                <RadioGroup
                                  value={sickleCellSpecialist.employeeType}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `sickleCellSpecialist[${index}].employeeType`,
                                      event,
                                    )
                                  }
                                  className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Choose a working type for this employee
                                  </RadioGroup.Label>
                                  <RadioGroup.Option
                                    value="embedded"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Embedded
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="fullTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Full Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                  <RadioGroup.Option
                                    value="partTime"
                                    className={({ active, checked }) =>
                                      classNames([
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        checked
                                          ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                        "border rounded-md py-3 px-3 flex items-center justify-center " +
                                          "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                      ])
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      Part Time
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                </RadioGroup>
                              </div>
                            </div>
                          ),
                        )
                      : undefined}
                    {values.sickleCellSpecialist &&
                    sickleCellSpecialistEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.sickleCellSpecialist.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.sickleCellSpecialist
                                ? values.sickleCellSpecialist.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Social Worker Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Social Worker Information"
                checked={socialWorkerEnabled}
                setChecked={setSocialWorkerEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="socialWorker"
                render={(arrayHelpers) => (
                  <>
                    {values.socialWorker && socialWorkerEnabled
                      ? values.socialWorker.map((socialWorker, index) => (
                          <div
                            key={index}
                            className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                          >
                            <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                              <label
                                htmlFor={`social-worker-name-and-info-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name and Credentials of Social Worker
                              </label>
                              <Field
                                type="text"
                                name="nameAndInfo"
                                id={`social-worker-name-and-info-${index}`}
                                onChange={(event: any) =>
                                  setFieldValue(
                                    `socialWorker[${index}].nameAndInfo`,
                                    event.target.value,
                                  )
                                }
                                autoComplete="off"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                required={socialWorkerEnabled}
                              />
                            </div>
                            <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">
                                Employment Type
                              </label>
                              <RadioGroup
                                value={socialWorker.employeeType}
                                onChange={(event) =>
                                  setFieldValue(
                                    `socialWorker[${index}].employeeType`,
                                    event,
                                  )
                                }
                                className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  Choose a working type for this employee
                                </RadioGroup.Label>
                                <RadioGroup.Option
                                  value="embedded"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Embedded
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="fullTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Full Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="partTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Part Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              </RadioGroup>
                            </div>
                          </div>
                        ))
                      : undefined}
                    {values.socialWorker && socialWorkerEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.socialWorker.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.socialWorker
                                ? values.socialWorker.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Other Staff Information */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Other Staff Information"
                checked={otherStaffEnabled}
                setChecked={setOtherStaffEnabled}
              />
              {/* @ts-ignore */}
              <FieldArray
                name="otherStaff"
                render={(arrayHelpers) => (
                  <>
                    {values.otherStaff && otherStaffEnabled
                      ? values.otherStaff.map((otherStaff, index) => (
                          <div
                            key={index}
                            className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6"
                          >
                            <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                              <label
                                htmlFor={`other-staff-name-and-info-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name and Credentials of Staff
                              </label>
                              <Field
                                type="text"
                                name="nameAndInfo"
                                id={`other-staff--and-info-${index}`}
                                onChange={(event: any) =>
                                  setFieldValue(
                                    `otherStaff[${index}].nameAndInfo`,
                                    event.target.value,
                                  )
                                }
                                autoComplete="off"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                required={otherStaffEnabled}
                              />
                            </div>
                            <div className="flex flex-col justify-between pl-4 sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">
                                Employment Type
                              </label>
                              <RadioGroup
                                value={otherStaff.employeeType}
                                onChange={(event) =>
                                  setFieldValue(
                                    `otherStaff[${index}].employeeType`,
                                    event,
                                  )
                                }
                                className="grid grid-cols-3 col-span-2 items-end md:space-x-2 -ml-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  Choose a working type for this employee
                                </RadioGroup.Label>
                                <RadioGroup.Option
                                  value="embedded"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase col-span-1 h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Embedded
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="fullTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Full Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                  value="partTime"
                                  className={({ active, checked }) =>
                                    classNames([
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-500"
                                        : "",
                                      checked
                                        ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                      "border rounded-md py-3 px-3 flex items-center justify-center " +
                                        "text-sm font-medium uppercase h-[85%] cursor-pointer sm:flex-1",
                                    ])
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    Part Time
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              </RadioGroup>
                            </div>
                          </div>
                        ))
                      : undefined}
                    {values.otherStaff && otherStaffEnabled ? (
                      <div className="col-span-2 col-start-2 flex justify-end items-end md:col-start-5">
                        {values.otherStaff.length > 0 ? (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                            text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap h-[80%]"
                            onClick={() => arrayHelpers.remove(0)}
                          >
                            <MinusIcon className="w-3 h-3" />
                            <span className="block text-sm font-medium text-white pl-2">
                              Remove From List
                            </span>
                          </button>
                        ) : undefined}
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md
                           text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap ml-4 h-[80%]"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.otherStaff
                                ? values.otherStaff.length + 1
                                : 0,
                              {
                                nameAndInfo: "",
                                employeeType: "",
                              },
                            )
                          }
                        >
                          <PlusIcon className="w-3 h-3" />
                          <span className="block text-sm font-medium text-white pl-2">
                            Add to List
                          </span>
                        </button>
                      </div>
                    ) : undefined}
                  </>
                )}
              />
            </div>

            {/* Sickle Cell Trained Nurses Count */}
            <div className="col-span-6 grid grid-cols-6">
              <ApplicationFormRadioButton
                label="Add Number of Sickle Cell Trained Nurses"
                checked={numberOfSickleCellNursesEnabled}
                setChecked={setNumberOfSickleCellNursesEnabled}
              />
              {values.numberOfSickleCellNurses !== undefined &&
                numberOfSickleCellNursesEnabled && (
                  <div className="col-span-3 grid grid-cols-2 pt-2 md:col-span-6 md:grid-cols-6">
                    <div className="col-span-3 flex flex-col justify-between sm:col-span-3">
                      <label
                        htmlFor="sickle-cell-trained-nurses-count"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Number of Sickle Cell Disease Trained Nurses
                      </label>
                      <Field
                        type="number"
                        name="numberOfSickleCellNurses"
                        id="sickle-cell-trained-nurses-count"
                        autoComplete="off"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        required={numberOfSickleCellNursesEnabled}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </fieldset>
      </div>

      {/* Additional Center Components */}
      <div className="pt-16 md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Additional Center Components
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please indicate if you have these additional ares present in your
            center.
          </p>
        </div>
        <fieldset
          className="mt-5 md:grid md:grid-cols-2 md:mt-0 md:col-span-2"
          id="center-components-fieldset"
        >
          <legend className="sr-only">Additional Center Components</legend>

          {/* Dedicated Clinic Space */}
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <Field
                id="dedicated-clinic-space"
                aria-describedby="dedicated-clinic-space-description"
                name="dedicatedClinicSpace"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                onClick={hideDcsSharedOptions}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="dedicated-clinic-space"
                className="font-medium text-gray-700"
              >
                Dedicated Clinic Space
              </label>
              <p
                id="dedicated-clinic-space-description"
                className="text-gray-500"
              >
                Check this if you have a dedicated clinic space or location.
              </p>
              {dedicatedClinicSpaceEnabled && (
                <div className="flex flex-col">
                  <div className="flex pt-3">
                    <div className="flex items-center h-5">
                      <Field
                        id="independent-clinic-space"
                        aria-describedby="independent-clinic-space-description"
                        name="indepClinicSpace"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="independent-clinic-space"
                        className="font-medium text-gray-700"
                      >
                        Independent Clinic Space
                      </label>
                      <p
                        id="independent-clinic-space-description"
                        className="text-gray-500"
                      >
                        Dedicated independent clinic space for your center.
                      </p>
                    </div>
                  </div>
                  <div className="flex pt-1">
                    <div className="flex items-center h-5">
                      <Field
                        id="shared-with-cancer-center"
                        aria-describedby="shared-with-cancer-center-description"
                        name="dcsSharedWithCancerCenter"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="shared-with-cancer-center"
                        className="font-medium text-gray-700"
                      >
                        Shared With Cancer Center
                      </label>
                      <p
                        id="shared-with-cancer-center-description"
                        className="text-gray-500"
                      >
                        Clinic space is shared with the cancer center.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col pt-1">
                    <div className="flex">
                      <div className="flex items-center h-5">
                        <Field
                          id="shared-with-other"
                          aria-describedby="shared-with-other-description"
                          name="dcsSharedWithOther"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          onClick={hideDcsSharedWithOtherName}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="shared-with-other"
                          className="font-medium text-gray-700"
                        >
                          Shared With Other
                        </label>
                        <p
                          id="shared-with-other-description"
                          className="text-gray-500"
                        >
                          Clinic space is shared with someone else.
                        </p>
                      </div>
                    </div>
                    {dcsSharedWithOtherEnabled && (
                      <div className="flex flex-col pt-2 ml-6">
                        <div className="text-sm">
                          <p
                            id="dcs-shared-with-other-name-label"
                            className="text-gray-700"
                          >
                            Space is shared with:
                          </p>
                        </div>
                        <div className="flex items-center">
                          <Field
                            id="dcs-shared-with-other-name"
                            type="text"
                            aria-labelledby="dcs-shared-with-other-name-label"
                            name="dcsSharedWithOtherName"
                            className="focus:ring-indigo-500 border-gray-300 rounded w-full"
                            required={dcsSharedWithOtherEnabled}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Infusion Area */}
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <Field
                id="infusion-area"
                aria-describedby="infusion-area-description"
                name="infusionArea"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                onClick={hideInfusionAreaOptions}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="infusion-area"
                className="font-medium text-gray-700"
              >
                Infusion Area
              </label>
              <p id="infusion-area-description" className="text-gray-500">
                Check this if you have an area for infusions.
              </p>
              {infusionAreaEnabled && (
                <div className="flex flex-col">
                  <div className="flex pt-3">
                    <div className="flex items-center h-5">
                      <Field
                        id="dedicated-day-hospital"
                        aria-describedby="dedicated-day-hospital-description"
                        name="infAreaDedicatedDayHospital"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="dedicated-day-hospital"
                        className="font-medium text-gray-700"
                      >
                        Dedicated Day Hospital
                      </label>
                      <p
                        id="dedicated-day-hospital-description"
                        className="text-gray-500"
                      >
                        Dedicated space in a day hospital for infusions.
                      </p>
                    </div>
                  </div>
                  <div className="flex pt-1">
                    <div className="flex items-center h-5">
                      <Field
                        id="inf-shared-with-cancer-center"
                        aria-describedby="inf-shared-with-cancer-center-description"
                        name="infAreaSharedWithCancerCenter"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="inf-shared-with-cancer-center"
                        className="font-medium text-gray-700"
                      >
                        Shared With Cancer Center
                      </label>
                      <p
                        id="inf-shared-with-cancer-center-description"
                        className="text-gray-500"
                      >
                        Infusion space is shared with the cancer center.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col pt-1">
                    <div className="flex">
                      <div className="flex items-center h-5">
                        <Field
                          id="inf-shared-with-other"
                          aria-describedby="inf-shared-with-other-description"
                          name="infAreaSharedWithOther"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          onClick={hideInfusionAreaSharedWithOtherName}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="inf-shared-with-other"
                          className="font-medium text-gray-700"
                        >
                          Shared With Other
                        </label>
                        <p
                          id="inf-shared-with-other-description"
                          className="text-gray-500"
                        >
                          Infusion space is shared with someone else.
                        </p>
                      </div>
                    </div>
                    {infAreaSharedWithOtherEnabled && (
                      <div className="flex flex-col pt-2 ml-6">
                        <div className="text-sm">
                          <p
                            id="shared-with-other-name"
                            className="text-gray-700"
                          >
                            Space is shared with:
                          </p>
                        </div>
                        <div className="flex items-center">
                          <Field
                            id="shared-with-other-name"
                            type="text"
                            aria-describedby="shared-with-other-name"
                            name="infAreaSharedWithOtherName"
                            className="focus:ring-indigo-500 border-gray-300 rounded w-full"
                            required={infAreaSharedWithOtherEnabled}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Observation Unit */}
          <div className="relative flex items-start md:pt-10">
            <div className="flex items-center h-5">
              <Field
                id="observation-unit"
                aria-describedby="observation-unit-description"
                name="observationUnit"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="observation-unit"
                className="font-medium text-gray-700"
              >
                Observation Unit
              </label>
              <p id="observation-unit-description" className="text-gray-500">
                Check this if you have an observation unit.
              </p>
            </div>
          </div>

          {initialValues.centerType && initialValues.centerType !== "adult" && (
            <>
              {/* Pediatric Hematology */}
              <div className="relative flex items-start md:pt-10">
                <div className="flex items-center h-5">
                  <Field
                    id="pediatric-hematology"
                    aria-describedby="pediatric-hematology-description"
                    name="pediatricHematology"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="pediatric-hematology"
                    className="font-medium text-gray-700"
                  >
                    Pediatric Hematology
                  </label>
                  <p
                    id="pediatric-hematology-description"
                    className="text-gray-500"
                  >
                    Check this if you have a pediatric hematologist on staff.
                  </p>
                </div>
              </div>
            </>
          )}
        </fieldset>
      </div>
    </div>
  );
};
