/** @modules Containers */
import React from "react";

import { Hero, Sponsors, Goals, Footer } from "../../components";
import { Helmet } from "react-helmet-async";

export const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Home</title>
        <meta
          name="description"
          content="The website for the National Alliance of Sickle Cell Centers - supporting sickle cell centers and patients around the US and the world"
        />
        <meta charSet="UTF-8" />
      </Helmet>
      <main className="flex flex-col max-w-10xl mt-5 xl:mx-auto">
        <Hero />
        <Sponsors />
        <Goals />
      </main>
      <Footer dataTestId="footer_1dc8c9a4-dc75-4fcd-be7b-46f246c7a4b1" />
    </>
  );
};
