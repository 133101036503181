/** @module Containers */
import React from "react";

export const Error: React.FC = () => {
  return (
    <div
      className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      data-testid="error-page-root-element"
    >
      <div className="">
        <h2
          className="text-center font-bold text-5xl"
          data-testid="error-heading"
        >
          Not Authorized
        </h2>
        <p className="text-center pt-5" data-testid="error-text">
          You are not authorized to view the requested page, or your token has
          expired.
          <br />
          If you believe you have encountered this error by mistake, please
          contact{" "}
          <a
            href="mailto:"
            className="text-primary-700"
            data-testid="support-link"
          >
            support@sicklecellcenters.org
          </a>
          .
        </p>
      </div>
    </div>
  );
};
