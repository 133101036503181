/** @module Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";

import { useGetCenterApplicationsInfoQuery } from "../../services/applicationService";
import { Table } from "../../components";
import { SharedApplicationColumns } from "../../components/Shared/Columns";

export const PendingApplications: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );

  const {
    data: pendingCenterApplications,
    isFetching,
    isLoading,
    isError,
  } = useGetCenterApplicationsInfoQuery("pending");

  const memoizedSharedApplicationColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedApplicationColumns, []);
  console.log({ pendingCenterApplications });

  return (
    <section>
      <h4 className="static-page-header mx-8">Pending Applications</h4>
      <div className="flex flex-col">
        {!isError && pendingCenterApplications && (
          <Table
            columns={memoizedSharedApplicationColumns}
            data={pendingCenterApplications}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={isFetching || isLoading}
            headerCellClassName="pl-4"
            customRowCellClassName="pl-4"
          />
        )}
      </div>
    </section>
  );
};
