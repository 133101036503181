/** @module Components */
import React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDEditor from "@uiw/react-md-editor";

import { toastError, toastSuccess } from "../Shared";
import { JobPostFormType } from "../../types";
import { usePostJobPostFormMutation } from "../../services/jobPostService";
import { parse, parseISO } from "date-fns";

export const JobPostForm: React.FC = () => {
  const navigate = useNavigate();

  // State and AppContext

  // RTKQ Queries and Mutations
  const [postJobPostForm] = usePostJobPostFormMutation();

  const formValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Required"),
    companyWebsite: Yup.string().min(5, "Too short!").required("Required"),
    title: Yup.string().min(4, "Too short!").required("Required"),
    jobAddrStreetAddress: Yup.string()
      .min(5, "Too short!")
      .required("Required"),
    jobAddrCity: Yup.string().min(1, "Too short!").required("Required"),
    jobAddrState: Yup.string()
      .length(2, "Please use a valid state abbreviation")
      .required("Required"),
    jobAddrZipCode: Yup.string().min(4, "Too short!").required("Required"),
    salaryRange: Yup.string(),
    degreeRequirements: Yup.string().required("Required"),
    dateAppEndsAt: Yup.date().required("Required"),
    description: Yup.string().required("Required"),
    contactName: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required"),
    contactPhone: Yup.string(),
  });

  return (
    <div className="pt-2 max-w-7xl mx-auto">
      <Formik
        initialValues={{
          companyName: "",
          companyWebsite: "",
          title: "",
          jobAddrStreetAddress: "",
          jobAddrCity: "",
          jobAddrState: "",
          jobAddrZipCode: "",
          salaryRange: "",
          degreeRequirements: "",
          dateAppEndsAt: "",
          description: "",
          contactName: "",
          contactEmail: "",
          contactPhone: "",
        }}
        validationSchema={formValidationSchema}
        onSubmit={(values: JobPostFormType) => {
          toast.remove();
          postJobPostForm(values)
            .unwrap()
            .then(() => {
              navigate("/", { replace: false });
              toastSuccess(
                "Your submitted job ad has been received and will be processed and approved as soon as possible!"
              );
            })
            .catch((error) => {
              toastError(
                "There was an issue submitting your form. Please reach out to support@sicklecellcenters.org for help."
              );
            });
        }}
      >
        {({
          values,
          isValid,
          dirty,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form className="space-y-8 divide-y divide-gray-200">
            <fieldset className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              {/* Company Info */}
              <div>
                {/* Company Info Title */}
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Company/Institution Information
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Please fill out information about your company/institution.
                  </p>
                </div>

                {/* Company Info Fields */}
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  {/* Company Name */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Company/Institution Name*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.companyName && errors.companyName ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.companyName}
                        </div>
                      ) : null}
                      <div className="flex rounded-md shadow-sm w-2/3">
                        <Field
                          type="text"
                          name="companyName"
                          id="company-name"
                          className="flex-1 block w-2/3 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Company Website */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="companyWebsite"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Company/Institution Website*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.companyWebsite && errors.companyWebsite ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.companyWebsite}
                        </div>
                      ) : null}
                      <div className="flex rounded-md shadow-sm w-2/3">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                          https://
                        </span>
                        <Field
                          type="text"
                          name="companyWebsite"
                          id="company-website"
                          value={values.companyWebsite}
                          className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Job Info */}
              <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                {/* Job Info Title */}
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Job Information
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Please fill out information about the job that you would
                    like to publicly share.
                  </p>
                </div>

                {/* Job Info Fields */}
                <div className="space-y-6 sm:space-y-5">
                  {/* Job Title Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Job Title*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.title && errors.title ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.title}
                        </div>
                      ) : null}
                      <Field
                        type="text"
                        name="title"
                        id="job-title"
                        className="block w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job Street Address Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Street Address*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.jobAddrStreetAddress &&
                      errors.jobAddrStreetAddress ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.jobAddrStreetAddress}
                        </div>
                      ) : null}

                      <Field
                        type="text"
                        name="jobAddrStreetAddress"
                        id="street-address"
                        autoComplete="street-address"
                        className="block w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job City Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      City*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.jobAddrCity && errors.jobAddrCity ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.jobAddrCity}
                        </div>
                      ) : null}
                      <Field
                        type="text"
                        name="jobAddrCity"
                        id="city"
                        autoComplete="address-level2"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job State Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      State / Province*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.jobAddrState && errors.jobAddrState ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.jobAddrState}
                        </div>
                      ) : null}
                      <Field
                        type="text"
                        name="jobAddrState"
                        id="state"
                        autoComplete="address-level1"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job Zip Code Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      ZIP / Postal Code*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.jobAddrZipCode && errors.jobAddrZipCode ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.jobAddrZipCode}
                        </div>
                      ) : null}
                      <Field
                        type="text"
                        name="jobAddrZipCode"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job Salary Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="salary-range"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Salary Range - i.e. $90,000 - $100,000 (Optional)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.salaryRange && errors.salaryRange ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.salaryRange}
                        </div>
                      ) : null}
                      <Field
                        type="text"
                        name="salaryRange"
                        id="salary-range"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job Degree Requirements Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="degree-requirements"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Degree Requirements* (i.e. MD, DO, Board Certified)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.degreeRequirements &&
                      errors.degreeRequirements ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.degreeRequirements}
                        </div>
                      ) : null}

                      <Field
                        type="text"
                        name="degreeRequirements"
                        id="degree-requirements"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  {/* Job Application Ends At Field */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="date-ends-at"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Date Application Period Ends*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.dateAppEndsAt && errors.dateAppEndsAt ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.dateAppEndsAt}
                        </div>
                      ) : null}
                      <DatePicker
                        id="date-app-ends-at"
                        name="dateAppEndsAt"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown={false}
                        dateFormat="MMMM d, yyyy"
                        selected={
                          typeof values.dateAppEndsAt === "string" &&
                          values.dateAppEndsAt !== ""
                            ? parseISO(values.dateAppEndsAt)
                            : values.dateAppEndsAt === ""
                            ? new Date()
                            : typeof values.dateAppEndsAt === "string"
                            ? parse(
                                values.dateAppEndsAt,
                                "MMMM d, yyyy",
                                new Date()
                              )
                            : values.dateAppEndsAt
                        }
                        onChange={(date) => {
                          if (date instanceof Date) {
                            setFieldValue("dateAppEndsAt", date);
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched("dateAppEndsAt", true, true);
                        }}
                        className="focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-200 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Job Description */}
              <div className="pt-5">
                <div className="pb-4">
                  <h3 className="text-xl leading-6 font-bold text-gray-900">
                    Job Description*
                  </h3>
                  <p className="mt-1 text-base text-gray-500 pb-2">
                    Please describe the job you are posting for. You can use{" "}
                    <a
                      href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
                      className="link-text"
                    >
                      Markdown
                    </a>{" "}
                    to ensure your description looks great. The buttons on the
                    toolbar at the top of the box will add in the characters
                    needed to perform the action. For example, bolding text
                    requires two *'s before and after the word or words you
                    intend to bold. The box on the right will let you see a
                    preview of how your description will look on the site.
                  </p>
                  <MDEditor
                    id="md-editor"
                    value={values.description}
                    onChange={(value) => {
                      if (value || value === "") {
                        setFieldValue("description", value);
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("description", true, true);
                    }}
                    height={400}
                  />
                </div>
              </div>

              {/* Contact Info */}
              <div className="pt-4">
                {/* Contact Info Title */}
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Contact Information
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Please provide contact information for your job post.
                  </p>
                </div>

                {/* Contact Info Fields */}
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  {/* Contact Name */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="contact-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Contact Name*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex rounded-md shadow-sm w-2/3">
                        {touched.contactName && errors.contactName ? (
                          <div
                            className="font-medium text-sm text-red-600"
                            data-testid="company-website-error"
                          >
                            {errors.contactName}
                          </div>
                        ) : null}
                        <Field
                          type="text"
                          name="contactName"
                          id="contact-name"
                          className="flex-1 block w-2/3 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Contact Email */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="contact-email"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Contact Email*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex rounded-md shadow-sm w-2/3">
                        {touched.contactEmail && errors.contactEmail ? (
                          <div
                            className="font-medium text-sm text-red-600"
                            data-testid="company-website-error"
                          >
                            {errors.contactEmail}
                          </div>
                        ) : null}
                        <Field
                          type="text"
                          name="contactEmail"
                          id="contact-email"
                          className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Contact Phone Number */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="contact-phone"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Contact Phone Number (Optional)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {touched.contactPhone && errors.contactPhone ? (
                        <div
                          className="font-medium text-sm text-red-600"
                          data-testid="company-website-error"
                        >
                          {errors.contactPhone}
                        </div>
                      ) : null}
                      <div className="flex rounded-md shadow-sm w-2/3">
                        <Field
                          type="text"
                          name="contactPhone"
                          id="contact-phone"
                          className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <button
              type="submit"
              className="w-full ml-auto flex items-center justify-center px-4 py-3 border border-transparent text-base self-end justify-self-end
                  font-medium rounded-md text-white bg-indigo-500 xl:h-12 xl:w-3/12 hover:bg-indigo-400 focus:outline-none focus:ring-2
                  focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!(dirty && isValid)}
            >
              Submit Form
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
