/** @module Components */
import React from "react";
import { Helmet } from "react-helmet-async";

export const TemporaryMessage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Message</title>
        <meta
          name="description"
          content="A temporary message for the NASCC website."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="NASCC, message, sicklecell disease, sicklecell,
          pfizer, voxeletor, oxybryta"
        />
      </Helmet>
      <main className="static-page-main">
        <>
          <h2 className="static-page-header">
            Message from NASCC on Pfizer's Decision to Withdraw Oxbryta
            (Voxeletor)
          </h2>
          <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
            <div className="prose-text">
              This news is disappointing for sure and disheartening for many!
              However, we are glad that Pfizer is making this decision to better
              evaluate this medication and identify which people with sickle
              cell disease may benefit. At this time, the risks are too great
              but there may be an opportunity in the future to better understand
              how to optimize this medication (and all) medications in sickle
              cell disease. These types of issue is why clinical registries like
              GRNDaD (used by NASCC) and long-term follow-up with a sickle cell
              center is so important.{" "}
              <strong>
                We encourage everyone to see a sickle cell specialist annually
                (at a minimum) to review what is and what is not working for
                them
              </strong>
              . We all know that sickle cell disease is highly variable, many
              people are different and respond differently to medications. We
              need to better understand these differences to identify which
              medication will work best for which people.
              <br />
              <br />
              <strong>
                For now, we urge all people with sickle cell disease currently
                taking Oxbryta to make an appointment with your doctor. DO NOT
                STOP the drug abruptly. Work out a weaning plan with your
                doctor/care team. Don’t lose faith-this a step backward but we
                will stay on the path to better outcomes for everyone.
              </strong>
              <br />
              <br />
              Reference:{" "}
              <a
                href="https://www.pfizer.com/news/press-release/press-release-detail/pfizer-voluntarily-withdraws-all-lots-sickle-cell-disease"
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                the announcement from Pfizer.
              </a>
            </div>
          </section>
          <h2 className="static-page-header">
            Further Resources and Statements
          </h2>
          <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
            <div className="prose-text">
              On 9/27/2024,{" "}
              <strong>the National Alliance of Sickle Cell Centers</strong>{" "}
              (NASCC), in partnership with{" "}
              <strong>
                <a
                  href="https://www.sicklecelldisease.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-text"
                >
                  the Sickle Cell Disease Association of America
                </a>
              </strong>{" "}
              (SCDAA) held two webinars to discuss the above matter. You can
              watch both webinar sessions at the links below. Note: the first
              webinar was a live presentation with Q&A, and the second was a
              viewing of the recording of the first with additional live Q&A. In
              order to save time, the second video has been trimmed to just
              include the Q&A portion.
              <br />
              <strong>Webinar 1:</strong>{" "}
              <a
                href="https://youtu.be/wP3hsXY8qQ8"
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                NASCC/SCDAA Webinar 1 on YouTube
              </a>
              <br />
              <strong>Webinar 2:</strong>{" "}
              <a
                href="https://youtu.be/FfIzhbL4n4Y"
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                NASCC/SCDAA Webinar 2 on YouTube
              </a>
            </div>
            <br />
            <br />
            <div className="prose-text">
              The MARAC Statement from the Sickle Cell Disease Association of
              America can be found{" "}
              <a
                href="https://www.sicklecelldisease.org/2024/09/28/marac-statement-oxbryta/"
                className="link-text"
              >
                at this link.
              </a>
              <br />
              You can also find the MARAC statement from SCDAA for{" "}
              <strong>pharmacists</strong>
              at{" "}
              <a
                href="https://www.dropbox.com/scl/fi/h6ubt8c1u7hdjvo1lfiuq/SCDAA-MARAC-for-Pharmacists-9-28-24.docx?rlkey=ie6s8h5kkwekhgg4vvrt0i1wc&st=dn1bnfh7&dl=0"
                className="link-text"
              >
                this link.
              </a>
            </div>
          </section>
        </>
      </main>
    </>
  );
};
