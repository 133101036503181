import React from "react";
import { Field } from "formik";

export const CenterInformationForm: React.FC = () => {
  // const { values } = useFormikContext<CenterApplicationFormType>();
  // const { touched } =
  //   useFormikContext<FormikTouched<CenterApplicationFormType>>();
  // const { errors } =
  //   useFormikContext<FormikErrors<CenterApplicationFormType>>();
  // const { setFieldValue } = useFormikContext();

  return (
    <div className="bg-white shadow px-4 py-5 border border-gray-200 sm:rounded-lg sm:p-6 md:shadow-xl">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Basic Center Information
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please fill in the requested information about your sickle cell
            disease center. All fields outlined in red or otherwise indicated
            are required.
          </p>
        </div>
        <fieldset
          className="mt-5 md:mt-0 md:col-span-2"
          id="center-details-fieldset"
        >
          <div className="grid grid-cols-6 gap-6">
            {/* Center Name */}
            <div className="col-span-6 sm:col-span-6">
              <label
                htmlFor="center-name"
                className="block text-sm font-medium text-gray-700"
              >
                Center Name*
              </label>
              <Field
                type="text"
                name="centerName"
                id="center-name"
                autoComplete="off"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>

            {/* Center Address */}
            <div className="col-span-6">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700"
              >
                Street address*
              </label>
              <Field
                type="text"
                name="streetAddress"
                id="street-address"
                autoComplete="street-address"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City*
              </label>
              <Field
                type="text"
                name="city"
                id="city"
                autoComplete="address-level2"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700"
              >
                State / Province*
              </label>
              <Field
                type="text"
                name="state"
                id="region"
                autoComplete="address-level1"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700"
              >
                ZIP / Postal code*
              </label>
              <Field
                type="text"
                name="zipCode"
                id="postal-code"
                autoComplete="postal-code"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>

            {/* Director Name and Email */}
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="director-first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Director's First Name*
              </label>
              <Field
                type="text"
                name="directorFirstName"
                id="director-first-name"
                autoComplete="given-name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="directorLastName"
                className="block text-sm font-medium text-gray-700"
              >
                Director's Last Name*
              </label>
              <Field
                type="text"
                name="directorLastName"
                id="director-last-name"
                autoComplete="family-name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <label
                htmlFor="directorEmailAddress"
                className="block text-sm font-medium text-gray-700"
              >
                Director's Email Address*
              </label>
              <Field
                type="text"
                name="directorEmail"
                id="director-email-address"
                autoComplete="email"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            {/* Co-director Name and Email */}
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="co-director-first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Co-Director's First Name{" "}
                <span className="text-gray-400 italic">(Optional)</span>
              </label>
              <Field
                type="text"
                name="coDirectorFirstName"
                id="co-director-first-name"
                autoComplete="given-name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="co-director-last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Co-Director's Last Name{" "}
                <span className="text-gray-400 italic">(Optional)</span>
              </label>
              <Field
                type="text"
                name="coDirectorLastName"
                id="co-director-last-name"
                autoComplete="family-name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <label
                htmlFor="co-director-email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Co-Director's Email Address{" "}
                <span className="text-gray-400 italic">(Optional)</span>
              </label>
              <Field
                type="text"
                name="coDirectorEmail"
                id="co-director-email-address"
                autoComplete="email"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            {/* Primary Contact Info */}
            <div className="col-span-6 sm:col-span-5">
              <label
                htmlFor="co-director-email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Primary Contact Email*
              </label>
              <Field
                type="text"
                name="primaryCenterContactEmail"
                id="co-director-email-address"
                autoComplete="email"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                aria-describedby="primary-contact-email-help-text"
              />
              <p
                className="mt-2 text-sm text-gray-500"
                id="primary-contact-email-help-text"
              >
                This should be the email of the person who should receive
                important correspondence for the center.
              </p>
            </div>

            {/* Center Website */}
            <div className="col-span-6 sm:col-span-5">
              <label
                htmlFor="center-website"
                className="block text-sm font-medium text-gray-700"
              >
                Center Website*
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  https://
                </span>
                <Field
                  type="text"
                  name="websiteAddress"
                  id="center-website"
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                  placeholder="www.example.com"
                  required
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
