/** @modules Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { ConsensusDetailsColumns } from "../../components/Shared/Columns";
import { Table } from "../../components";
import { ConsensusRecommendationsType } from "../ConsensusResources/ConsensusRecommendations";

export const ConsensusRecommendationGeneTherapy: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState("1000");
  const tableData: ConsensusRecommendationsType[] = [
    {
      title: "Personnel Requirements for Transformative-SCD Therapy Centers",
      text: "All transformative therapy centers should have the following multidisciplinary team members involved in the care of individuals with SCD who undergo gene therapy available at their center (part of their multi-disciplinary team): hematologist with SCD experience, hematopoietic stem cell transplant (HSCT) physician, transfusion medicine physician, pulmonologist with SCD experience, cardiologist with SCD experience, nephrologist with SCD experience, infectious disease specialist with SCD experience, psychologist with dedicated FTE for people with SCD, ophthalmologist, surgery/interventional radiology for line placement, pharmacist, hematopathologist, psychologist who will see individuals with SCD, dedicated SCD social worker with dedicated time (FTE) for transformative therapy, SCD patient navigator, transplant coordinator.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Personnel Requirements for Transformative-SCD Therapy Centers",
      text: "All transformative therapy centers should have the following multidisciplinary team members involved in the care of individuals with SCD who undergo gene therapy available at their center: psychiatrist willing to work with people with sickle cell disease, pain medicine specialist with SCD experience, nutritionist with transplant experience.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Personnel Requirements for Transformative-SCD Therapy Centers",
      text: "All transformative therapy centers should have the following multidisciplinary team members involved in the care of individuals with SCD who undergo gene therapy available (not necessarily at their center but able to see and be involved with the patient): fertility specialist (gynecologist) for women, fertility specialist (urologist) for men, dentist.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Experience Required to be a Transformative-SCD Therapy Center",
      text: "All transformative therapy centers should have an established sickle cell center (pediatric or adult specific) either co-located at the same institution or in a formalized partnership prior to initiation of treatment.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Experience Required to be a Transformative-SCD Therapy Center",
      text: "All transformative therapy centers should have an established transplant program with experience transplanting individuals with hemoglobin disorders.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Experience Required to be a Transformative-SCD Therapy Center",
      text: "All transformative therapy centers should have a blood bank with experience transfusing individuals with SCD including phenotype-antigen matching consistent with the ASH guidelines.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Experience Required to be a Transformative-SCD Therapy Center",
      text: "All transformative therapy centers should have experience treating sickle-related pain (pediatric and adult specific).",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Experience Required to be a Transformative-SCD Therapy Center",
      text: "All transformative therapy centers should have the ability and experience to measure Busulfan pharmacokinetics (in-house or send-out).",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have an identified SCD center to follow patients for 15 years in compliance with the FDA guidelines for long-term follow-up after administration of a gene therapy product. For pediatric centers, a mechanism or collaboration with an adult center should be also in place.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have a cellular processing facility.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have FACT accreditation for autologous products.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All qualified transformative therapy centers should have the ability to perform cytogenetics, FISH, and karyotyping on bone marrow specimens.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have in house hemoglobin quantification (HbA/HbS/HbF).",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have the following imaging/testing modalities available: Brain MRI and MRA, Transcranial Doppler Ultrasound (Sickle Stroke Screen), Liver Iron Imaging: MR Ferriscan or other quantitative iron measurement, Echocardiogram, Pulmonary Function Tests.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "The Processes/Procedures/Approvals Required for Transformative Therapy Centers",
      text: "All transformative therapy centers should have access to sperm banking, ovarian/egg collection, and cryopreservation (It does not have to be co-located but a plan/partnership must be in place).",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "A SCD specialist should lead the consent process however a transplant physician should consent the patient for the autologous transplant process.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "A SCD specialist should manage a patient's sickle cell disease and related transfusions in the pre-transplant period.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "A SCD specialist should manage the apheresis process pre-collection. An HSCT specialist should manage the actual stem cell collection.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "A SCD-Transplant coordinator should manage/coordinate the transplant work-up.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "A monthly, multi-disciplinary meeting should be planned for 'final review' for each patient prior to approval for transplant (autologous gene therapy or allogeneic transplant) that must include a SCD specialist, HSCT specialist, fertility (if needed), transfusion medicine, and psychology.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Pre-transplant and Consent Management for Transformative Therapies at Transformative-SCD Therapy Centers",
      text: "Patients require a pre-transformative therapy mental health assessment by a psychologist with experience working with individuals with SCD.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "All qualified transformative therapy centers should have access to housing (or a plan for housing) for non-local patients with SCD who undergo allotransplant and gene therapy.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "A specialized long-term follow-up clinic (Transformative Care Clinic) dedicated to individuals with SCD who underwent allogeneic or autologous transplant should exist and be managed by a SCD physician. BMT physicians should be involved, managing complications and immune modulation after allogeneic transplantation, in addition to collaborative care with the SCD physician.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "In the first 100 days post-transplant, BMT physicians should manage acute transplant related complications, transfusions, immune suppression, etc.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "SCD physicians should manage pain post-transformative therapy (allotransplant and autologous-gene therapy).",
      typeOfRecommendation: "Recommendation",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "At this time, all patients should continue follow-up post-gene therapy with a SCD center (even if different from their originating hematologist).",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "In the event of graft failure/rejection, patients should return to their SCD specialist/center for ongoing SCD care.",
      typeOfRecommendation: "Standard",
    },
    {
      title:
        "Post-transplant Management for People with SCD Who Receive Transformative Therapies at Transformative-SCD Centers",
      text: "Initial and ongoing psychologist treatment/evaluation as needed post-transplant.",
      typeOfRecommendation: "Standard",
    },
  ];

  const memoizedConsensusColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(ConsensusDetailsColumns, []);

  React.useEffect(() => {
    const firstHeader = document.getElementById("header-1")?.closest("th");
    const secondHeader = document.getElementById("header-2")?.closest("th");
    const thirdHeader = document.getElementById("header-3")?.closest("th");
    if (firstHeader) {
      firstHeader.style.width = "20% important!";
    }
    if (secondHeader) {
      secondHeader.style.width = "70%";
    }
    if (thirdHeader) {
      thirdHeader.style.width = "10%";
    }
  });

  return (
    <>
      <Helmet>
        <title>NASCC - Transformative SCD Center Recommendations</title>
        <meta
          name="description"
          content="NASCC consensus recommendations for Transformative Sickle Cell Diseas therapy centers gathered from
          the NASCC annual meeting."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <section className="static-page-section mt-10">
          <h3 className="static-page-header pl-8">
            Transformative SCD Center Recommendations
          </h3>
          <p className="pl-8 pt-2 pb-8">
            There is a link to download the recommendation document at the
            bottom of the page.
          </p>
          <Table
            columns={memoizedConsensusColumns}
            data={tableData}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={false}
            headerCellClassName="pl-4"
            customRowCellClassName="pl-4 whitespace-normal"
            searchable={true}
          />
        </section>
        <section className="static-page-section mt-10">
          <h3 className="static-page-header">
            Download the Consensus Recommendations
          </h3>
          <ul className="list-disc pl-6">
            <li>
              <Link
                to="https://www.dropbox.com/scl/fi/co1oc07u4jo02gi3drslc/2024-NASCC-Consensus-Gene-Therapy-Recommendations.pdf?rlkey=0zlugtf3bqtdxesz5i9st2szg&st=mw345coi&dl=0"
                className="link-text"
              >
                {" "}
                Transformative SCD Center Recommendations
              </Link>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};
