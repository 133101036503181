/** @module Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";
import { ScaleIcon } from "@heroicons/react/24/outline";

import { useGetCenterApplicationsInfoQuery } from "../../services/applicationService";
import { Table } from "../../components";
import { SharedApplicationColumns } from "../../components/Shared/Columns";
import { CombinedCenterDetailsType } from "../../types";
import { getUnixTime, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../shared/Functions";

export const MembersHome: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10",
  );

  const {
    data: pendingCenterApplications,
    isLoading: pendingIsLoading,
    isFetching: pendingIsFetching,
    isError: pendingIsError,
  } = useGetCenterApplicationsInfoQuery("pending");

  const {
    data: approvedCenterApplications,
    isLoading,
    isFetching,
    isError,
  } = useGetCenterApplicationsInfoQuery("accepted");

  const sortedData =
    !isLoading &&
    !isFetching &&
    !isError &&
    approvedCenterApplications &&
    [...approvedCenterApplications].sort(
      (a: CombinedCenterDetailsType, b: CombinedCenterDetailsType) => {
        if (b.secondApprovalAt && a.secondApprovalAt) {
          return (
            getUnixTime(parseISO(b.secondApprovalAt)) -
            getUnixTime(parseISO(a.secondApprovalAt))
          );
        } else {
          return 0;
        }
      },
    );

  const memoizedSharedApplicationColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(SharedApplicationColumns, []);

  return (
    <main>
      <div className="mt-8">
        {/* Pending Application Cards */}
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Top 3 Pending Applications
          </h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {!pendingIsLoading &&
              !pendingIsFetching &&
              !pendingIsError &&
              pendingCenterApplications &&
              pendingCenterApplications.map((pendingApplication) => (
                <div
                  key={pendingApplication.centerName}
                  className="bg-white overflow-hidden shadow rounded-lg"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <ScaleIcon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-base font-medium text-gray-500 truncate">
                            {pendingApplication.centerName}
                          </dt>
                          <dd>
                            <div className="text-lg font-medium text-gray-900">
                              {pendingApplication.centerType &&
                                toTitleCase(pendingApplication.centerType)}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-5 py-3">
                    <div className="text-sm">
                      <Link
                        to={`/members/center_application/${pendingApplication.id}`}
                        className="font-medium text-cyan-700 hover:text-cyan-900"
                      >
                        View Application
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <h2 className="max-w-8xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 pb-4 sm:px-6 lg:px-8">
          Recently Approved Applications
        </h2>

        {/* Activity table (small breakpoint and up) */}
        <div className="flex flex-col">
          {!isError && approvedCenterApplications && (
            <Table
              columns={memoizedSharedApplicationColumns}
              // @ts-ignore
              data={sortedData}
              selectedPageSize={selectedPageSize}
              setSelectedPageSize={setSelectedPageSize}
              fetching={isFetching || isLoading}
              headerCellClassName="pl-4"
              customRowCellClassName="pl-4"
            />
          )}
        </div>
      </div>
    </main>
  );
};
