import React from "react";

import { Footer } from "../Footer/Footer";
import { Helmet } from "react-helmet-async";

export const ConflictOfInterest: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NASCC - Conflict of Interest Policy</title>
        <meta
          name="description"
          content="Contains the conflict of interest policy for NASCC in a downloadble form."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h3 className="static-page-header">Conflict of Interest Policy</h3>
        <section className="static-page-section">
          <p className="prose-text">
            Download the NASCC Conflict of Interest Policy{" "}
            <a
              href="https://www.dropbox.com/scl/fi/eiju4qrd3oiipkipmnu2d/NASCC-COI-Policy-FDP-2024.pdf?rlkey=91f2wvt5dw6wslap22fy334ln&st=nlv14ekf&dl=0"
              className="link-text"
            >
              here
            </a>
            .
          </p>
        </section>
      </main>
      <Footer dataTestId="footer_740a81ba-7201-4e48-8deb-1246cbe5e65d" />
    </>
  );
};
