import React from "react";
import { LogoGrid } from "../LogoGrid/LogoGrid";

export const Sponsors: React.FC = () => {
  return (
    <section className="flex items-center">
      <div className="bg-gray-200 bg-opacity-25">
        <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="pt-10 2xl:py-16 2xl:grid 2xl:grid-cols-3 2xl:gap-2">
            {/* Sponsor text */}
            <div className="mx-auto my-auto text-3xl font-bold text-center text-gray-800 2xl:text-left 2xl:col-span-3">
              Thanks to our <b>amazing sponsors</b> for providing their support
              and helping NASCC provide the best treatments, techniques, and
              knowledge to centers around the nation. If you would like to
              become a corporate sponsor and have your logo and profile featured
              on our site, please{" "}
              <a
                href="mailto:sponsors@sicklecellcenters.org"
                className="link-text"
              >
                contact us
              </a>
              .
            </div>
            <div className="2xl:col-span-3">
              <div className="flex flex-col">
                <LogoGrid sponsorshipType="platinum" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
