import React from "react";

export const GrndadSpeakerSeries: React.FC = () => {
  return (
    <section className="static-page-section mt-10" id="speaker_series">
      <h3 className="static-page-header">GRNDaD Speaker Series</h3>
      <p>
        This is a monthly virtual sickle cell disease conference for clinical
        and basic science leaders in SCD. To initiate a connection and receive
        notifications for this series, please subscribe to the mailing list
        using the following format:
      </p>
      <br />
      <p>
        Send an email to{" "}
        <a href="mailto:sympa@lists.johnshopkins.edu" className="link-text">
          sympa@lists.johnshopkins.edu
        </a>
        , with a <strong>subject line</strong> as follows:{" "}
        <em>subscribe scdspeakerseries</em>. Please note that the subject line
        should be the aforementioned two words, without a period, and should{" "}
        <strong>not</strong> be in the body of the email.
      </p>
      <br />
      <p>Please scroll the list down to see past speakers and sessions.</p>
      <div className="overflow-hidden w-full h-[700px] mt-12">
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTgLvNd5LGC6z_qJ1wtd7khhQsk4AineGUibYQdfiVAeDQh-UB_IhYvTh3rBsvM5WpKzRLjnb3CdUze/pubhtml?gid=0&amp;single=true&amp;widget=false&amp;headers=false&amp;range=A%3AE&amp;chrome=false"
          width="100%"
          height="100%"
          title="GRNDaD Speaker Series"
          className="border-0"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </section>
  );
};
