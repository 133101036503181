import { ChangeEventHandler } from "react";

/** @category Prop Types */
export type InputWithOverlappingLabelProps = {
  inputLabel: string;
  placeholderText?: string;
  className?: string;
  inputClassName?: string;
  inputOnChange?: ChangeEventHandler<HTMLInputElement> | undefined;
};

export const InputWithOverlappingLabel: React.FC<
  InputWithOverlappingLabelProps
> = ({
  inputLabel,
  placeholderText,
  className,
  inputClassName,
  inputOnChange,
}: InputWithOverlappingLabelProps) => {
  return (
    <div className={"relative " + className}>
      <label
        htmlFor={inputLabel.toLowerCase()}
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        {inputLabel}
      </label>
      <input
        type="text"
        name={inputLabel.toLowerCase()}
        id={inputLabel.toLowerCase()}
        className={
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
          "placeholder:text-gray-400 !focus:ring-2 !focus:ring-inset !focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
          inputClassName
        }
        onChange={
          inputOnChange
            ? (e) => {
                return inputOnChange(e);
              }
            : (e) => {
                return e;
              }
        }
        placeholder={placeholderText ? placeholderText : inputLabel}
      />
    </div>
  );
};
