/** @module Components */

import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useAsyncDebounce } from "react-table";

type GlobalFilterProps = {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
};

export const SearchBar: React.FC<GlobalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState(globalFilter);

  const handleInput = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  });

  return (
    <div
      className="flex flex-col justify-center items-center static w-1/5 h-full ml-8 pb-5"
      data-testid="search-bar-container"
    >
      <div className="w-full flex border border-gray-200 rounded-lg">
        <label
          htmlFor="search-field"
          className="sr-only"
          id="screen-reader-search-label"
        >
          Search
        </label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-700">
          <div
            className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-2"
            aria-hidden="true"
          >
            <MagnifyingGlassIcon
              className="h-5 w-5"
              aria-hidden="true"
              id="search-icon"
              data-testid="search-icon"
            />
          </div>
          <input
            id="search-field"
            name="search_field"
            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-400 bg-white rounded-lg focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder="Search..."
            value={searchValue || ""}
            onChange={(event) => {
              setSearchValue(event.target.value);
              handleInput(event.target.value);
            }}
            type="search"
            data-testid="search-input"
          />
        </div>
      </div>
    </div>
  );
};
