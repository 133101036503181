import React from "react";

import JulieHeadshot from "../../assets/images/Julie_Kanter.jpg";
import DeepaHeadshot from "../../assets/images/Deepa_Manwani.jpg";
import SophieHeadshot from "../../assets/images/Sophie_Lanzkron.jpg";
import PayalHeadshot from "../../assets/images/Grndad-Payal-Desai.jpg";
import JaneHeadshot from "../../assets/images/Grndad-Jane-Little.jpg";

export const GrndadExecutiveCommittee: React.FC = () => {
  const executiveCommittee = [
    {
      name: "Sophie Lanzkron",
      title: "M.D., M.H.S. Professor of Medicine",
      jobDescription:
        "Director, Sickle Cell Center for Adults at Johns Hopkins University.",
      photo: SophieHeadshot,
    },
    {
      name: "Jane Little",
      title: "M.D. Professor of Medicine",
      jobDescription:
        "Director, UNC Comprehensive Sickle Cell Disease program.",
      photo: JaneHeadshot,
    },
    {
      name: "Payal C. Desai",
      title:
        "M.D. Assistant Professor of Internal Medicine, Division of Hematology",
      jobDescription:
        "Director of Sickle Cell Research for the Ohio State university and co-director of the Sickle Cell Program.",
      photo: PayalHeadshot,
    },
    {
      name: "Deepa G. Manwani",
      title: "M.D., Pediatric Hematology-Oncology",
      jobDescription:
        "Director, Pediatric Hematology at Montefiore and Professor in the Department of Pediatrics at Albert " +
        "Einstein College of Medicine.",
      photo: DeepaHeadshot,
    },
    {
      name: "Julie Kanter",
      title: "M.D. Associate Professor of Medicine",
      jobDescription:
        "Co-director of the Comprehensive Sickle Cell Center at UAB.",
      photo: JulieHeadshot,
    },
  ];

  return (
    <section className="static-page-section mt-10" id="executive_committee">
      <h3 className="static-page-header">GRNDaD Executive Committee</h3>
      <div className="">
        <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
          {executiveCommittee.map((officer, index) => {
            return (
              <li>
                <div className="grid grid-cols-3 items-start gap-x-8 align-middle">
                  <div className="col-span-1">
                    <img
                      src={officer.photo}
                      alt={`Headshot for ${officer.name}`}
                      className="rounded-lg shadow-2xl object-cover aspect-3/4"
                    />
                  </div>
                  <div className="col-span-2 space-y-2">
                    <h3 className="text-lg leading-6 font-medium">
                      Dr. {officer.name}
                    </h3>
                    <p className="text-lg leading-6 font-medium text-indigo-600">
                      {officer.title}
                    </p>
                    <p className="text-lg text-gray-500">
                      {officer.jobDescription}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
