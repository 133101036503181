/** @module Containers */
import React, { Fragment, useEffect } from "react";
import {
  ChartBarIcon,
  CursorArrowRaysIcon,
  InformationCircleIcon,
  Bars3Icon,
  PhoneIcon,
  ShieldCheckIcon,
  UsersIcon,
  GlobeAmericasIcon,
  XMarkIcon,
  GlobeAltIcon,
  ClipboardIcon,
  UserGroupIcon,
  PhotoIcon,
  BookmarkSquareIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import NavBarLogo from "../../assets/images/navBarLogo.jpg";

const nascc_resources = [
  {
    name: "Publications",
    description: "Read about work on Sickle Cell Disease around the world.",
    href: "publications",
    icon: ChartBarIcon,
  },
  // {
  //   name: "Educational Resources",
  //   description: "Learn more about Sickle Cell Disease and how to manage it.",
  //   href: "educational_resources",
  //   icon: CursorArrowRaysIcon,
  // },
  {
    name: "Start a Center",
    description: "Information on how to start a Sickle Cell Center.",
    href: "start_a_center",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Provider Resources",
    description:
      "Resources focused on providers, with links to tools to help your patients.",
    href: "provider_resources",
    icon: ShieldCheckIcon,
  },
  {
    name: "Job Postings",
    description: "Jobs posted by our member centers around the country!",
    href: "jobs",
    icon: ClipboardIcon,
  },
];

const centers = [
  {
    name: "Member Centers",
    description: "Map of our Member centers and their specialities.",
    href: "member_centers",
    icon: UserGroupIcon,
  },
  {
    name: "Associate Member Centers",
    description: "Map of our Associate Member Centers and their specialties.",
    href: "associate_centers",
    icon: UserGroupIcon,
  },
  {
    name: "Center Membership Criteria",
    description: "Elements needed to join NASCC as a member.",
    href: "center_criteria",
    icon: ClipboardIcon,
  },
  {
    name: "Member Logo Guidelines",
    description: "Guidelines on how you can use the NASCC member logo.",
    href: "logo_guidelines",
    icon: PhotoIcon,
  },
];
const mobile_menu_options = [
  {
    name: "Member Centers",
    description: "",
    href: "member_centers",
    icon: GlobeAltIcon,
  },
  {
    name: "Consensus Recommendations",
    description: "",
    href: "consensus_recommendations/general",
    icon: BookmarkSquareIcon,
  },
];
const callsToAction = [
  { name: "Contact NASCC", href: "contact_info", icon: PhoneIcon },
];
const about_us = [
  {
    name: "About NASCC",
    description: "Information about NASCC and it's mission.",
    href: "about_us",
    icon: InformationCircleIcon,
  },
  {
    name: "Executive Committee",
    description: "Information and biographies of NASCC's executive committee",
    href: "executive_committee",
    icon: UsersIcon,
  },
  {
    name: "Our Partners",
    description: "Information about NASCC's partner organizations.",
    href: "our_partners",
    icon: GlobeAmericasIcon,
  },
  {
    name: "Contact Us",
    description: "Contact information for NASCC",
    href: "contact_info",
    icon: PhoneIcon,
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const NavHeader: React.FC = () => {
  const [matchesMediaQuery, setMatchesMediaQuery] = React.useState(false);

  // Create a hook that changes the state of the matchesMediaQuery variable if the window resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1280 && window.innerWidth < 1385) {
        setMatchesMediaQuery(true);
      } else {
        setMatchesMediaQuery(false);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Popover className="relative bg-white">
      {/* Larger screens */}
      <div
        className="absolute inset-0 shadow z-30 pointer-events-none"
        aria-hidden="true"
      />
      <div className="relative z-20">
        <div className="mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 lg:justify-between lg:space-x-2">
          {/* Logo and name */}
          <div data-testid="logo-and-name-container-non-mobile">
            <Link to="/" className="flex" style={{ minWidth: "6rem" }}>
              <span className="sr-only">NASCC Logo</span>
              <img
                className="h-20 w-auto"
                src={NavBarLogo}
                alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
              />
            </Link>
          </div>
          {/* Menu options and buttons */}
          <div
            className="flex-1 flex items-center justify-end"
            data-testid="menu-options-and-buttons-container"
          >
            {/* Menu options */}
            <Popover.Group
              as="nav"
              className="space-x-5 hidden xl:flex xl:pl-4 xl:space-x-3 xl:items-center"
            >
              {/* Current Programs */}
              {/*<Link to="consensus_meeting" className="nav-header-text">*/}
              {/*  Programs |*/}
              {/*</Link>*/}
              {/* Consensus Recommendations */}
              <Link
                to="consensus_recommendations/general"
                className="nav-header-text"
              >
                {!matchesMediaQuery && "Consensus Recommendations |"}
                {matchesMediaQuery && "Recommendations |"}
              </Link>
              {/* Centers */}
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                      )}
                    >
                      <span
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "nav-header-text",
                        )}
                      >
                        Centers
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "h-8 w-8 group-hover:text-gray-500",
                        )}
                        aria-hidden="true"
                      />
                      <span className="nav-header-text">|</span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        {/* Resources */}
                        <div className="mx-auto grid gap-y-6 px-2 py-2 sm:grid-cols-2 sm:gap-8 sm:px-4 sm:py-4 lg:grid-cols-4 lg:px-6 lg:py-6 xl:py-10">
                          {centers.map((item) => (
                            <Popover.Button
                              as={Link}
                              key={item.name}
                              to={item.href}
                              state={{ to: item.href }}
                              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-100"
                            >
                              <div className="flex md:h-full lg:flex-col">
                                <div className="flex-shrink-0">
                                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <item.icon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                  <div>
                                    <p className="text-base font-medium text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {item.description}
                                    </p>
                                  </div>
                                  <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                    Learn more{" "}
                                    <span aria-hidden="true">&rarr;</span>
                                  </p>
                                </div>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                        {/* Calls to action on Resources popover */}
                        <div className="bg-gray-50">
                          <div className="mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <Popover.Button
                                  as={Link}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                >
                                  <item.icon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3">{item.name}</span>
                                </Popover.Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* Resources */}
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                      )}
                    >
                      <span
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "nav-header-text",
                        )}
                      >
                        Resources
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "h-8 w-8 group-hover:text-gray-500",
                        )}
                        aria-hidden="true"
                      />
                      <span className="nav-header-text">|</span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                        {/* Resources */}
                        <div className="mx-auto grid gap-y-6 px-2 py-2 sm:grid-cols-2 sm:gap-8 sm:px-4 sm:py-4 lg:grid-cols-4 lg:px-6 lg:py-6 xl:py-10">
                          {nascc_resources.map((item) => (
                            <Popover.Button
                              as={Link}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-100"
                            >
                              <div className="flex md:h-full lg:flex-col">
                                <div className="flex-shrink-0">
                                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <item.icon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                  <div>
                                    <p className="text-base font-medium text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {item.description}
                                    </p>
                                  </div>
                                  <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                    Learn more{" "}
                                    <span aria-hidden="true">&rarr;</span>
                                  </p>
                                </div>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                        {/* Calls to action on Resources popover */}
                        <div className="bg-gray-50">
                          <div className="mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <Popover.Button
                                  as={Link}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                >
                                  <item.icon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3">{item.name}</span>
                                </Popover.Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* About Us popover */}
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                      )}
                    >
                      <span
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "nav-header-text",
                        )}
                      >
                        About Us
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "h-8 w-8 group-hover:text-gray-500",
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "nav-header-text",
                        )}
                      >
                        |
                      </span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg">
                        <div className="absolute inset-0 flex">
                          <div className="bg-white w-2/3" />
                          <div className="bg-gray-50 w-1/3" />
                        </div>
                        <div className="relative mx-auto grid grid-cols-1 lg:grid-cols-3">
                          {/* NASCC and partners about us information */}
                          <nav className="grid gap-y-6 px-2 pl-4 py-2 sm:grid-cols-2 sm:gap-8 sm:px-4 sm:pl-6 sm:py-4 lg:grid-cols-4 lg:px-6 lg:pl-10 lg:py-6 lg:col-span-2 xl:py-8">
                            {about_us.map((item) => (
                              <Popover.Button
                                as={Link}
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-100"
                              >
                                <div className="flex md:h-full lg:flex-col">
                                  <div className="flex-shrink-0">
                                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                      <item.icon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                    <div>
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p>
                                    </div>
                                    <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                      Learn more{" "}
                                      <span aria-hidden="true">&rarr;</span>
                                    </p>
                                  </div>
                                </div>
                              </Popover.Button>
                            ))}
                          </nav>
                          {/* Blog section */}
                          <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                            <div>
                              <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                From the blog
                              </h3>
                              <p>Coming soon!</p>
                              {/*<ul role="list" className="mt-6 space-y-6">*/}
                              {/*  {blogPosts.map((post) => (*/}
                              {/*    <li key={post.id} className="flow-root">*/}
                              {/*      <a*/}
                              {/*        href={post.href}*/}
                              {/*        className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"*/}
                              {/*      >*/}
                              {/*        <div className="hidden sm:block flex-shrink-0">*/}
                              {/*          <img*/}
                              {/*            className="w-32 h-20 object-cover rounded-md"*/}
                              {/*            src={post.imageUrl}*/}
                              {/*            alt=""*/}
                              {/*          />*/}
                              {/*        </div>*/}
                              {/*        <div className="w-0 flex-1 sm:ml-8">*/}
                              {/*          <h4 className="text-base font-medium text-gray-900 truncate">*/}
                              {/*            {post.name}*/}
                              {/*          </h4>*/}
                              {/*          <p className="mt-1 text-sm text-gray-500">*/}
                              {/*            {post.preview}*/}
                              {/*          </p>*/}
                              {/*        </div>*/}
                              {/*      </a>*/}
                              {/*    </li>*/}
                              {/*  ))}*/}
                              {/*</ul>*/}
                            </div>
                            {/*<div className="mt-6 text-sm font-medium">*/}
                            {/*  <Link*/}
                            {/*    to="#"*/}
                            {/*    className="text-indigo-600 hover:text-indigo-500"*/}
                            {/*  >*/}
                            {/*    {" "}*/}
                            {/*    View all posts{" "}*/}
                            {/*    <span aria-hidden="true">&rarr;</span>*/}
                            {/*  </Link>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <Link to="sponsors" className="nav-header-text text-indigo-700">
                Sponsors
              </Link>
            </Popover.Group>
            {/* Buttons */}
            <div className="flex items-center md:ml-4">
              {/* Login Button */}
              <Link
                to="member_login"
                className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap"
              >
                <span className="sr-only">Sign in</span>
                Member Login
              </Link>
              {/* Join Button */}
              <Link
                to="join"
                className="ml-2 inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap"
              >
                <span className="sr-only">Join NASCC</span>
                Join NASCC
              </Link>
            </div>
            {/* Menu button for small screens */}
            <div
              className="-mr-2 -my-2 ml-4 justify-self-end xl:hidden"
              data-testid="small-screen-menu-popover-container"
            >
              <PopoverButton className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
          </div>
        </div>
      </div>

      {/* Transition and menu for small screens */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div className="flex">
                  <span className="sr-only">NASCC Logo</span>
                  <img
                    className="h-20 w-auto"
                    src={NavBarLogo}
                    alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
                  />
                  <div className="pl-4 flex justify-center items-center">
                    <span className="sr-only">
                      National Alliance of Sickle Cell Centers
                    </span>
                    <div className="flex flex-col">
                      <h1 className="tracking-tight font-extrabold text-gray-900 text-3xl align-center">
                        National Alliance of
                      </h1>
                      <h1 className="tracking-tight font-extrabold text-gray-900 text-3xl align-center">
                        Sickle Cell Centers
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="-mr-2">
                  <PopoverButton className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                    {nascc_resources
                      .concat(about_us)
                      .concat(mobile_menu_options)
                      .map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </Link>
                      ))}
                  </div>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="mt-6">
                <Link
                  to="join"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Join NASCC
                </Link>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing member?{" "}
                  <Link
                    to="login"
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};
