/** @module Containers */
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavHeader } from "../NavHeader/NavHeader";

export const PageNotFound: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Page not found error page." />
        <meta charSet="UTF-8" />
      </Helmet>
      <NavHeader />
      <div
        className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        data-testid="error-page-root-element"
      >
        <div className="">
          <h2
            className="text-center font-bold text-5xl"
            data-testid="error-heading"
          >
            Page Not Found
          </h2>
          <p className="text-center pt-5" data-testid="error-text">
            Page is not found.
            <br />
            If you believe you have encountered this error by mistake, please
            contact{" "}
            <a
              href="mailto:"
              className="link-text"
              data-testid="support-email-link"
            >
              support@sicklecellcenters.org
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};
