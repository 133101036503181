/** @module Services */

import { camelCaseResponse, providesList } from "../shared/Functions";
import { MemberCenterBase } from "../types";
import { emptyApi } from "./emptyApi";

export interface MembershipReportData extends MemberCenterBase {
  centerContacts: string[];
}

export const reportService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getMembershipReport: builder.query<MembershipReportData[], void>({
      query: () => "/membership_report",
      transformResponse: (response: any): MembershipReportData[] => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) =>
        providesList(results, "MembershipReport"),
    }),
  }),
});

export const { useGetMembershipReportQuery } = reportService;
