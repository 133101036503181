import React from "react";

import { Footer } from "../Footer/Footer";
import { useGetOfficersQuery } from "../../services/userService";
import MarshaHeadshot from "../../assets/images/Marsha_Treadwell.png";
import SophieHeadshot from "../../assets/images/Sophie_Lanzkron.jpg";
import JulieHeadshot from "../../assets/images/Julie_Kanter.jpg";
import DeepaHeadshot from "../../assets/images/Deepa_Manwani.jpg";
import { User } from "../../types";

export const ExecutiveCommittee: React.FC = () => {
  const {
    data: officers,
    isFetching,
    isError,
    isLoading,
  } = useGetOfficersQuery();

  const headshots: Record<string, any> = {
    Deepa: DeepaHeadshot,
    Julie: JulieHeadshot,
    Marsha: MarshaHeadshot,
    Sophie: SophieHeadshot,
  };

  const [sortedOfficers, setSortedOfficers] = React.useState<User[]>([]);

  React.useEffect(() => {
    const tempArray: User[] = [];
    if (officers) {
      officers.forEach((officer) => {
        if (officer.firstName === "Julie") {
          tempArray.splice(0, 0, officer);
        } else if (officer.firstName === "Sophie") {
          tempArray.splice(1, 0, officer);
        } else if (officer.firstName === "Deepa") {
          tempArray.splice(2, 0, officer);
        } else {
          tempArray.splice(3, 0, officer);
        }
      });
    }
    setSortedOfficers(tempArray);
  }, [officers]);

  return (
    <>
      <main className="static-page-main">
        <h3 className="static-page-header">Meet our executive committee</h3>
        <section className="static-page-section">
          {!isFetching && !isError && !isLoading && officers ? (
            <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
              {sortedOfficers.map((officer) => {
                return (
                  <li>
                    <div className="grid grid-cols-3 items-start gap-x-8 align-middle">
                      <div className="col-span-1">
                        <img
                          src={headshots[officer.firstName]}
                          alt={`Headshot for ${officer.firstName} ${officer.lastName}`}
                          className="rounded-lg shadow-2xl object-cover aspect-3/4"
                        />
                      </div>
                      <div className="col-span-2 space-y-2">
                        <h3 className="text-lg leading-6 font-medium">
                          Dr. {officer.firstName} {officer.lastName}
                        </h3>
                        <p className="text-lg leading-6 font-medium text-indigo-600">
                          {officer.position}
                        </p>
                        <p className="text-lg text-gray-500">{officer.bio}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : isFetching ? (
            "Retrieving officers..."
          ) : (
            "Error retrieving officers..."
          )}
        </section>
        <h3 className="static-page-header pt-4">
          Conflict of Interest Policies
        </h3>
        <section className="static-page-section">
          <p className="prose-text">
            You can find NASCC's conflict of interest policy{" "}
            <a href="/conflict_of_interest" className="link-text">
              here
            </a>
            .
          </p>
        </section>
      </main>
      <Footer dataTestId="footer_4d954278-26ed-4c0a-85ae-241ecaa85a88" />
    </>
  );
};
