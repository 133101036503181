import React from "react";
import { JobPostForm } from "../../components";

export const NewJobPost: React.FC = () => {
  return (
    <main className="static-page-main">
      <h3 className="static-page-header">Create a New Job Post</h3>
      <section className="static-page-section">
        <JobPostForm />
      </section>
    </main>
  );
};
