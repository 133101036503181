/** @modules Components */
import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik, Field } from "formik";
import { Helmet } from "react-helmet-async";

import { userSlice } from "../../redux/slices";
import { useAppDispatch } from "../../redux/hooks";
import { ErrorToast } from "../Shared";
import { UserLoginData } from "../../types";
import { useLoginUserMutation } from "../../services/userService";
import NavBarLogo from "../../assets/images/navBarLogo.jpg";

export const Login: React.FC = () => {
  const { setCredentials } = userSlice.actions;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [makeLoginRequest] = useLoginUserMutation();

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />);

  return (
    <>
      <Helmet>
        <title>NASCC - Members Login</title>
        <meta
          name="description"
          content="The log in page for members of the National Alliance of Sickle Cell Centers."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div
        className="bg-white p-4 mt-4 sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg sm:shadow-lg"
        data-testid={"login-header"}
      >
        <img
          className="mx-auto aspect-auto"
          src={NavBarLogo}
          alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
          data-testid="nascc-logo"
        />
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .email("Must be a valid email")
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={async (values: UserLoginData) => {
          const data = {
            username: values.username,
            password: values.password,
            scope: "",
          };
          makeLoginRequest(data)
            .unwrap()
            .then((result: any) => {
              console.log(result);
              dispatch(setCredentials(result));
              navigate("/members/dashboard");
            })
            .catch(() => {
              toastError(
                "Unable to sign in with that email address and password.",
              );
            });
        }}
      >
        {({ values, handleSubmit, touched, errors, dirty, isValid }) => (
          <form
            className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
            data-testid="login-form-component"
            onSubmit={handleSubmit}
          >
            <div className="bg-white px-2 py-2">
              Please note: we're still working on our members dashboard. Once
              it's available we'll send out an email to all members!
            </div>
            <fieldset className="bg-white py-4 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
              <div data-testid="email-container">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                  data-testid="email-label"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <Field
                    id="username"
                    name="username"
                    type="email"
                    autoComplete="email"
                    value={values.username}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                      touched.username && errors.username
                        ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                        : ""
                    }`}
                    data-testid="email-input"
                  />
                  {touched.username && errors.username ? (
                    <div
                      className="font-medium text-xs text-red-600 pt-1"
                      data-testid="email-error"
                    >
                      {errors.username}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="pt-2" data-testid="password-container">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                  data-testid="password-label"
                >
                  Password
                </label>
                <div className="mt-1">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={values.password}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                      touched.password && errors.password
                        ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                        : ""
                    }`}
                    data-testid="password-input"
                  />
                  {touched.password && errors.password ? (
                    <div
                      className="font-medium text-xs text-red-600 pt-1"
                      data-testid="password-error"
                    >
                      {errors.password}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex items-center justify-between mt-4">
                <div
                  className="flex items-center"
                  data-testid="checkbox-container"
                >
                  <input
                    id="remember-me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    data-testid="checkbox-input"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                    data-testid="checkbox-label"
                  >
                    Remember me
                  </label>
                </div>

                <div
                  className="text-sm"
                  data-testid="forgot-password-container"
                >
                  <button
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    data-testid="forgot-password-link"
                    onClick={() => navigate("/forgot_password")}
                    type="button"
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="mx-auto w-full flex items-center justify-center px-4 py-3 border border-transparent text-base
                  font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2
                  focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white disabled:opacity-50 disabled:cursor-not-allowed"
                  data-testid="sign-in-button"
                  disabled={!(dirty && isValid)}
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
    </>
  );
};
