import { emptyApi } from "./emptyApi";
import { camelCaseResponse, providesList } from "../shared/Functions";
import {
  ApiSettingsType,
  GeneralApiResponse,
  JobPostFormType,
  JobPostType,
} from "../types";

export const jobPostService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobPostList: builder.query<JobPostType[], ApiSettingsType>({
      query: ({ offset = 0, limit = 100 }: ApiSettingsType) =>
        `/job_postings?offset=${offset}&limit=${limit}`,
      transformResponse: (response: any): JobPostType[] => {
        return camelCaseResponse(response);
      },
      providesTags: (results, error, id) => providesList(results, "JobPost"),
    }),
    postJobPostForm: builder.mutation<GeneralApiResponse, JobPostFormType>({
      query: (jobPostFormValues) => ({
        url: "/job_post",
        method: "POST",
        body: processJobPostFormValues(jobPostFormValues),
      }),
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      invalidatesTags: ["JobPost"],
    }),
  }),
});

export const { useGetJobPostListQuery, usePostJobPostFormMutation } =
  jobPostService;

function processJobPostFormValues(
  jobPostFormValues: JobPostFormType
): Record<string, unknown> {
  return {};
}
