/** @module Components */
import React from "react";
import { classNames } from "../../shared/Functions";

export type AddressInfoType = {
  streetAddress: string;
  city: string;
  state: string;
  zipCode: number;
};

export type CardInfoDataType = {
  infoTitle: string;
  infoContent?: string;
  infoBoolean?: boolean;
  addressBoolean?: boolean;
  addressInfo?: AddressInfoType;
};

export const CardInfoData: React.FC<CardInfoDataType> = ({
  infoTitle,
  infoContent,
  infoBoolean,
  addressBoolean,
  addressInfo,
}: CardInfoDataType) => {
  return (
    <div className="sm:col-span-1 min-h-4 flex flex-col justify-between">
      <dt className="text-sm font-medium text-gray-500 pb-2 md:text-base">
        {infoTitle}
      </dt>
      {infoContent && infoContent !== "" && (
        <dd className="flex rounded-sm pr-8 text-sm font-semibold text-gray-900">
          {infoContent}
        </dd>
      )}
      {infoBoolean !== undefined && (
        <dd
          className={classNames([
            infoBoolean ? "bg-green-400" : "bg-red-500",
            "flex rounded-sm pr-8 max-w-min mt-1 text-sm font-semibold text-gray-900 pl-1",
          ])}
        >
          {infoBoolean ? "Yes" : "No"}
        </dd>
      )}
      {addressBoolean && addressInfo && (
        <dd className="flex flex-col rounded-sm pr-8 text-sm font-semibold text-gray-900">
          <p>{addressInfo.streetAddress}</p>
          <p>
            {addressInfo.city}, {addressInfo.state} {addressInfo.zipCode}
          </p>
        </dd>
      )}
    </div>
  );
};
