/** @module Components */

import React from "react";
import { HeaderGroup } from "react-table";
import tw from "tailwind-styled-components";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";

/** @category Prop Types */
export type HeaderProps = {
  headerGroups: HeaderGroup<Record<string, unknown>>[];
  headerRowClassName?: string;
  headerCellClassName?: string;
};

const StyledHeaderRow = tw.tr`
  bg-gray-50
  w-full
  border-b
  border-gray-200
`;

const StyledHeaderCell = tw.th`
  w-1/6
  py-3
  text-left
  text-sm
  font-bold
  text-gray-500
  uppercase
  tracking-wider
`;

export const CustomHeader = ({
  headerGroups,
  headerRowClassName,
  headerCellClassName = "",
}: HeaderProps): JSX.Element => {
  return (
    <thead>
      {headerGroups.map((headergroup) => (
        // eslint-disable-next-line react/jsx-key
        <StyledHeaderRow
          {...headergroup.getHeaderGroupProps()}
          className={headerRowClassName}
        >
          {headergroup.headers.map((column) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <StyledHeaderCell
                {...column.getHeaderProps()}
                className={headerCellClassName}
                data-testid={
                  column.canSort ? "sortable-column-header" : "column-header"
                }
              >
                <div className="flex">
                  {column.render("Header") as React.ReactNode}
                  {column.canSort ? (
                    column.isSorted && !column.isSortedDesc ? (
                      <ArrowSmallDownIcon
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="h-5 pl-2"
                        data-testid="sort-down-icon"
                      />
                    ) : column.isSortedDesc ? (
                      <ArrowSmallUpIcon
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="h-5 pl-2"
                        data-testid="sort-up-icon"
                      />
                    ) : (
                      <ArrowsUpDownIcon
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="h-5 pl-2"
                        data-testid="sort-both-icon"
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </StyledHeaderCell>
            );
          })}
        </StyledHeaderRow>
      ))}
    </thead>
  );
};
