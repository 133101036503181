import React from "react";
import { Link } from "react-router-dom";

import MainLogo from "../../assets/images/mainLogo.jpg";

export const Hero: React.FC = () => {
  return (
    <section className="flex flex-col items-center lg:flex-row">
      {/* Text for Hero */}
      <div className="px-4 w-full">
        <div className="px-4 pt-10">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block lg:inline">
              National Alliance of Sickle Cell Centers
            </span>{" "}
            <br className="hidden lg:block" />
            <span className="block lg:inline">Working Together to Combat </span>
            <span className="block text-indigo-600 lg:inline">
              Sickle Cell Disease
            </span>
          </h1>
          <p className="mt-3 max-w-full text-lg text-gray-800 sm:text-xl md:mt-5">
            Our mission is to support sickle cell disease centers in delivering
            high-quality comprehensive care by setting standards, promoting
            their adoption, identifying opportunities and resources to
            strengthen those centers, and advocating for access to comprehensive
            care to improve health outcomes, quality of life, and survival.
          </p>
          <div className="mt-10 flex justify-start items-center pb-10 sm:flex lg:justify-start">
            <div className="rounded-md shadow max-w-sm">
              <Link
                to="about_us"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                More About Our Organization
              </Link>
            </div>
            <div className="mt-3 rounded-md shadow max-w-sm ml-3">
              <Link
                to="join"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Join NASCC
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Image for Hero */}
      <div className="w-full h-full pt-2 hidden lg:flex">
        <img
          className="rounded h-full w-full"
          src={MainLogo}
          alt="NASCC Logo, five different colored hands in a circle linked together surrounded by round and sickle cell shaped blood cells with the words National Alliance of Sickle Cell Centers below"
        />
      </div>
    </section>
  );
};
