/** @module Components */
import React from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Formik, Field } from "formik";
import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { ContactForm } from "../../types";
import { ErrorToast, SuccessToast } from "../Shared";
import { usePostContactFormMutation } from "../../services/contactService";

export const ContactUs: React.FC = () => {
  const [makeContactFormRequest] = usePostContactFormMutation();

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} />);
  const toastSuccess = (message: string) =>
    toast.custom(<SuccessToast message={message} />);

  return (
    <main className="static-page-main">
      <h2 className="static-page-header">Contact Us</h2>
      <section className="relative bg-white shadow-lg sm:p-4 md:rounded-lg md:p-2">
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          {/* Get in Touch Text and Numbers */}
          <div className="py-6 px-4 border-r-2 border-gray-100 sm:px-6 lg:col-span-2 lg:px-0 lg:py-12 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <p className="mt-3 text-xl leading-6 text-gray-500">
                If you have any questions about NASCC or need to contact us for
                any reason, you can find our contact information below. You can
                also use the form to the right to send us a message and we'll
                get back to you as soon as possible.
              </p>
              <dl className="mt-8 text-lg text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>1129 20TH St NW FL 4TH</p>
                    <p>Washington, DC 20036</p>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <a href="mailto:support@sicklecellcenters.org">
                    <dd className="flex">
                      <EnvelopeOpenIcon
                        className="flex-shrink-0 h-7 w-7 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3 link-text text-lg self-end">
                        support@sicklecellcenters.org
                      </span>
                    </dd>
                  </a>
                </div>
              </dl>
              <p className="mt-6 text-base text-gray-500">
                Looking for careers?{" "}
                <Link to="/jobs" className="font-medium link-text">
                  View all job openings
                </Link>
                .
              </p>
            </div>
          </div>
          {/* Contact Form */}
          <div className="bg-white py-6 px-4 sm:px-6 lg:col-span-3 lg:py-12 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <Formik
                enableReinitialize
                initialValues={{
                  fullName: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  fullName: Yup.string().required("Required"),
                  email: Yup.string()
                    .email("Must be a valid email address")
                    .required("Required"),
                  message: Yup.string()
                    .required("Required")
                    .min(10, "Must be at least 10 characters long"),
                })}
                onSubmit={async (values: ContactForm) => {
                  makeContactFormRequest(values)
                    .unwrap()
                    .then((result: any) => {
                      toastSuccess(
                        "Your contact form has been submitted. Someone will reply to your message as soon as possible.",
                      );
                    })
                    .catch(() => {
                      toastError(
                        "Unable to submit your contact form. Please contact support@sicklecellcenters.org for help.",
                      );
                    });
                }}
              >
                {({
                  values,
                  handleSubmit,
                  touched,
                  errors,
                  dirty,
                  isValid,
                }) => (
                  <form
                    data-testid="contact-form-component"
                    onSubmit={handleSubmit}
                    className="grid grid-cols-1 gap-y-6"
                  >
                    <fieldset>
                      <label htmlFor="full-name" className="sr-only">
                        Full name
                      </label>
                      <Field
                        type="text"
                        name="fullName"
                        id="full-name"
                        autoComplete="name"
                        value={values.fullName}
                        required
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Full name"
                        data-testid="full-name-contact-field"
                      />
                      {touched.fullName && errors.fullName ? (
                        <div
                          className="font-medium text-xs text-red-600 pt-1"
                          data-testid="full-name-error"
                        >
                          {errors.fullName}
                        </div>
                      ) : null}
                    </fieldset>
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={values.email}
                        required
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Email"
                      />
                      {touched.email && errors.email ? (
                        <div
                          className="font-medium text-xs text-red-600 pt-1"
                          data-testid="email-error"
                        >
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        type="text"
                        id="message"
                        name="message"
                        value={values.message}
                        rows={4}
                        required
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                        placeholder="Message"
                      />
                      {touched.message && errors.message ? (
                        <div
                          className="font-medium text-xs text-red-600 pt-1"
                          data-testid="email-error"
                        >
                          {errors.message}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base
                        font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        data-testid="contact-form-submit-button"
                        disabled={!(dirty && isValid)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
