/** @module Containers */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import {
  ActivityFeed,
  BasicCenterInfo,
  CenterDetails,
  CenterEmployees,
} from "../../components";
import {
  useGetCenterApplicationEventsQuery,
  useGetCenterApplicationInfoQuery,
  usePatchCenterApplicationMutation,
} from "../../services/applicationService";
import { ErrorToast, Modal, SuccessToast } from "../../components/Shared";
import { useAppContext } from "../../context";
import {useAppSelector} from "../../redux/hooks";

export const ApplicationDetail: React.FC = () => {
  const { centerApplicationId } = useParams();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalRightButtonText, setModalRightButtonText] = useState("");
  const [displayRightButton, setDisplayRightButton] = useState(false);
  const { displayModal, setDisplayModal } = useAppContext();
  const user = useAppSelector((state) => state.user);

  const [patchCenterApplication, isSuccess] =
    usePatchCenterApplicationMutation();
  const {
    data: centerApplicationInfo,
    isLoading,
    isFetching,
    isError,
  } = useGetCenterApplicationInfoQuery(centerApplicationId || "");
  const {
    data: applicationEvents,
    isLoading: eventsLoading,
    isFetching: eventsFetching,
    isError: eventsError,
  } = useGetCenterApplicationEventsQuery(centerApplicationId || "");

  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />);

  const toastSuccess = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames={"mt-modal"} />);

  const handleApprove = () => {
    patchCenterApplication({
      approve: true,
      reject: false,
      reject_reason: "",
      center_application_id: centerApplicationId || "",
    })
      .unwrap()
      .then(() => {
        if (isSuccess) {
          toastSuccess("Application successfully updated!");
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          return toastError(
            "There was an issue updating the application. Please contact support@sicklecellcenters.org if you continue to have trouble."
          );
        }
      })
      .catch((error) => {
        return toastError(
          "There was an issue updating the application. Please contact support@sicklecellcenters.org if you continue to have trouble."
        );
      });
  };



  const handleReject = () => {
    setModalTitle("Reject Application Under Construction");
    setModalBody(
      "Currently, in order to reject an application you must contact support@sicklecellcenters.org " +
        "and send in the name of the center you'd like to reject the application for. We apologize for this extra step."
    );
    setModalRightButtonText("Close");
    setDisplayRightButton(true);
    setDisplayModal(true);
  };

  return (
    <>
      {displayModal && (
        <Modal
          modalBody={modalBody}
          modalTitle={modalTitle}
          modalRightButtonText={modalRightButtonText}
          modalLeftButtonText={""}
          displayRightButton={displayRightButton}
          displayLeftButton={false}
        />
      )}
      <section>
        {/* Header */}
        <h1 className="static-page-header px-4">
          Application Information -{" "}
          {!isLoading &&
          !isFetching &&
          !isError &&
          centerApplicationInfo &&
          centerApplicationInfo.centerName !== undefined
            ? centerApplicationInfo.centerName
            : ""}
        </h1>
        {/* Basic Info and Event Feed */}
        <div className="flex">
          <div className="w-4/6">
            {!isLoading && !isFetching && !isError && centerApplicationInfo && (
              <BasicCenterInfo basicCenterInformation={centerApplicationInfo} />
            )}
          </div>
          <div className="w-2/6 flex flex-col justify-between pb-2">
            {!eventsLoading &&
              !eventsFetching &&
              !eventsError &&
              applicationEvents && <ActivityFeed events={applicationEvents} />}
            {centerApplicationInfo && (
              <div className="w-full flex justify-center space-x-2 pt-4">
                <button
                  disabled={centerApplicationInfo.status !== "pending" || centerApplicationInfo.firstApprovalBy === user.id.toString()}
                  onClick={handleApprove}
                  className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md
              shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap disabled-styling"
                >
                  <span className="sr-only">Approve the Application</span>
                  Approve
                </button>
                <button
                  disabled={centerApplicationInfo.status !== "pending" || centerApplicationInfo?.firstApprovalBy === user.id.toString()}
                  onClick={handleApprove}
                  className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md
              shadow-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap disabled-styling"
                >
                  <span className="sr-only">
                    Approve the Application as an Associate Center
                  </span>
                  Approve as Associate
                </button>
                <button
                  disabled={centerApplicationInfo.status !== "pending" || centerApplicationInfo.firstApprovalBy === user.id.toString()}
                  onClick={handleReject}
                  className="inline-flex items-center justify-center px-4 py-4 border border-transparent rounded-md
              shadow-md text-base font-medium text-white bg-red-500 hover:bg-red-700 whitespace-nowrap disabled-styling"
                >
                  <span className="sr-only">Reject the Application</span>
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          {!isLoading && !isFetching && !isError && centerApplicationInfo && (
            <CenterDetails combinedCenterDetails={centerApplicationInfo} />
          )}
        </div>
        <div>
          {!isLoading && !isFetching && !isError && centerApplicationInfo && (
            <CenterEmployees combinedCenterDetails={centerApplicationInfo} />
          )}
        </div>
      </section>
    </>
  );
};
