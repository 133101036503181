/** @module Components */
import React from "react";

import { CenterType, CombinedCenterDetailsType } from "../../types";
import { CardInfoData } from "./CardInfoData";

/** @category Prop Types */
export type CenterDetailsProps = {
  combinedCenterDetails: CombinedCenterDetailsType;
};

export const CenterDetails: React.FC<CenterDetailsProps> = ({
  combinedCenterDetails,
}: CenterDetailsProps) => {
  return (
    <div className="bg-white border-t border-gray-300 border-2 mx-4 my-2 shadow sm:rounded-lg">
      {/* Card Header */}
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-2xl leading-6 font-semibold text-gray-900">
          Center Details
        </h3>
      </div>
      {/* Center Details Card Body */}
      <div className="px-4 pb-4 sm:pb-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 xl:grid-cols-6">
          {(combinedCenterDetails.centerType?.toUpperCase() ===
            CenterType.Adult ||
            combinedCenterDetails.centerType?.toUpperCase() ===
              CenterType.WholeLife) && (
            <>
              <CardInfoData
                infoTitle="Number of Adult SC Patients"
                infoContent={combinedCenterDetails.numberOfAdultSc?.toString()}
              />
              <CardInfoData
                infoTitle="Number of Adult SCA Patients"
                infoContent={combinedCenterDetails.numberOfAdultSca?.toString()}
              />
              <CardInfoData
                infoTitle="Number of Adult Other Patients"
                infoContent={combinedCenterDetails.numberOfAdultOther?.toString()}
              />
            </>
          )}

          {(combinedCenterDetails.centerType?.toUpperCase() ===
            CenterType.Pediatric ||
            combinedCenterDetails.centerType?.toUpperCase() ===
              CenterType.WholeLife) && (
            <>
              <CardInfoData
                infoTitle="Number of Pediatric SC Patients"
                infoContent={combinedCenterDetails.numberOfPediatricSc?.toString()}
              />
              <CardInfoData
                infoTitle="Number of Pediatric SCA Patients"
                infoContent={combinedCenterDetails.numberOfPediatricSca.toString()}
              />
              <CardInfoData
                infoTitle="Number of Pediatric Other Patients"
                infoContent={combinedCenterDetails.numberOfPediatricOther.toString()}
              />
            </>
          )}
          <CardInfoData
            infoTitle="Number of Dedicated Outpatient Staff"
            infoContent={combinedCenterDetails.numberOfDedicatedOutpatientStaff.toString()}
          />
        </dl>
      </div>
      {/* Clinic Space Section Body */}
      <div className="relative px-4">
        <div
          className="absolute inset-0 flex items-center px-6"
          aria-hidden="true"
        >
          <div className="w-full border-t border-gray-400" />
        </div>
        <div className="relative pb-4">
          <span className="pr-3 bg-white text-xl font-medium text-gray-900">
            Clinic Space
          </span>
        </div>
      </div>
      <div className="px-4 pb-4 sm:pb-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 xl:grid-cols-6">
          <CardInfoData
            infoTitle="Dedicated Clinic Space?"
            infoBoolean={combinedCenterDetails.dedicatedClinicSpace}
          />
          <CardInfoData
            infoTitle="DCS Shared with a Cancer Center?"
            infoBoolean={combinedCenterDetails.dcsSharedWithCancerCenter}
          />
          <CardInfoData
            infoTitle="DCS is an Independent Clinic Space?"
            infoBoolean={combinedCenterDetails.indepClinicSpace}
          />
          <CardInfoData
            infoTitle="DCS Shared with Something Else?"
            infoBoolean={combinedCenterDetails.dcsSharedWithOther}
          />
          {combinedCenterDetails.dcsSharedWithOther &&
            combinedCenterDetails.dcsSharedWithOtherName && (
              <CardInfoData
                infoTitle="DCS Shared With"
                infoContent={combinedCenterDetails.dcsSharedWithOtherName}
              />
            )}
          <CardInfoData
            infoTitle="Infusion Area?"
            infoBoolean={combinedCenterDetails.infusionArea}
          />
          <CardInfoData
            infoTitle="IA Shared With a Cancer Center?"
            infoBoolean={combinedCenterDetails.infAreaSharedWithCancerCenter}
          />
          <CardInfoData
            infoTitle="IA Contains a Dedicated Day Hospital?"
            infoBoolean={combinedCenterDetails.infAreaDedicatedDayHospital}
          />
          <CardInfoData
            infoTitle="IA Shared with Something Else?"
            infoBoolean={combinedCenterDetails.infAreaSharedWithOther}
          />
          {combinedCenterDetails.infAreaSharedWithOther &&
            combinedCenterDetails.infAreaSharedWithOtherName && (
              <CardInfoData
                infoTitle="IA Shared With"
                infoContent={combinedCenterDetails.infAreaSharedWithOtherName}
              />
            )}
          <CardInfoData
            infoTitle="Observation Unit?"
            infoBoolean={combinedCenterDetails.dedicatedClinicSpace}
          />
        </dl>
      </div>
      {/* Written Protocols and Documentation Section */}
      <div className="relative px-4">
        <div
          className="absolute inset-0 flex items-center px-6"
          aria-hidden="true"
        >
          <div className="w-full border-t border-gray-400" />
        </div>
        <div className="relative pb-4">
          <span className="pr-3 bg-white text-xl font-medium text-gray-900">
            Written Protocols and Documentation
          </span>
        </div>
      </div>
      <div className="px-4 pb-4 sm:pb-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 xl:grid-cols-6">
          <CardInfoData
            infoTitle="Written Protocol for Acute and Chronic Sickle Cell Disease?"
            infoBoolean={combinedCenterDetails.writtenProcForSickleCellDisease}
          />
          <CardInfoData
            infoTitle="Emergency Department Protocols for Sickle Cell Disease?"
            infoBoolean={combinedCenterDetails.emergencyDeptProtocolsForScd}
          />
          {(combinedCenterDetails.centerType?.toUpperCase() ===
            CenterType.Adult ||
            combinedCenterDetails.centerType?.toUpperCase() ===
              CenterType.WholeLife) && (
            <>
              <CardInfoData
                infoTitle="Protocol for Receiving Newly Transitioned Patients to Adult Care?"
                infoBoolean={combinedCenterDetails.recNewlyTransToAdultProtocol}
              />
            </>
          )}
          {(combinedCenterDetails.centerType?.toUpperCase() ===
            CenterType.Pediatric ||
            combinedCenterDetails.centerType?.toUpperCase() ===
              CenterType.WholeLife) && (
            <>
              <CardInfoData
                infoTitle="Protocol for Transition to Adult Care?"
                infoBoolean={combinedCenterDetails.transToAdultProtocol}
              />
              <CardInfoData
                infoTitle="Protocol for Tracking Newborn Screening Results?"
                infoBoolean={
                  combinedCenterDetails.trackingNewbornScreenProtocol
                }
              />
            </>
          )}
          <CardInfoData
            infoTitle="Protocol for Tracking Quality Metrics?"
            infoBoolean={combinedCenterDetails.trackingQualityMetricsProtocol}
          />
          <CardInfoData
            infoTitle="Opioid Policy?"
            infoBoolean={combinedCenterDetails.opioidPolicy}
          />
          <CardInfoData
            infoTitle="Infusion Policy?"
            infoBoolean={combinedCenterDetails.infusionPolicy}
          />
        </dl>
      </div>
      {/* Access to Treatments, Compounds, and Prescriptions Section */}
      <div className="relative px-4">
        <div
          className="absolute inset-0 flex items-center px-6"
          aria-hidden="true"
        >
          <div className="w-full border-t border-gray-400" />
        </div>
        <div className="relative pb-4">
          <span className="pr-3 bg-white text-xl font-medium text-gray-900">
            Access to Treatments, Compounds, and Prescriptions
          </span>
        </div>
      </div>
      <div className="px-4 pb-4 sm:pb-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 xl:grid-cols-6">
          <CardInfoData
            infoTitle="Access to Compound Hydroxyurea?"
            infoBoolean={combinedCenterDetails.accessToCompoundHydroxyurea}
          />
          <CardInfoData
            infoTitle="Access to Crizanlizumab Infusions?"
            infoBoolean={combinedCenterDetails.accessCrizanlizumabInfusions}
          />
          <CardInfoData
            infoTitle="Access to Prescribe Voxeletor?"
            infoBoolean={combinedCenterDetails.accessPrescribeVoxeletor}
          />
          <CardInfoData
            infoTitle="Access to Pharmaceutical Clinical Trials?"
            infoBoolean={combinedCenterDetails.accessPharmaClinicalTrials}
          />
          <CardInfoData
            infoTitle="Access to Stem Cell Transplants?"
            infoBoolean={combinedCenterDetails.accessStemCellTransplant}
          />
        </dl>
      </div>
    </div>
  );
};
