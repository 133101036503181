import React from "react";

export const GrndadLocations: React.FC = () => {
  return (
    <section className="static-page-section mt-10" id="locations">
      <h3 className="static-page-header">GRNDaD Locations</h3>
      <div className="overflow-hidden w-full h-[655px] -mb-16">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1xRNuXK1IwThEyCEp9V7f7ekc9e-yDLSU&amp;headers=false&amp;chrome=false"
          width="100%"
          height="100%"
          title="GRNDaD Locations Map"
          className="border-0 -mt-16 -mb-16"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </section>
  );
};
