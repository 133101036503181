/** @modules Containers */
import React from "react";
import { ColumnWithStrictAccessor } from "react-table";
import { Helmet } from "react-helmet-async";

import { ConsensusDetailsColumns } from "../../components/Shared/Columns";
import { Table } from "../../components";
import { ConsensusRecommendationsType } from "../ConsensusResources/ConsensusRecommendations";

export const ConsensusRecommendationAdult: React.FC = () => {
  const [selectedPageSize, setSelectedPageSize] = React.useState("1000");
  const tableData: ConsensusRecommendationsType[] = [
    {
      title: "Routine Health Maintenance",
      text: "Patients should be seen annually for an extended comprehensive sickle cell disease well visit. This should include a detailed review of acute care use (number of acute VOC and treatment setting-infusion, ED, hospital) as well as any other new disease related concerns)",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Routine Health Maintenance",
      text: "Laboratory testing (all tests should be performed annually at baseline when acutely ill except (*) where indicated): CBC, differential, Retic, CMP/chem10 (renal function and liver function), Total and direct bili, LDH, Ferritin/transferrin saturation, HIV per CDC guidelines, Urinalysis, Albumin/Creatinine ratio, 25-OH Vitamin D. For patients who have had transfusion in the past 12 months: Hep C and Hep B, RBC Antibody screen. RBC genotype (*one time), RBC phenotyping (min C/D/E/Kell if not done previously) if not genotyped (*one time)",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Routine Health Maintenance",
      text: "Patients should receive care at a NASCC recognized sickle cell center for this annual comprehensive visit",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Routine Health Maintenance",
      text: "Patients should have an established primary care provider and receive standard adult screening in addition to sickle cell disease specific items detailed here.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Routine Health Maintenance",
      text: "Patients should be referred to local and/or national sickle cell community organization for support if available",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Visit Frequency",
      text: "Patients who are on a disease modifying therapy (hydroxyurea, crizanlizumab, voxelotor, L-glutamine) or those who require monthly prescriptions for pain medication should be seen every 3 months for follow-up assessments.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Visit Frequency",
      text: "Those with mild disease phenotype (defined practically as those without organ damage or who require very infrequent pain medication) should be seen for follow-up every 6 months.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Neurologic Disease",
      text: "Evaluation for focal neurologic deficits with assessment of cranial nerve function and gait should be done during the annual comprehensive visit",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurologic Disease",
      text: "Qualitative neuropsychiatric and functional assessment should be done during their annual visit as part of their history and physical. Validated assessments in these areas are pending.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurologic Disease",
      text: "Screening MRI once in lifetime consistent with ASH guidelines for people with Hgb SS/S beta 0. This study should be done as soon as possible once person starts in the adult SCD center if not performed in the last 12 months.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "Neurologic Disease",
      text: "Referral to neurology for silent infarcts, along with discussion of secondary prevention options, including transfusion and HSCT, per ASH guidelines.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Neurologic Disease",
      text: "Referral to neuropsychologist or rehab specialist if abnormalities in neurological function are identified",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "Neurologic Disease",
      text: "Referral to neurosurgery for anatomic abnormalities",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "Eye",
      text: "Dilated eye exam for retinopathy screening annually. This should be performed by an ophthalmologist if possible, understanding that some individuals may only have access to an optometrist.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT",
      text: "Routine dental care every 6 months. As sickle cell disease can affect dentition, dental coverage should be included in medical coverage for all individuals with sickle cell disease. Note that provider should emphasize dental education with patients the pertinence of dental care to SCD as a risk of pain, source of infection, and risk for dehydration.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT",
      text: "Adults with sickle cell disease DO NOT require routine antibiotic prophylaxis for dental screening or treatment.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT",
      text: "Patients should be asked about potential auditory concerns during annual visit. They should be referred for an audiogram if there are any concerns.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "ENT",
      text: "Patients receiving iron chelation should undergo an annual audiogram.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "To be consistent with the most recent ASH guidelines, patients should be screened annually for symptoms that will help identify those who may require additional testing. This includes screening for conditions known to increase PH risk in the general population and symptoms suggestive of PH. If the answer to any of the screening questions is yes, echocardiography is recommended. The frequency of echocardiography determination is unclear, but depending on the findings can be repeated as often as every 1-3 years. If the answer to all the questions is no, the clinician can use their clinical judgment and their review of all clinical guidelines to approach additional diagnostic testing.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "When an echocardiogram is indicated, it should be performed at baseline outside of acute care settings unless clinically indicated.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "If an echocardiogram is performed, attention should be paid to all aspects of the heart (not only the TR jet velocity and/or right ventricular systolic pressure). There is significant information provided in an Echocardiogram that go beyond the TR jet velocity. This includes assessments of L/R atrial and ventricle size and function.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "Screening for hypoxia at baseline, ideally at rest and with exertion.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "Screening for symptoms of obstructive sleep apnea including snoring, morning fatigue, or witnessed apnea.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "Routine screening with pulmonary function testing is NOT recommended for adults with SCD. However, providers should have a low threshold for PFT for adults with risk factors as per the ASH guidelines. If performed, comprehensive PFT should include full spirometry as well as complete evaluation of diffusion capacity and lung volumes. Additionally, any patient with interstitial lung disease/pulmonary fibrosis identified on radiologic imaging needs a referral to pulmonary medicine prior to ordering pulmonary function testing (this is standard of care in Pulmonary Medicine).",
      typeOfRecommendation: "Standard",
    },
    {
      title: "CARDIO-PULMONARY",
      text: "If patients have a positive symptom screen, complete PFTs including spirometry, lung volumes, and diffusing capacity for carbon monoxide should be performed in addition to a 6-minute walk test. Specific criteria are provided for when to prompt a referral to Pulmonary.",
      typeOfRecommendation: "Recommendation",
    },

    {
      title: "GASTROINTESTINAL",
      text: "Screening for symptoms of cholelithiasis, constipation and enlarged spleen, including abdominal pain particularly with eating, recurrent nausea and vomiting, and decreased appetite or early satiety. Note that patients with a large spleen may have thrombocytopenia but this should be confirmed with abdominal ultrasound. If splenomegaly is not seen, patients may require additional work-up.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "RENAL",
      text: "Annual screening random urine albumin/Cr. If ACR <30, continue to follow annually. If ACR 30-100, repeat at least q6 months and aim for first morning void. If ACR>100, repeat within 4 weeks for first morning void.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "GU",
      text: "Screening for a history of priapism and erectile dysfunction.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "GU",
      text: "All those with a uterus should receive appropriate gynecologic care and access to contraception if desired. Persons with menstrual-associated VOC should be considered for progesterone-only hormonal contraception. Discuss fertility options including risk of hydroxyurea and other disease modifying medications at each annual visit and facilitate referrals to Reproductive Endocrinology and Infertility (REI) if needed.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "SKIN",
      text: "Perform detailed skin exam including lower extremities to evaluation for skin changes and ulcers of the lower extremities as part of the annual assessment.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "SKIN",
      text: "Recurrent skin ulceration should prompt consideration of changes to disease modifying therapy.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "SKIN",
      text: "If signs/symptoms of venous stasis disease are noted, patients should undergo echocardiography with consideration of referral to cardiology.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "PSYCHOSOCIAL EVALUATIONS",
      text: "Mental health screening for anxiety and depression annually and as needed based on reported symptoms or new psychosocial concerns. Tools to use: GAD, PHQ-9.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "IRON ASSESSMENT",
      text: "Patients receiving long term chronic blood transfusions should have regular monitoring for iron overload and appropriate iron chelation therapy according to their iron burden.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "IRON ASSESSMENT",
      text: "All patients receiving iron chelation therapy should be regularly monitored for therapeutic effect and chelator toxicity.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "IRON ASSESSMENT",
      text: "Ferritin and Transferrin should be monitored routinely in ALL persons with SCD at least annually, regardless of chronic or on demand transfusions.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "IRON ASSESSMENT",
      text: "Ferritin should not be checked in the acute care setting as it is an acute phase reactant.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "IRON ASSESSMENT",
      text: "All patients with serum ferritin persistently raised >1000 on chronic transfusion therapy should have quantitative monitoring of liver iron concentration using magnetic resonance imaging (MRI).",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "IRON ASSESSMENT",
      text: "Iron chelation is recommended in patients who have a liver iron concentration of >5mg/g dry weight on MRI scanning.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "BONE HEALTH",
      text: "Annual vitamin D testing should be performed in all patients with appropriate supplementation given as needed.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "BONE HEALTH",
      text: "Routine physical exam to assess joint mobility and should include joint symptoms in a review of systems, imaging only based on positive findings.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "IMMUNIZATIONS",
      text: "Individuals with SCD should receive CDC adult vaccine schedule for those with anatomical or functional asplenia.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "IMMUNIZATIONS",
      text: "Sickle cell centers should ensure, implement, and track CDC recommended immunizations with specific focus on: Pneumococcal, Influenza, Covid-19, Hepatitis B, Check hepatitis B immunization status by titer and boost as needed, Hepatitis C screening every 1-2 years in patients transfused within that time interval, Haemophilus influenzae for those that did not complete series as children.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "TRANSITION TO ADULT CARE",
      text: "All SCD centers should have a formalized, structured transition process including formal communication and hand off between pediatric and adult teams. Structured introduction to adult facilities and staff should be offered.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "TRANSITION TO ADULT CARE",
      text: "Each ADULT SCD center should have a staff member designated as the 'Transition Coordinator' with dedicated time to coordinate care of patients 18-30 year of age. The staff member's credentials and FTE amount is dependent on the size of population and available resources.",
      typeOfRecommendation: "Standard",
    },
    {
      title: "TRANSITION TO ADULT CARE",
      text: "Follow up touch point 2 weeks after initial visit in Adult SCD clinic, manner of follow up per center capabilities and patient preferences.",
      typeOfRecommendation: "Recommendation",
    },
    {
      title: "TRANSITION TO ADULT CARE",
      text: "The transition coordinator should be funded with institutional/hospital support and further facilitated by the payer/Managed Care Entity (MCE).",
      typeOfRecommendation: "Recommendation",
    },
  ];

  const memoizedConsensusColumns = React.useMemo<
    ColumnWithStrictAccessor<Record<string, unknown>>[]
  >(ConsensusDetailsColumns, []);

  React.useEffect(() => {
    const firstHeader = document.getElementById("header-1")?.closest("th");
    const secondHeader = document.getElementById("header-2")?.closest("th");
    const thirdHeader = document.getElementById("header-3")?.closest("th");
    if (firstHeader) {
      firstHeader.style.width = "20% important!";
    }
    if (secondHeader) {
      secondHeader.style.width = "70%";
    }
    if (thirdHeader) {
      thirdHeader.style.width = "10%";
    }
  });

  return (
    <>
      <Helmet>
        <title>NASCC - Consensus Resources</title>
        <meta
          name="description"
          content="NASCC consensus recommendations for adult patients with Sickle Cell Disease gathered from
          the NASCC annual meeting."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <section className="static-page-section mt-10">
          <h3 className="static-page-header pl-8">
            Care Delivery and Health Maintenance Consensus Recommendations for
            Over 18 Years Old
          </h3>
          <Table
            columns={memoizedConsensusColumns}
            data={tableData}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            fetching={false}
            headerCellClassName="pl-4"
            customRowCellClassName="pl-4 whitespace-normal"
            searchable={true}
          />
        </section>
        <section className="static-page-section mt-10">
          <h3 className="static-page-header">Links for Downloading</h3>
          <ul className="list-disc pl-6">
            <li>
              <a href="https://www.dropbox.com/scl/fi/1jo9i3acjo1erbg5mwwcu/Table-for-Consensus-Recommendations-18.docx?rlkey=y30hy2aqey4v6elvyuliwpv5o&st=e012qs5t&dl=0">
                {" "}
                Care Delivery and Health Maintenance Consensus Recommendations
                for Over 18 Years Old
              </a>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};
