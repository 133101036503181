import { emptyApi } from "./emptyApi";
import { camelCaseResponse, providesList } from "../shared/Functions";
import {
  CenterSubscriber,
  CenterSubscriberFromApi,
  Subscriber, SubscriberFormType,
  SubscriberFromApi,
} from "../types";

export const subscriberService = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscribers: builder.query<SubscriberFromApi[], void>({
      query: () => "/subscribers?center=false",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results, error, id) => providesList(results, "Subscriber"),
    }),
    getCenterSubscribers: builder.query<CenterSubscriberFromApi[], void>({
      query: () => "/subscribers?center=true",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results, error, id) =>
        providesList(results, "CenterSubscribers"),
    }),
    getAllSubscribers: builder.query<
      (SubscriberFromApi | CenterSubscriberFromApi)[],
      void
    >({
      query: () => "/admin/subscribers/get_all_subscribers",
      transformResponse: (response: any) => {
        return camelCaseResponse(response).map((subscriber: any) => {
          return processSubscribersQuery(subscriber);
        });
      },
    }),
    postSubscriber: builder.mutation<Record<string, unknown>, Subscriber>({
      query: (subscriberInfo: Subscriber) => ({
        url: "/subscriber",
        method: "POST",
        body: processSubscriberForm(subscriberInfo),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    postCenterSubscriber: builder.mutation<
      Record<string, unknown>,
      CenterSubscriber
    >({
      query: (centerSubscriberInfo: CenterSubscriber) => ({
        url: "/subscriber",
        method: "POST",
        body: centerSubscriberInfo,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    patchSubscriber: builder.mutation<
      Record<string, unknown>,
      Record<string, unknown>
    >({
      query: (listsToAddInfo: Record<string, unknown>) => ({
        url: "/admin/sendgrid/contact_list",
        method: "PATCH",
        body: processListsToAdd(listsToAddInfo),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const {
  useGetSubscribersQuery,
  useGetCenterSubscribersQuery,
  useGetAllSubscribersQuery,
  usePatchSubscriberMutation,
  usePostSubscriberMutation,
  usePostCenterSubscriberMutation,
} = subscriberService;

function processSubscriberForm(subscriberInfo: SubscriberFormType) {
  return {
    first_name: subscriberInfo.firstName,
    last_name: subscriberInfo.lastName,
    email: subscriberInfo.email,
    center_name: subscriberInfo.centerName,
  };
}

function processSubscribersQuery(
  subscriberInfo: any
): SubscriberFromApi | CenterSubscriberFromApi {
  if (subscriberInfo.centerId) {
    return {
      id: subscriberInfo.id,
      firstName: subscriberInfo.firstName,
      lastName: subscriberInfo.lastName,
      email: subscriberInfo.email,
      centerName: subscriberInfo.center.centerName,
      centerId: subscriberInfo.centerId,
      centerApplicationToken: subscriberInfo.centerApplicationToken,
      userRegistrationToken: subscriberInfo.userRegistrationToken
        ? subscriberInfo.userRegistrationToken
        : undefined,
    };
  }
  return {
    id: subscriberInfo.id,
    firstName: subscriberInfo.firstName,
    lastName: subscriberInfo.lastName,
    email: subscriberInfo.email,
  };
}

function processListsToAdd(listsToAddInfo: any) {
  return {
    email_list_ids: listsToAddInfo.emailListIds.map(
      (listInfo: any) => listInfo.value
    ),
    subscriber_id: listsToAddInfo.subscriberId,
  };
}
